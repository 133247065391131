@include media-breakpoint-down(sm) {
     .subPage_textBox_imageRight {
          padding-top: 50px;
          padding-bottom: 30px;
          .container {
               .textBox_imageRight_title {
                    color: $global;
                    font-size: 16px;
                    font-weight: 700;
                    letter-spacing: -0.03px;
                    line-height: 26px;
                    text-align: center;

               }

               .textBox_imageRight_wrap {
                    .textBox_imageRight_text {
                         float: left;
                         padding-top: 10px;
                         width: 100%;
                         padding-right: 0px;
                         text-align: center;

                         p {
                              color: $global;
                              font-size: 16px;
                              font-weight: 400;
                              line-height: 26px;
                         }
                    }

                    .textBox_imageRight_image {
                         float: left;
                         width: 100%;
                         background-color: transparent;
                         margin-right: 0%;
                         height: 400px;
                         text-align: center;
                         box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
                         .wrap{
                              height: 100%;

                         img {
                              width: auto;
                              height: 100%;
                         }
                         }
                    }
               }
          }
     }

}
.postSidebar{
	margin-top: 45px;
	.postSidebar_header{
		color: $global;
		font-size: 18px;
		font-weight: 700;
		letter-spacing: -0.04px;
		line-height: 32px;
		text-transform: uppercase;
		margin-bottom: 30px;
	}
	.postSidebar_content{
		.postSidebar_content_news{
			margin-bottom: 50px;
			.postSidebar_content_news_image{
				img{
					width: 100%;
				}
			}
			.postSidebar_content_news_header{
				.postSidebar_content_news_date{
					color: #202020;
					font-size: 10px;
					font-weight: 700;
					letter-spacing: -0.4px;
					line-height: 25px;
					margin-bottom: 10px;
					margin-top: 10px;
				}
				.postSidebar_content_news_title{
					text-decoration: none;
					color: $red;
					transition-duration: .2s;
					&:hover{
						color: $redHover;
					}

					h2{
						font-size: 16px;
						font-weight: 700;
						letter-spacing: -0.64px;
						line-height: 25px;
					}
				}
			}
		}
		.postSidebar_content_points_wraper_item {
			width: 100%;
			position: relative;
			background-color: $red;
			background-size: cover;
			display: block;
			box-shadow: 0 0 0px rgba(0, 0, 0, 0);
			transition-duration: .3s;
			padding: 30px;

			.item_wrap {
				display: flex;
				align-content: space-between;
				flex-wrap: wrap;
				min-height: 220px;


				.item_title {
					font-size: 21px;
					letter-spacing: -0.84px;
					line-height: 33px;
					color: #ffffff;
					text-transform: none;
					font-weight: 400;

					strong {
						color: $red;
						font-weight: 600;
					}
				}

				.item_link {
					@include buttonAnimation();
				}
			}
		}
		
	}
}

@import 'rwd/tablet';
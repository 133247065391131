@include media-breakpoint-down(sm) {
     .site_header {
          .site_header_container {
               padding: 10px 20px;
               align-items: flex-start;
               .site_header_left {
                    img {
                         width: 75px;
                    }
               }

               .site_header_right {
                    .site_header_right_menu {
                         .menu_button {
                              .menu_button_text{
                                   display: none;
                              }
                              .menu_button_icon {
                                   // width: 36px;
                                   // height: 36px;
                                   .menu_button_icon_line {}
                              }

                              &:hover {
                                   .menu_button_icon {
                                        .menu_button_icon_line:nth-child(1) {}

                                        .menu_button_icon_line:nth-child(3) {}
                                   }
                              }

                              &.menu_button--open {
                                   .menu_button_icon {
                                        .menu_button_icon_line:nth-child(1) {}

                                        .menu_button_icon_line:nth-child(2) {}

                                        .menu_button_icon_line:nth-child(3) {}
                                   }

                                   &:hover {}
                              }
                         }
                    }

                    .site_header_right_nav {
                         &.site_header_right_nav--open {
                              opacity: 1;
                              right: 0;
                         }

                         .siteMenu {
                              padding: 80px 20px;
                              list-style: none;

                              li {
                                   a {
                                        display: block;
                                        text-align: right;
                                        width: 100%;
                                        color: #dee2e8;
                                        text-decoration: none;
                                        padding: 16px 0;
                                        transition-duration: .2s;

                                        &:hover {
                                             opacity: .6;
                                        }
                                   }
                              }
                         }
                    }
               }
          }
     }
}
.single_post{
     float: left;
     width: 100%;
     padding-top: 110px;
     padding-bottom: 140px;
}

@import 'postContent/style.scss';
@import 'postSidebar/style.scss';

@include media-breakpoint-down(sm) {
     .single_post {
          float: left;
          width: 100%;
          padding-top: 45px;
          padding-bottom: 60px;
     }
}
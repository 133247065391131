.mainPage_ourPassion{
	float: left;
	width: 100%;

	padding-bottom: 200px;

	.container{
		position: relative;
		.ourPassion_wrap{
			float: left;
			width: 100%;
			position: relative;
		}
		.ourPassion{
			max-width: 530px;
			height: 513px;
			float: left;

			.ourPassion_title {
				color: $global;
				font-size: 30px;
				font-weight: 600;
				line-height: 36px;
				margin-bottom: 40px;
				letter-spacing: -1.2px;
			}
			.ourPassion_description{
				color: $global;
				font-size: 16px;
				font-weight: 400;
				margin-bottom: 65px;
				line-height: 32px;
			}
			.ourPassion_link{
				a{
					@include buttonAnimation();
					max-width: 244px;
				}
			}
		}
		.ourPassion_images{
			float: right;
			height: 100%;
			&::after {
				display: block;
				position: absolute;
				right: 2vw;
				bottom: 10vh;
				content: '';
				width: 100%;
				height: 100%;
				background-repeat: no-repeat;
				background-position: bottom right;
				pointer-events: none;
				z-index: 1;
				background-image: url(../img/bg/4.png);
			}

			img{
				position: absolute;
				top: 0;
				box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
				right: 0;
				&:nth-child(1){}
				&:nth-child(2){
					right: 15vw;
					top: 15vh;

				}
				&:nth-child(3){
					right: 30vw;
					bottom: 0;
					top: auto;
				}
			}
		}
	}
}

@media (max-width: 1300px) {
	.mainPage_ourPassion .container .ourPassion_images img:nth-child(2) {
		right: 10vw;
	}
	.mainPage_ourPassion .container .ourPassion_images img:nth-child(3) {}
}
@media (max-width: 1200px) {
	.mainPage_ourPassion .container .ourPassion_images img{
		width: 300px;
	}
	.mainPage_ourPassion .container .ourPassion_images img:nth-child(2) {
		right: 14vw;
		top: 22vh;
	}

	.mainPage_ourPassion .container .ourPassion_images img:nth-child(3) {
		right: 30vw;
		bottom: 6vw;
	}
}
@import 'rwd/tablet';
@import 'rwd/mobile';
@include media-breakpoint-down(sm) {
     .consulting_mainPage {
          float: left;
          width: 100%;
          padding-top: 20px;

          .container {
               .consulting_mainPage_title {
                    margin: 0 auto;
                    margin-bottom: 30px;

                    max-width: 510px;
                    font-weight: 600;

                    font-size: 20px;
                    letter-spacing: -0.8px;
                    line-height: 28px;

               }

               .consulting_mainPage_items {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: normal;


                    .consulting_mainPage_item {
                         width: 100%;
                         text-align: center;
                         margin-bottom: 40px;
                         ;
                         position: relative;
                         float: left;
                         padding-bottom: 30px;


                         .item_image_mobile {
                              display: block;
                              width: 100%;
                              margin-bottom: 30px;

                              img {
                                   width: 100%;
                              }
                         }

                         .item_wrap {
                              padding: 0 15px;
                              margin-bottom: 0px;
                              height: 100%;
                         }

                         .item_title {
                              font-size: 16px;
                              line-height: 24px;
                              text-transform: uppercase;
                              font-weight: 900;
                              margin-bottom: 10px;
                         }

                         .item_description {
                              font-size: 16px;
                              line-height: 26px;
                              margin-bottom: 20px;

                         }

                         .item_link {
                              position: absolute;
                              bottom: 0px;
                              width: 100%;
                              left: 0;
                              text-align: center;

                              a {
                                   color: $red;
                                   font-size: 16px;
                                   line-height: 30px;
                                   font-weight: 900;
                                   text-decoration: none;

                                   &:hover {
                                        color: $redHover;
                                   }
                              }
                         }

                         &.consulting_mainPage_item--image {
                              display: none;
                         }
                    }
               }
          }
     }

}

.subPage_blankPage {
	float: left;
	width: 100%;
	padding-bottom: 150px;
	padding-top: 110px;
	.container{
		.blankPage_content{
			max-width: 876px;
			margin: 0 auto;

			*:last-child {
				margin-bottom: 0px;
			}

			p {
				margin-bottom: 40px;
			}

			ul,
			ol {
				list-style: none;
				padding: 0;
				margin: 0;
				float: left;
				width: 100%;
				margin-bottom: 40px;

				li {
					// line-height: 50px;
					padding-left: 20px;
					position: relative;
					float: left;
					width: 100%;
					margin-bottom: 20px;
					&::before {
						width: 7px;
						display: inline-block;
						height: 10px;
						background-image: url(../img/icons/arrowRight-red.svg);
						content: '';
						position: absolute;
						left: 0;
						top: 7px;
					}

					&:last-child {
						margin-left: 0px;
					}

					ul,
					ol {
						li {
							&::before {
								display: none;
							}
						}
					}
				}
			}

			img {
				max-width: 100%;
				margin-bottom: 40px;
				height: auto;
				margin-top: 10px;
			}
		}
	}
}

@import 'rwd/tablet';
@import 'rwd/mobile';
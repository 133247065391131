@include media-breakpoint-down(md){
 .single_post_news {
      float: left;
      width: 100%;
      max-width: 920px;
      margin: 0 auto;

      
 }

}
.subPage_textBox_imageLeft_inGrid-6-5{
	float: left;
	width: 100%;
	padding-top: 110px;
	padding-bottom: 130px;
	
	.container{
		position: relative;
		.subPage_textBox_imageLeft_inGrid-6-5_title {
			max-width: calc(876px + 80px);
			margin: 0 auto;
			color: $global;
			font-size: 16px;
			font-weight: 700;
			letter-spacing: -0.03px;
			line-height: 32px;
			margin-bottom: 80px;
		}
		.subPage_textBox_imageLeft_inGrid-6-5_content{
			.subPage_textBox_imageLeft_inGrid-6-5_content_wrap {
				

				.subPage_textBox_imageLeft_inGrid-6-5_content_image {
					float: left;
					position: relative;
					width: 100%;

					.wrap {
						position: relative;
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 22%;
						&.reset_margin {
							margin-bottom: 2%;
						}
						img {
							width: 48%;
							z-index: 2;
							-webkit-box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
							box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
						}
						.image2{
							position: absolute;
							top: 79%;
							left: 50%;
							transform: translateX(-50%);
							z-index: 1;
							-webkit-box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
							box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
						}
						.image3{    
							position: relative;
							margin-top: 6%;
							z-index: -1;
							-webkit-box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
							box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
						}
					}

				

					&::before {
						display: block;
						width: 0;
						height: 0;
						border: 50px solid transparent;
						border-right: 100px solid $red;
						filter: blur(2px);
						// right: -500px;
						content: '';
						position: absolute;

						transform: scale(.6);
						right: 0px;z-index: 1;
						bottom: 27%;
					}

				}

				.subPage_textBox_imageLeft_inGrid-6-5_content_text {
					float: left;
					width: 100%;

					padding-top: 10px;
					padding-left: 30px;

					color: $global;
					font-size: 16px;
					font-weight: 400;

					min-height: 100%;
					display: flex;
					flex-wrap: wrap;
					align-content: flex-start;

					line-height: 32px;
					strong{
						
						font-size: 16px;
						font-weight: 700;
					}

					p {
						margin-bottom: 30px;
						float: left;
						width: 100%;
					}

					ul, ol{
						list-style: none;
						padding: 0; margin: 0;
						float: left;
						width: 100%;
						
						li{
							line-height: 50px;
							padding-left: 20px;
							position: relative;
							float: left;
							width: 100%;
							&::before{
								width: 7px;
								display: inline-block;
								height: 10px;
								background-image: url(../img/icons/arrowRight-red.svg);
								content: '';
								position: absolute;
								left: 0;
								top: 20px;
							}
						}
					}
					.item_link {
						@include buttonAnimation();
						width: 244px;
						margin-top: 36px;

					}
				}
			}
		}
	}
}

@import 'rwd/tablet';
@import 'rwd/mobile';
@include media-breakpoint-down(sm) {
     .subPage_iconsRow {
          float: left;
          width: 100%;
          padding-bottom: 40px;


          .container {
               .iconsRow_title {
                    color: $global;
                    font-size: 20px;
                    font-weight: 600;
                    letter-spacing: -0.8px;
                    line-height: 28px;
                    margin-bottom:50px;
               }

               .iconsRow_content {
                    float: left;
                    width: 100%;

                    .iconsRow_content_wrap {
                         max-width: 872px;
                         margin: 0 auto;

                         .iconsRow_content_item {
                              margin-bottom: 60px;

                              .item_icon {
                                   width: 100%;
                                   height: 93px;
                                   display: flex;
                                   align-items: center;
                                   justify-content: center;

                                   img {
                                        max-width: 92px;
                                        max-height: 93px;
                                   }
                              }

                              .item_title {
                                   margin: 0 auto;
                                   width: 257px;
                                   text-align: center;
                                   color: $global;
                                   margin-top: 40px;
                                   font-size: 16px;
                                   font-weight: 400;
                                   line-height: 30px;
                              }
                         }
                    }
               }
          }
     }
}
@include media-breakpoint-down(md){
 .carrierZone_subPage {
      float: left;
      width: 100%;
      padding-top: 200px;
      padding-bottom: 40px;

      &.carrierZone_subPage--marginChange {
           padding-top: 100px;
      }

      .container {
           .carrierZone_wrap {
                position: relative;
                float: left;
                width: 100%;
           }

           .carrierZone_subPage_bacgkround {
                position: absolute;
                top: -100%;
                bottom: 50%;
                left: 0;
                width: 100%;
                right: 0;

           }

           .carrierZone_subPage_box {
                display: flex;
                background-color: #202020;
                padding: 60px 45px;
                justify-content: center;
                position: relative;
                z-index: 1;
                align-items: center;
                width: 612px;
                float: none;
                margin: 0 auto;

                .box_title {
                     color: #ffffff;
                     font-size: 26px;
                     line-height: 32px;
                     margin: 0;
                     padding: 0;
                }

                a {
                     @include buttonAnimation();
                     margin-left: auto;
                     max-width: 244px;
                }
           }
      }
 }
}
.imageBox{
	float: left;
	width: 100%;

	.imageBox_content{
		width: 70%;
		height: 330px;
		box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
		float: right;
		background-size: cover;
		background-position: center;
	}
}

@import 'rwd/tablet';
@import 'rwd/mobile';
@include media-breakpoint-down(md){
     .offer_subPage {
          padding-top: 110px;
          padding-bottom: 60px;
          .container {
               .offer_subPage_points {
                    .offer_subPage_points_wraper {
                         flex-wrap: wrap;
                         width: calc(100% + 20px);
                         margin-left: -10px;
                         .offer_subPage_points_wraper_item {
                              width: calc(50% - 20px);
                              margin: 0 10px;
                              margin-bottom: 20px;

                              .item_wrap {
                              }
                              .item_icon {
                                   img {
                                   }
                              }
                              .item_title {
                              }
                              .item_description {
                              }
                              &.offer_subPage_points_wraper_item--darkBackground {
                              }
                              &.offer_subPage_points_wraper_item--blankItem {
                                   width: 30%;
                              }
                              &.offer_subPage_points_wraper_item--info {
                                   .item_wrap {
                                        .item_title {
                                             strong {
                                             }
                                        }
                                        .item_link {
                                        }
                                   }
                              }
                              &.offer_subPage_points_wraper_item--z1 {
                              }
                         }
                    }
               }
               .offer_subPage_rightImage {
                    display: none;
               }
          }
     }
}
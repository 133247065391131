@include media-breakpoint-down(sm) {
     .single_post_news {
          float: left;
          width: 100%;
          max-width: 920px;
          margin: 0 auto;

          .single_post_news_header {
               margin-bottom: 20px;

               .single_post_news_title {
                    color: $global;
                    font-size: 16px;
                    font-weight: 700;
                    letter-spacing: -0.64px;
                    line-height: 25px;
               }

               .single_post_news_date {
                    color: #9ca0a6;
                    font-size: 12px;
                    font-weight: 400;
                    letter-spacing: -0.48px;
                    line-height: 25px;
               }
          }

          .single_post_news_image {
               img {
                    width: 100%;
               }
          }

          .single_post_news_content {
               color: $global;
               font-size: 16px;
               font-weight: 400;
               line-height: 26px;
               text-align: center;t
               *:last-child {
                    margin-bottom: 0px;
               }

               p {
                    margin-bottom: 30px;
               }

               ul,
               ol {
                    text-align: left;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    float: left;
                    width: 100%;
                    margin-bottom: 30px;

                    li {
                         line-height: 50px;
                         padding-left: 20px;
                         position: relative;
                         float: left;
                         width: 100%;

                         &::before {
                              width: 7px;
                              display: inline-block;
                              height: 10px;
                              background-image: url(../img/icons/arrowRight-red.svg);
                              content: '';
                              position: absolute;
                              left: 0;
                              top: 20px;
                         }

                         &:last-child {
                              margin-left: 0px;
                         }
                    }
               }

               img {
                    max-width: 100%;
                    margin-bottom: 30px;
                    margin-top: 10px;
               }
          }

          .single_post_news_back {
               float: left;
               width: 100%;

               a {
                    color: $red;
                    font-size: 14px;
                    font-weight: 700;
                    letter-spacing: -0.56px;
                    line-height: 25px;
                    display: block;
                    margin-top: 30px;
                    transition-duration: .2s;
                    text-decoration: none;

                    &:hover {
                         color: $redHover;
                    }
               }
          }
     }

}
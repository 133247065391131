.site_header{
	float: left;
	width: 100%;
	position: fixed;

	top: 0;
	z-index: 10000;

	&.site_header--scroll{
		.site_header_container{
			padding-top: 20px;
			padding-bottom: 20px;
			background-color: #fff;
		}
	}

	.site_header_container{
		padding: 40px 50px;
		float: left;
		width: 100%;
		display: flex;
		transition-duration: .3s;
		align-items: center;
		.site_header_left{
			img{
				width: 194px;
			}
		}
		.site_header_right{
			margin-left: auto;
			.site_header_right_menu{
				z-index: 1;
				position: relative;
				font-size: 18px;
				.menu_button{
					text-transform: uppercase;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					transition-duration: .4s;
					cursor: pointer;

					.menu_button_icon{
						display: flex;
						margin-left: 10px;
						flex-wrap: wrap;
						align-content: center;
						justify-content: center;
						width: 44px; height: 44px;

						background-color: #000;
						padding: 0 13px;
						border-radius: 100px;
						transition-duration: .4s;
						

						.menu_button_icon_line{
							display: block;
							margin: 2px 0;
							transition-duration: .2s;
							width: 100%;
							position: relative;
							height: 2px;
							background-color: #fff;
							left: 0;
						}
					}
					&:hover {
						.menu_button_icon{
							// .menu_button_icon_line:first-child {
							// 	transform: rotate(-45deg);
							// 	transform-origin: right;
							// }
							// .menu_button_icon_line:last-child {
							// 	transform: rotate(45deg);
							// 	transform-origin: right;
							// }

							.menu_button_icon_line:nth-child(1) {
								left: -3px;
							}
							.menu_button_icon_line:nth-child(3) {
								left: 3px;
							}
						}
					}
					&.menu_button--open{
						color: #fff;
						.menu_button_icon {
							background-color: #fff;
							padding-top: 1px;
							padding-left: 12px;
						
							.menu_button_icon_line:nth-child(1) {
								left: 0px;
								transform: rotate(-45deg);
								margin: -2px 0;
								background-color: #000000;
							}
							.menu_button_icon_line:nth-child(2) {
								left: 0px;
								margin: -2px 0;
								width: 0px;
								background-color: #000000;
							}
							.menu_button_icon_line:nth-child(3) {
								left: 0px;
								margin: -2px 0;
								transform: rotate(45deg);
								background-color: #000000;

							}
						}
						&:hover{

						}
					}
				}
			}
			.site_header_right_nav{
				position: absolute;
				opacity: 0;
				right: -100%;
				background-color: $global;
				min-height: 100vh;
				overflow: auto;
				width: 100%;
				max-width: 478px;
				height: 100vh;
				top: 0;
				transition-duration: .5s;

				&.site_header_right_nav--open{
					opacity: 1;
					right: 0;
				}
				.siteMenu{
					padding: 140px 50px 0 50px;
					list-style: none;
					li{
						a{
							display: block;
							text-align: right;
							width: 100%;
							color: #dee2e8;
							text-decoration: none;
							padding: 16px 0;
							transition-duration: .2s;
							&:hover{
								opacity: .6;
							}
						}
					}
				}
				.languages{
					padding: 0; margin: 0;
					list-style: none;
					display: flex;
					justify-content: flex-end;
					padding-right: 50px;
					li{
						margin-left: 10px;
						img{
							height: 15px;
						}
					}	
				}
			}
		}
	}
}

@import 'rwd/tablet';
@import 'rwd/mobile';
@include media-breakpoint-down(sm) {
     .imageBox {
          float: left;
          width: 100%;
          margin-bottom: 30px;

          .imageBox_content {
               width: calc(100% - 20px);
               height: 95px;
               box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
               float: right;
               background-size: cover;
               background-position: center;
          }
     }
}
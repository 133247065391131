@include media-breakpoint-down(sm) {
     .subPage_containerBox {
          float: left;
          width: 100%;
          padding-bottom: 60px;

          .container {
               .containerBox {
                    .column {
                         padding-left: 10px;
                         padding-right: 10px;
                    }

                    .containerBox_item {
                         padding: 25px;
                         display: flex;
                         flex-wrap: wrap;
                         align-items: center;
                         box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
                         margin-bottom: 20px;
                         cursor: pointer;
                         position: relative;
                         top: 0;
                         transition-duration: .2s;

                         .containerBox_item_image {
                              width: 100%;
                              text-align: center;   
                              margin-bottom: 20px;                           

                              img {
                                   width: 133px;
                              }
                         }

                         .containerBox_item_text {
                              width: 100%;
                              padding-left: 0;

                              color: $global;
                              font-size: 16px;
                              font-weight: 900;
                              line-height: 32px;
                              text-align: center;
                         }

                         &:hover {
                              box-shadow: 0 10px 70px rgba(0, 0, 0, 0.5);
                              top: -5px;

                         }

                    }

                    .containerBox_item_popup {
                         max-width: 460px;
                         width: 100%;
                         box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
                         background-color: $redHover;
                         position: fixed;
                         top: 0px;
                         margin-top: 0px;
                         left: 0%;
                         margin-left: 0px;
                         padding: 20px 36px 49px 36px;
                         color: #ffffff;
                         font-size: 16px;
                         font-weight: 400;
                         z-index: 99999999;
                         line-height: 32px;
                         cursor: default;
                         opacity: 0;
                         pointer-events: none;
                         transform: scale(0);
                         transition-duration: .3s;
                         max-height: 100vh;
                         height: 100vh;


                         .popup-close {
                              float: left;
                              width: 100%;
                              margin-bottom: 0px;

                              .wrap {
                                   float: right;
                                   position: relative;
                                   right: -36px;
                                   top: -20px;
                                   padding: 20px;
                                   cursor: pointer;

                                   img {
                                        width: 15px;
                                   }
                              }
                         }

                         strong {
                              font-weight: 700;
                         }

                         p {
                              margin-bottom: 30px;

                              &:last-child {
                                   margin-bottom: 0;
                              }
                         }

                         &.containerBox_item_popup--active {
                              opacity: 1;
                              transform: scale(1);
                              pointer-events: all;
                         }
                    }
               }
          }
     }
}
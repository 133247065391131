@include media-breakpoint-down(md){
     .site_footer {
          float: left;
          width: 100%;
          border-top: 30px solid #f3f3f3;

          .container {
               .footer_wrap {
                    padding-top: 60px;
                    padding-bottom: 70px;
                    float: left;
                    width: 100%;

                    .footer_item {
                         .footer_item_header {
                              color: $red;
                              font-size: 18px;
                              font-weight: 700;
                              line-height: 32px;
                              margin-bottom: 18px;
                         }

                         .footer_item_content {
                              .footer_item_content_list {
                                   list-style: none;
                                   padding: 0;
                                   float: left;
                                   width: 100%;
                                   margin-bottom: 40px;

                                   li {
                                        a {
                                             color: #9ca0a6;
                                             font-size: 16px;
                                             font-weight: 400;
                                             line-height: 38px;
                                             transition-duration: .2s;
                                             text-decoration: none;

                                             &:hover {
                                                  color: $footerHover;
                                             }
                                        }

                                        &.facebook {
                                             margin-top: 50px;

                                             a {
                                                  color: #9ca0a6;
                                                  font-size: 16px;
                                                  font-weight: 900;
                                                  line-height: 38px;

                                                  &:hover {
                                                       color: $footerHover;
                                                  }
                                             }
                                        }
                                   }

                                   &.footer_item_content_list--red {
                                        margin-top: -10px;
                                        width: 40%;
                                        float: left;

                                        li {
                                             margin-bottom: 17px;

                                             a {
                                                  color: $red;
                                                  font-size: 18px;
                                                  font-weight: 700;
                                                  line-height: 32px;

                                                  &:hover {
                                                       color: $redHover;
                                                  }

                                                  img {
                                                       width: 26px;
                                                       margin-right: 15px;
                                                  }
                                             }
                                        }
                                   }
                              }

                              p {
                                   width: 60%;
                                   padding-right: 30px;
                                   float: left;
                                   color: #9ca0a6;
                                   font-size: 16px;
                                   font-weight: 400;
                                   line-height: 38px;
                              }
                         }
                    }
               }

               .footer_bottom {
                    display: flex;
                    padding: 30px 0;
                    border-top: 1px solid #e9eef5;
                    float: left;
                    width: 100%;

                    .left {
                         color: #9ca0a6;
                         font-size: 14px;
                         font-weight: 400;
                         line-height: 38px;
                    }

                    .right {
                         margin-left: auto;
                         color: #9ca0a6;
                         font-size: 14px;
                         font-weight: 400;
                         line-height: 38px;

                         img {
                              width: 124px;
                         }
                    }
               }
          }
     }

     .goUp {
          position: absolute;
          right: 30px;
          bottom: 130px;

          .goUp_wrap {
               display: block;
               width: 54px;
               height: 54px;
               background-color: #9ca0a6;
               display: flex;
               align-items: center;
               justify-content: center;
               border-radius: 100px;

               img {
                    width: 23px;
               }
          }
     }

}
@include media-breakpoint-down(sm) {
     .offer_mainPage {
          padding-top: 20px;
          padding-bottom: 30px;
          float: left;
          width: 100%;
          .container {
               .offer_mainPage_header {
                    display: none;
               }

               .offer_mainPage_points {
                    .offer_mainPage_points_wraper {
                         flex-wrap: wrap;
                         .offer_mainPage_points_wraper_item {
                              margin-bottom: 20px;
                              width: 100%;
                              min-height: 280px;

                              .item_wrap {
                                   padding: 20px;
                              }

                              .item_icon {
                                   img {}
                              }

                              .item_title {}

                              .item_description {}

                              &.offer_mainPage_points_wraper_item--darkBackground {}

                              &.offer_mainPage_points_wraper_item--blankItem {
                                   display: none;
                              }

                              &.offer_mainPage_points_wraper_item--info {
                                   .item_wrap {
                                        .item_title {
                                             strong {}
                                        }

                                        .item_link {}
                                   }
                              }

                              &.offer_mainPage_points_wraper_item--z1 {}
                         }
                    }
               }

               .offer_mainPage_rightImage {
                    display: none;
               }
          }
     }
}
@include media-breakpoint-down(md){
 .subPage_textBox_imageRight {
      float: left;
      width: 100%;
      padding-top: 110px;
      padding-bottom: 75px;

      .container {
           max-width: calc(876px + 80px);
          &::before {
               display: none;
          }
           .textBox_imageRight_title {
                color: $global;
                font-size: 16px;
                font-weight: 700;
                letter-spacing: -0.03px;
                line-height: 32px;
           }

           .textBox_imageRight_wrap {
                .textBox_imageRight_text {
                     float: left;
                     padding-top: 10px;
                     width: 60%;
                     padding-right: 40px;

                     p {
                          color: $global;
                          font-size: 16px;
                          font-weight: 400;
                          line-height: 32px;
                     }
                }

                .textBox_imageRight_image {
                     float: left;
                     width: 70%;
                     margin-right: -40%;
                     background-color: red;
                     box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
                    .wrap{
                         img {
                              width: 100%;
                         }
                    }

                    &::after {
                         display: none;
                    }
                    &::before {
                         display: none;
                    }
                }
           }
      }
 }

}
.subPage_contact {
	float: left;
	width: 100%;
	
	.container{
		.contact{
			float: left;
			width: 100%;
			padding-top: 110px;
			padding-bottom: 120px;
		}
		.contact_header{
			max-width: 876px;
			margin: 0 auto;
			
			font-size: 16px;
			font-weight: 400;
			letter-spacing: -0.03px;
			line-height: 32px;
			.contact_header_wrap{
				float: left;
				width: 100%;
				margin-bottom: 60px;
			}
			strong{
				font-weight: 700;
				display: block;
				float: left;
				width: 100%;
			}
			p{
				margin-bottom: 40px;
				display: block;
				float: left;
				width: 100%;
				&:last-child{
					margin-bottom: 0;
				}
			}
			a {
				color: $red;
			}
		}
		.contact_content{
			float: left;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			.contact_content_map{
				width: 50%;
				float: left;
				margin-right: -50%;
				height: 630px
			}
			.contact_content_right{
				max-width: 761px;
				float: right;
				margin-left: auto;
				width: 100%;
				z-index: 1;
				position: relative;
				pointer-events: none;
				.contact_content_box{
					box-shadow: 0 0 70px rgba(0, 0, 0, 0.1);
					background-color: #fff;
					padding: 50px 50px 50px 70px;
					pointer-events: all;

					.box_item{
						display: flex;
						.box_item_name{
							color: $global;
							font-size: 16px;
							font-weight: 700;
							letter-spacing: -0.03px;
							line-height: 40px;
							width: 220px;
						}
						.box_item_phone{
							width: 165px;
							padding-left: 20px;
							a{
								color: $global;
								font-size: 16px;
								font-weight: 400;
								letter-spacing: -0.03px;
								line-height: 40px;
							}
						}
						.box_item_mail{
							width: 172px;
							margin-left: auto;
							a{
								color: $red;
								font-size: 16px;
								font-weight: 700;
								letter-spacing: -0.03px;
								line-height: 40px;
							}
						}
					}
				}
				.contact_content_boxBottom{
					pointer-events: all;
					width: 100%;
					float: left;
					display: flex;
					justify-content: center;
					margin-top: 50px;
					p{
						float: left;
						color: $global;
						font-size: 16px;
						font-weight: 400;
						letter-spacing: -0.03px;
						line-height: 32px;
						margin: 0 25px;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

@import 'rwd/tablet';
@import 'rwd/mobile';
@include media-breakpoint-down(md){
     .intro_mainPage {
          justify-content: center;
          background-size: auto;
          min-height: 680px;

          justify-content: center;
          padding-top: 20px;
          // padding-bottom: 0px;
          min-height: 420px;
          background-size: 100% auto;
          // background-position: right bottom -200px;
          background-position: top center;
          
          .container {
               .intro_mainPage_content {
                    padding-top: 20px;
                    float: none;
                    margin: 0 auto;

                    .intro_mainPage_content_wrap_title {
                         text-align: center;
                         h1 {
                              p{
                                   font-size: 50px;
                                   letter-spacing: -2px;
                                   span {
                                   }
                              }
                         }

                         p {
                              font-size: 22px;
                              font-weight: 300;
                         }
                    }

                    .intro_mainPage_content_wrap_popup {
                         margin-top: 140px;
                         a {
                                text-align: center;
                              strong {
                              }

                              .popup_icon{
                                   img {
                                   }
                                   &:hover {
                                   }
                              }
                         }
                    }
               }
          }

          .intro_mainPage_animation {
               .animation_triangle {
			     animation: none;
                    
                    &.animation_triangle:nth-child(1) {
                         right: -60px;
                    }

                    &.animation_triangle:nth-child(2) {
                         left: 0;
                         transform: scale(.4);
                    }

                    &.animation_triangle:nth-child(3) {
                         transform: scale(.6);
                         top: 50%;
                         left: -50px;
                    }

                    &.animation_triangle:nth-child(4) {
                         top: auto;
                         bottom: 0;
                         left: -5%;
                    }

                    &.animation_triangle:nth-child(5) {
                    }

                    &.animation_triangle:nth-child(6) {
                    }

                    &.animation_triangle:nth-child(7) {
                    }

                    &.animation_triangle:nth-child(8) {
                    }

                    &.animation_triangle:nth-child(9) {
                    }

                    &.animation_triangle:nth-child(10) {
                    }
               }
          }
     }
     
     .intro_mainPage .container .intro_mainPage_content{
          margin: 0;
          margin-left: auto;
     }
     .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title h1{
          margin-top: 30vw;
          margin-bottom: 10vw;
          text-align: right;
     }
     .intro_mainPage .container .intro_mainPage_content{
          max-width: 100%;
          width: 100%;
     }
     .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup{
          margin-top: 50px;
     }
     .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup{
          display: flex;
          justify-content: center;
     }
}
.consulting_mainPage {
	float: left;
	width: 100%;
	padding-top: 90px;
	.container{
		.consulting_mainPage_title{
			font-size: 30px;
			letter-spacing: -1.2px;
			line-height: 36px;
			max-width: 546px;
			text-align: center;
			margin: 0 auto;
			margin-bottom: 110px;
			font-weight: 600;
		}
		.consulting_mainPage_items{
			display: flex;
			flex-wrap: wrap;
			align-items: center;


			.consulting_mainPage_item{
				width: 33.333%;
				text-align: center;

				.item_image_mobile{
					display: none;
				}
				.item_wrap{
					padding: 0 30px;
					margin-bottom: 60px;
				}
				
				.item_title{
					font-size: 16px;
					line-height: 30px;
					text-transform: uppercase;
					font-weight: 900;
					margin-bottom: 10px;
				}
				.item_description{
					font-size: 16px;
					line-height: 30px;
					margin-bottom: 20px;

				}
				.item_link{
					a{
						color: $red;
						font-size: 16px;
						line-height: 30px;
						font-weight: 900;
						text-decoration: none;
						&:hover{
							color: $redHover;
						}
					}
				}
				&.consulting_mainPage_item--image{
					text-align: center;
					position: relative;
					img{
						max-width: 100%;
					}

					&::after{
						display: block;
						position: absolute;
						right: 0;
						bottom: 30px;
						content: '';
						width: 100%;
						height: 100%;
						background-repeat: no-repeat;
						background-position: bottom right;
						pointer-events: none;
						z-index: 1;
					}
					&:nth-child(6n + 2) {
						&::after{
							background-image: url(../img/bg/1.png);
						}
					}
					&:nth-child(6n + 4) {
						&::after{
							background-image: url(../img/bg/2.png);
							right: auto;
							left: 0;
							background-position: left bottom;
						}
					}
					&:nth-child(6n + 6) {
						&::after{
							background-image: url(../img/bg/3.png);
						}
					}
				}
			}
		}
	}
}

@media (min-width: 992px){
	.consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item:nth-child(4) {
		order: 3;
	}
	.consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item:nth-child(5) {
		order: 2;
	}
	.consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item:nth-child(6) {
		order: 1;
	}
}
@import 'rwd/tablet';
@import 'rwd/mobile';
.subPage_iconsRow {
	float: left;
	width: 100%;
	padding-bottom: 40px;
	.container{
		.iconsRow_title{
			color: $global;
			font-size: 24px;
			font-weight: 600;
			letter-spacing: -0.96px;
			line-height: 36px;
			text-align: center;
			margin-bottom: 100px;
		}
		.iconsRow_content{
			float: left;
			width: 100%;
			.iconsRow_content_wrap{
				max-width: 872px;
				margin: 0 auto;
				.iconsRow_content_item{
					margin-bottom: 80px;
					.item_icon{
						width: 100%;
						height: 93px;
						display: flex;
						align-items: center;
						justify-content: center;
						img{
							max-width: 92px;
							max-height: 93px;
						}
					}
					.item_title{
						margin: 0 auto;
						max-width: 257px;
						width: 100%;
						text-align: center;
						color: $global;
						margin-top: 40px;
						font-size: 16px;
						font-weight: 400;
						line-height: 30px;
					}
				}
			}
		}
	}
}

@import 'rwd/tablet';
@import 'rwd/mobile';
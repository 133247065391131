@include media-breakpoint-down(md){
     .subPage_quote {
	margin-bottom: 100px;
          .container {
               .quote_content {
                    font-size: 18px;
                    line-height: 32px;
                    p {
                       font-size: 18px;
                       line-height: 32px;
                    }
                    &::before {
                         display: block;
                         width: 0;
                         height: 0;
                         border: 50px solid transparent;
                         border-right: 100px solid $red;
                         filter: blur(2px);
                         // right: -500px;
                         content: '';
                         position: absolute;
                         left: auto;
                         transform: scale(.6);
                         right: 30%;
                         bottom: -50px;
                    }
               }
          }
     }

}
.blog_news {
	float: left;
	width: 100%;
	padding-top: 180px;
	padding-bottom: 70px;
	.container{
		.news_title{
			font-size: 30px;
			letter-spacing: -1.2px;
			line-height: 36px;
			margin-bottom: 60px;
			text-align: center;
			width: 100%;
			float: left;
			font-weight: 600;
		}
		.news_articles{
			float: left;
			width: 100%;
			.news_article{
				text-align: center;
				margin-bottom: 110px;
				.news_article_image{
					img{
						max-width: 100%;
					}
				}
				.news_article_wrap{
					.news_article_date{
						color: #202020;
						font-size: 10px;
						letter-spacing: -0.4px;
						line-height: 25px;
						margin-top: 30px;
						margin-bottom: 16px;
						font-weight: 700;
						width: 100%;
					}
					.news_article_title{
						color: $red;
						font-size: 20px;
						letter-spacing: -0.8px;
						line-height: 25px;
						font-weight: 700;
						padding: 0 30px;
						width: 100%;
						a{
							color: $red;
							text-decoration: none;
							&:hover{
								color: $redHover;
							}
						}

					}
					.news_article_subtitle{
						color: #9ca0a6;
						font-size: 16px;
						letter-spacing: -0.64px;
						line-height: 25px;
						font-weight: 400;
						padding: 0 30px;
						margin-top: 15px;
						width: 100%;

					}
					.news_article_link{
						width: 100%;
						a{
							@include buttonAnimation();
							width: 186px;
							display: inline-block;
							margin-top: 50px;
						}
					}
				}
			}
		}

		.news_articles_pagination{
			float: left;
			width: 100%;
			display: flex;
			justify-content: center;
			.news_articles_pagination_items{
				a,
				span {
					float: left;
					font-size: 12px;
					font-weight: 700;
					line-height: 25px;
					color: $global;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #fff;
					width: 40px;
					height: 40px;
					text-decoration: none;
					border: 1px solid #d9d9d9;
					margin: 0 -1px;
					transition-duration: .2s;
					&:first-child {
						border-radius: 3px 0px 0px 3px;
					}
					&:last-child {
						border-radius: 0px 3px 3px 0px;
					}
					&:hover{
						background-color: $footerHover;
					}
				}
				.dots{
					&:hover {
						background-color: #fff;
					}
				}
				.current{
					background-color: $red;
					color: #ffffff;
					border: none;
					&:hover{
						background-color: $red;
					}

				}
			}
		}

	}
}

@import 'rwd/tablet';
@import 'rwd/mobile';
@include media-breakpoint-down(md){
 .subPage_textBox_imageLeft_inGrid-6-5 {
      float: left;
      width: 100%;
      padding-top: 110px;
      padding-bottom: 130px;

      .container {
           position: relative;

           .subPage_textBox_imageLeft_inGrid-6-5_title {
                max-width: calc(876px + 80px);
                margin: 0 auto;
                color: $global;
                font-size: 16px;
                font-weight: 700;
                letter-spacing: -0.03px;
                line-height: 32px;
                margin-bottom: 80px;
           }

           .subPage_textBox_imageLeft_inGrid-6-5_content {
                .subPage_textBox_imageLeft_inGrid-6-5_content_wrap {


                     .subPage_textBox_imageLeft_inGrid-6-5_content_image {
                          float: left;
                          position: relative;
                         height: 100%;
                         width: 100%;
                         margin-left: 0px;
                         box-shadow: none;


                          .wrap {
                              height: 100%;
                              overflow: hidden;
                              width: 100%;
                              margin-bottom: 40px;
                              flex-direction: column;
                               img {
                                    height: auto;
                                    width: 70%;
                                    float: right;
                                    box-shadow:none;

                               }
                               .image2 {
                                    position: relative;
                                    // left: -30%;
                                    // top: 70%;
                                    margin-top: -8%;
                                    margin-left: 0;
                                    float: left;

                                    width: 70%;
                                    box-shadow:none;
                                   
                                    transform: none;
                                    top: 0;
                                    left: 0;
                                    margin-top: 1%;

                               }
                               .image3 {
                                    position: relative;
                                   //  margin-top: -10%;
                                   //  margin-left: -20%;
                                    box-shadow: none;
                                    margin-top: 1%;
                                    margin-left: 0;
                               }
                          }

                           &::after {
                                display: none;

                           }

                           &::before {
                                display: none;

                           }

                     }

                     .subPage_textBox_imageLeft_inGrid-6-5_content_text {
                          float: left;
                          width: 100%;

                          padding-top: 10px;
                          padding-left: 0px;

                          color: $global;
                          font-size: 16px;
                          font-weight: 400;

                          min-height: 100%;
                          display: flex;
                          flex-wrap: wrap;
                          align-content: flex-start;

                          strong {

                               font-size: 20px;
                               font-weight: 700;
                               float: left;
                               width: 100%;
                          }

                          p {
                               margin-bottom: 30px;
                               float: left;
                               width: 100%;
                          }

                          ul,
                          ol {
                               list-style: none;
                               padding: 0;
                               margin: 0;
                               float: left;
                               width: 100%;

                               li {
                                    line-height: 50px;
                                    padding-left: 20px;
                                    position: relative;
                                    float: left;
                                    width: 100%;

                                    &::before {
                                         width: 7px;
                                         display: inline-block;
                                         height: 10px;
                                         background-image: url(../img/icons/arrowRight-red.svg);
                                         content: '';
                                         position: absolute;
                                         left: 0;
                                         top: 20px;
                                    }
                               }
                          }

                          .item_link {
                               @include buttonAnimation();
                               width: 244px;
                               margin-top: 36px;

                          }
                     }
                }
           }
      }
 }

}
@include media-breakpoint-down(md){
.subPage_blankPage {
     float: left;
     width: 100%;
     padding-bottom: 150px;
     padding-top: 110px;
     
}


}
@include media-breakpoint-down(md){
 .subPage_textBox_imageLeft_inGrid-5-5-center {
      float: left;
      width: 100%;
      padding-top: 110px;
      padding-bottom: 130px;

      .container {
           position: relative;

           .subPage_textBox_imageLeft_inGrid-5-5-center_title {
                max-width: calc(876px + 80px);
                margin: 0 auto;
                color: $global;
                font-size: 16px;
                font-weight: 700;
                letter-spacing: -0.03px;
                line-height: 32px;
                margin-bottom: 80px;
           }

           .subPage_textBox_imageLeft_inGrid-5-5-center_content {
                .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap {


                     .subPage_textBox_imageLeft_inGrid-5-5-center_content_image {
                          float: left;
                          box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
                          position: relative;
                         height: 100%;
                         width: calc(100% + 40px);
                         margin-left: -40px;


                          .wrap {
                              height: 100%;
                              overflow: hidden;
                               img {
                                    height: 100%;
                                    width: auto;
                               }
                          }

                           &::after {
                                display: none;

                           }

                           &::before {
                                display: none;

                           }

                     }

                     .subPage_textBox_imageLeft_inGrid-5-5-center_content_text {
                          float: left;
                          width: 100%;

                          padding-top: 10px;
                          padding-left: 30px;

                          color: $global;
                          font-size: 16px;
                          font-weight: 400;

                          min-height: 100%;
                          display: flex;
                          flex-wrap: wrap;
                          align-content: flex-start;

                          strong {

                               font-size: 20px;
                               font-weight: 700;
                               float: left;
                               width: 100%;
                          }

                          p {
                               margin-bottom: 30px;
                               float: left;
                               width: 100%;
                          }

                          ul,
                          ol {
                               list-style: none;
                               padding: 0;
                               margin: 0;
                               float: left;
                               width: 100%;

                               li {
                                    line-height: 50px;
                                    padding-left: 20px;
                                    position: relative;
                                    float: left;
                                    width: 100%;

                                    &::before {
                                         width: 7px;
                                         display: inline-block;
                                         height: 10px;
                                         background-image: url(../img/icons/arrowRight-red.svg);
                                         content: '';
                                         position: absolute;
                                         left: 0;
                                         top: 20px;
                                    }
                               }
                          }

                          .item_link {
                               @include buttonAnimation();
                               width: 244px;
                               margin-top: 36px;

                          }
                     }
                }
           }
      }
 }

}
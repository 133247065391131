@include media-breakpoint-down(md){
     .mainPage_ourPassion {
          float: left;
          width: 100%;

          padding-bottom: 200px;

          .container {
               position: relative;

               .ourPassion_wrap {
                    float: left;
                    width: 100%;
                    position: relative;
               }

               .ourPassion {
                    max-width: 530px;
                    height: auto;
                    float: none;
                    margin: 0 auto;
                    text-align: center;


                    .ourPassion_title {
                         color: $global;
                         font-size: 30px;
                         font-weight: 600;
                         line-height: 36px;
                         margin-bottom: 40px;
                         text-align: center;
                         letter-spacing: -1.2px;
                    }

                    .ourPassion_description {
                         color: $global;
                         font-size: 16px;
                         font-weight: 400;
                         margin-bottom: 65px;
                         text-align: center;
                         line-height: 32px;
                    }

                    .ourPassion_link {
                         a {
                              @include buttonAnimation();
                              max-width: 244px;
                              display: inline-block
                         }
                    }
               }

               .ourPassion_images {
                    float: left;
                    height: 100%;
                    width: 100%;
                    position: relative;
                    height: 70vw;
                    margin-top: 80px;
                    &::after {
                         display: block;
                         position: absolute;
                         right: 2vw;
                         bottom: 0vh;
                         content: '';
                         width: 100%;
                         height: 100%;
                         background-repeat: no-repeat;
                         background-position: bottom right;
                         pointer-events: none;
                         z-index: 1;
                         background-image: url(../img/bg/4.png);
                    }

                    img {
                         position: absolute;
                         top: 0;
                         box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
                         right: 0;
                         width: 60vw;

                         &:nth-child(1) {}

                         &:nth-child(2) {
                              right: 19vw;
                              top: 15vh;

                         }

                         &:nth-child(3) {
                              right: auto;
                              bottom: 0;
                              left: 0px;
                              top: auto;
                         }
                    }
               }
          }
     }
}
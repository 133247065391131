.carrierZone_mainPage {
	float: left;
	width: 100%;
	padding-top: 260px;
	padding-bottom: 90px;

	.container{
		.carrierZone_wrap{
			position: relative;
			float: left;
			width: 100%;
		}
		.carrierZone_mainPage_bacgkround{
			display: block;
			width: 646px;
			position: absolute;
			top: -40px;
			bottom: -40px;
			left: 0;
			background-color: #000;
			background-size: cover;

		}
		.carrierZone_mainPage_box{
			display: flex;
			background-color: #202020;
			padding: 70px 70px;
			justify-content: center;
			position: relative;
			z-index: 1;
			align-items: center;
			float: right;
			width: 761px;
			.box_title{
				color: #ffffff;
				font-size: 30px;
				line-height: 32px;
				margin: 0;
				padding: 0;
			}
			a{
				@include buttonAnimation();
				margin-left: auto;
				max-width: 244px;
			}
		}
	}
}
@media(max-width: 1300px){
	.carrierZone_mainPage{
		padding-top: 170px;
	}
}
@import 'rwd/tablet';
@import 'rwd/mobile';
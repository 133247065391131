@include media-breakpoint-down(md){
     .imageBox {
          float: left;
          width: 100%;
          margin-bottom: 40px;

          .imageBox_content {
               width: calc(100% - 40px);
               height: 214px;
               box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
               float: right;
               background-size: cover;
               background-position: center;
          }
     }
}
@include media-breakpoint-down(md){
     .subPage_textBox_imageLeft {
          float: left;
          width: 100%;
          margin-bottom: 115px;

          .container {
               max-width: calc(876px + 80px);
               position: relative;

               .textBox_imageLeft_content {
                    padding: 0 40px;
                    .textBox_imageLeft_content_wrap {
                         .textBox_imageLeft_content_text {
                              float: left;
                              padding-top: 10px;
                              width: 100%;
                              padding-left: 0px;
                              text-align: center;

                              p {
                                   color: $global;
                                   font-size: 16px;
                                   font-weight: 400;
                                   line-height: 32px;
                                   margin-bottom: 30px;
                              }

                              .item_link {
                                   @include buttonAnimation();
                                   width: 244px;
                                   display: inline-block;
                                   margin-top: 36px;

                              }
                         }

                         .textBox_imageLeft_content_image {
                              float: left;
                              width: 100%;
                              margin-left: 0%;
                              background-color: red;
                              box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
                              position: relative;
                              margin-bottom: 40px;
                              .wrap {
                                   overflow: hidden;

                                   img {
                                        width: 100%;
                                   }
                              }

                              &::after {
                                   display: none;
                              }

                              &::before {
                                   display: none;
                              }

                         }
                    }
               }
          }
     }

}
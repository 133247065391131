.mainPage_news {
	float: left;
	width: 100%;
	padding-top: 100px;
	padding-bottom: 190px;
	.container{
		.news_title{
			font-size: 30px;
			letter-spacing: -1.2px;
			line-height: 36px;
			margin-bottom: 60px;
			text-align: center;
			width: 100%;
			float: left;
			font-weight: 600;
		}
		.news_articles{
			float: left;
			width: 100%;
			.news_article{
				text-align: center;
				.news_article_image{
					img{
						max-width: 100%;
					}
				}
				.news_article_wrap{
					.news_article_date{
						color: #202020;
						font-size: 10px;
						letter-spacing: -0.4px;
						line-height: 25px;
						margin-top: 30px;
						margin-bottom: 16px;
						font-weight: 700;
						width: 100%;
					}
					.news_article_title{
						color: $red;
						font-size: 20px;
						letter-spacing: -0.8px;
						line-height: 25px;
						font-weight: 700;
						padding: 0 30px;
						width: 100%;
						a{
							color: $red;
							text-decoration: none;
							&:hover{
								color: $redHover;
							}
						}

					}
					.news_article_subtitle{
						color: #9ca0a6;
						font-size: 16px;
						letter-spacing: -0.64px;
						line-height: 25px;
						font-weight: 400;
						padding: 0 30px;
						margin-top: 15px;
						width: 100%;

					}
					.news_article_link{
						width: 100%;
						a{
							@include buttonAnimation();
							width: 186px;
							display: inline-block;
							margin-top: 50px;
						}
					}
				}
			}
		}


	}
}

@import 'rwd/tablet';
@import 'rwd/mobile';
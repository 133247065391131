@include media-breakpoint-down(sm) {
     .carrierZone_mainPage {
          float: left;
          width: 100%;
          padding-top: 100px;
          padding-bottom: 40px;

          .container {
               .carrierZone_wrap {
                    position: relative;
                    float: left;
                    width: 100%;
               }

               .carrierZone_mainPage_bacgkround {
                    position: absolute;
                    top: -80%;
                    bottom: 50%;
                    left: 0;
                    width: 100%;
                    height: 120px;
                    right: 0;

               }

               .carrierZone_mainPage_box {
                    display: flex;
                    background-color: #202020;
                    margin: 0 10px;
                    padding: 15px;
                    justify-content: center;
                    position: relative;
                    z-index: 1;
                    align-items: center;
                    width: calc(100% - 20px);
                    flex-wrap: wrap;
                    float: none;
                    margin-left: 10px;
                    text-align: center;
                    justify-content: center;

                    .box_title {
                         color: #ffffff;
                         font-size: 26px;
                         line-height: 32px;
                         margin: 0;
                         padding: 0;
                         margin-bottom: 10px;
                         font-size: 20px;
                         width: 100%;
                    }

                    a {
                         @include buttonAnimation();
                         margin-left: 0px;
                         max-width: 244px;
                    }
               }
          }
     }
}
.subPage_textBox_imageRight {
	float: left;
	width: 100%;
	padding-top: 110px;
	padding-bottom: 75px;
	
	.container{
		max-width: calc(876px + 80px);
		position: relative;
		&::before {
			display: block;
			width: 0;
			height: 0;
			border: 50px solid transparent;
			border-right: 100px solid $red;
			filter: blur(1px);
			content: '';
			position: absolute;
			transform: scale(2);
			left: -35%;
			top: 100px;
		}

		.textBox_imageRight_title{
			color: $global;
			font-size: 16px;
			font-weight: 700;
			letter-spacing: -0.03px;
			line-height: 32px;
			margin-bottom: 30px;
		}
		.textBox_imageRight_wrap{
			.textBox_imageRight_text{
				float: left;
				padding-top: 30px;
				width: 60%;
				padding-right: 40px;
				p{
					color: $global;
					font-size: 16px;
					font-weight: 400;
					line-height: 32px;
					margin-bottom: 30px;
				}
			}
			.textBox_imageRight_image{
				float: left;
				width: 70%;
				margin-right: -40%;
				background-color: red;
				box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
				position: relative;
				.wrap{
					overflow: hidden;
					img {
						width: 100%;
					}
				}

				&::after {
					display: block;
					width: 0;
					height: 0;
					border: 50px solid transparent;
					border-right: 100px solid #fff;
					filter: blur(2px);
					// right: -500px;
					content: '';
					position: absolute;

					transform: scale(.8);
					left: -20px;
					bottom: 20px;
				}
				&::before {
					display: block;
					width: 0;
					height: 0;
					border: 50px solid transparent;
					border-right: 100px solid $red;
					filter: blur(2px);
					// right: -500px;
					content: '';
					position: absolute;

					transform: scale(1);
					right: 30px;
					top: -50px;
				}

			}
		}
	}
}

@import 'rwd/tablet';
@import 'rwd/mobile';
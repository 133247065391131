@include media-breakpoint-down(sm) {
     .subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_slider .slick-list{
          width: 100%;
          margin-left: 0;
          padding: 0px 0;
          padding-left: 0px;
          padding-left: 0px;
     }
     .subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_slider .item_slider_wrap{
          padding: 0;
     }
     .subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_slider .item_slider_wrap img{
          width: 100%;
          box-shadow: none;
          margin-top: 20px;
          margin-bottom: 20px;
     }
     .subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .imageSlider_item_pagination{
          margin-bottom: 50px;
     }
     .subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_title{
          color: $global;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: -0.03px;
          line-height: 30px;

          font-weight: 700;
     }
}
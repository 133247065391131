@include media-breakpoint-down(md){
     .offer_mainPage {
          .container {
               .offer_mainPage_header {
                    margin-top: 80px;
                    font-size: 28px;
               }
               .offer_mainPage_points {
                    .offer_mainPage_points_wraper {
                         .offer_mainPage_points_wraper_item {
                              width: 100%;
                              .item_wrap {
                              }
                              .item_icon {
                                   img {
                                   }
                              }
                              .item_title {
                              }
                              .item_description {
                              }
                              &.offer_mainPage_points_wraper_item--darkBackground {
                              }
                              &.offer_mainPage_points_wraper_item--blankItem {
                                   width: 30%;
                              }
                              &.offer_mainPage_points_wraper_item--info {
                                   .item_wrap {
                                        .item_title {
                                             strong {
                                             }
                                        }
                                        .item_link {
                                        }
                                   }
                              }
                              &.offer_mainPage_points_wraper_item--z1 {
                              }
                         }
                    }
               }
               .offer_mainPage_rightImage {
                    display: none;
               }
          }
     }
}
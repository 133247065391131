.subPage_quote {
	float: left;
	width: 100%;
	position: relative;
	z-index: 11;
	margin-bottom: 130px;

	&.quote--marginChange{
		margin-bottom: 0;
		margin-top: 110px;
	}
	.container{
		max-width: calc(876px + 80px);
		position: relative;

		.quote_content{
			background-color: $global;
			text-align: center;
			padding: 32px 50px;
			color: #ffffff;
			font-size: 18px;
			font-weight: 600;
			line-height: 32px;
			p{
				margin: 0;
				padding: 0;
			}

			&::before {
				display: block;
				width: 0;
				height: 0;
				border: 50px solid transparent;
				border-right: 100px solid $red;
				filter: blur(2px);
				// right: -500px;
				content: '';
				position: absolute;

				transform: scale(.6);
				left: 30px;
				bottom: -50px;
			}
		}
	}
}

@import 'rwd/tablet';
@import 'rwd/mobile';
@include media-breakpoint-down(sm) {
     .subPage_textBox_imageLeft {
	     margin-bottom: 80px;
          

          .container {

               .textBox_imageLeft_content {
                    padding: 0px;
               }
          }
     }

}
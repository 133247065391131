@include media-breakpoint-down(sm) {
     .subPage_quote {
	margin-bottom: 70px;
          .container {
               .quote_content {
                    font-size: 16px;
                    line-height: 24px;
                    padding: 30px 20px;
                    padding-bottom: 45px;
                    p {
                         font-size: 16px;
                         line-height: 24px;
                    }
                    &::before {
                         display: block;
                         width: 0;
                         height: 0;
                         border: 50px solid transparent;
                         border-right: 100px solid $red;
                         filter: blur(2px);
                         // right: -500px;
                         content: '';
                         position: absolute;
                         left: auto;
                         transform: scale(.6);
                         right: 30%;
                         bottom: -50px;
                    }
               }
          }
     }

}
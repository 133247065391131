@include media-breakpoint-down(sm) {
     .subPage_contact {
          float: left;
          width: 100%;

          .container {
               .contact {
                    float: left;
                    width: 100%;
                    padding-top: 110px;
                    padding-bottom: 120px;
               }

               .contact_header {
                    max-width: 876px;
                    margin: 0 auto;

                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: -0.03px;
                    line-height: 32px;

                    .contact_header_wrap {
                         float: left;
                         width: 100%;
                         margin-bottom: 60px;
                    }

                    strong {
                         font-weight: 700;
                         display: block;
                         float: left;
                         width: 100%;
                    }

                    p {
                         margin-bottom: 40px;
                         display: block;
                         float: left;
                         width: 100%;

                         &:last-child {
                              margin-bottom: 0;
                         }
                    }

                    a {
                         color: $red;
                    }
               }

               .contact_content {
                    float: left;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    .contact_content_map {
                         width: 100%;
                         margin-bottom: -30px;
                         float: left;
                         margin-right: 0px;
                         height: 280px
                    }

                    .contact_content_right {
                         max-width: auto;
                         width: calc(100% - 40px);
                         float: none;
                         margin-left: 20px;
                         z-index: 1;
                         position: relative;
                         pointer-events: none;

                         .contact_content_box {
                              box-shadow: 0 0 70px rgba(0, 0, 0, 0.1);
                              background-color: #fff;
                              padding: 25px;
                              pointer-events: all;

                              .box_item {
                                   display: flex;
                                   flex-wrap: wrap;
                                   margin-bottom: 30px;
                                   &:last-child{
                                        margin-bottom: 0;
                                   }
                                   .box_item_name {
                                        color: $global;
                                        font-size: 16px;
                                        font-weight: 700;
                                        letter-spacing: -0.03px;
                                        line-height: 20px;
                                        width: 100%;
                                        font-size: 14px;
                                   }

                                   .box_item_phone {
                                        padding-left: 0px;
                                        width: 100%;

                                        a {
                                             color: $global;
                                             font-size: 16px;
                                             font-weight: 400;
                                             letter-spacing: -0.03px;
                                             line-height: 20px;
                                             font-size: 14px;
                                        }
                                   }

                                   .box_item_mail {
                                        margin-left: 0px;
                                        width: 100%;

                                        a {
                                             color: $red;
                                             font-size: 16px;
                                             font-weight: 700;
                                             letter-spacing: -0.03px;
                                             line-height: 20px;
                                             font-size: 14px;
                                        }
                                   }
                              }
                         }

                         .contact_content_boxBottom {
                              pointer-events: all;
                              width: 100%;
                              float: left;
                              display: flex;
                              justify-content: center;
                              flex-wrap: wrap;
                              margin-top: 50px;

                              p {
                                   float: left;
                                   color: $global;
                                   font-size: 16px;
                                   font-weight: 400;
                                   letter-spacing: -0.03px;
                                   line-height: 32px;
                                   margin: 0 25px;
                                   width: 100%;
                                   margin-bottom: 0;
                              }
                         }
                    }
               }
          }
     }

}
@import 'buttons';
@import 'colors';

body, html{
      font-family: 'Nunito Sans',sans-serif;
      width: 100%;
    //   height: 100%;
      max-width: 100%;
      min-width: 320px;
      overflow-x: hidden;
      color: $global;
}

.siteWrapper{
      background-color: #fff;
      float: left;
      width: 100%;
}
.no-padding{
      padding: 0;
}

.mainPage{
      background-color: #f1f1f1;
      float: left;
      width: 100%;
      position: relative;
      &::before {
            content: '';
            width: 100%;
            height: 100px;
            position: absolute;
            z-index: 0;
            top: 42%;
            left: 0;
            margin-top: -50px;
            border: 750px solid transparent;
            border-left: 2000px solid #fff;
      }
      .mainPage_wrap{
            z-index: 1;
            position: relative;
      }
}

@media (min-width: 1200px) {
      .container {
            width: 100%;
            max-width: 1420px;
            padding: 0 40px;

      }
}
@media (min-width: 768px) and (max-width: 991px) {
      .container {
            width: 100%;
            max-width: 991px;
            padding: 0 40px;

      }
}
@media (max-width: 767px){
      .container {
            padding: 0 20px;

      }
      .mainPage {
            background-color: #fff;
            &::before {
            display: none;
            }
      }
}


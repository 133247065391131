@include media-breakpoint-down(sm) {
     .mainPage_news {
          float: left;
          width: 100%;
          padding-top: 40px;
          padding-bottom:40px;

          .container {
               .news_title {
                    color: $global;
                    font-size: 20px;
                    font-weight: 600;
                    letter-spacing: -0.8px;
                    line-height: 28px;
                    margin-bottom: 40px;
               }

               .news_articles {
                    float: left;
                    width: 100%;

                    .news_article {
                         text-align: center;
                         margin-bottom: 50px;
                         display: flex;
                         flex-wrap: wrap;

                         .news_article_image {
                              width: 100%;
                              float: left;
                              text-align: center;

                              img {}
                         }

                         .news_article_wrap {
                              width: 100%;
                              float: left;
                              padding-left: 20px;
                              display: flex;
                              align-content: space-between;
                              flex-wrap: wrap;
                              justify-content: center;
                              .news_article_date {
                                   color: #202020;
                                   font-size: 10px;
                                   letter-spacing: -0.4px;
                                   line-height: 25px;
                                   margin-top: 20px;
                                   margin-bottom: 16px;
                                   font-weight: 700;
                              }

                              .news_article_title {
                                   color: $red;
                                   font-size: 20px;
                                   letter-spacing: -0.8px;
                                   line-height: 25px;
                                   font-weight: 700;
                                   padding: 0 0px;

                                   font-size: 16px;

                              }

                              .news_article_subtitle {
                                   color: #9ca0a6;
                                   font-size: 16px;
                                   letter-spacing: -0.64px;
                                   line-height: 25px;
                                   font-weight: 400;
                                   padding: 0 0px;
                                   margin-top: 15px;

                              }

                              .news_article_link {
                                   a {
                                        @include buttonAnimation();
                                        width: 186px;
                                        display: inline-block;
                                        margin-top: 30px;
                                        font-size: 12px;
                                   }
                              }
                         }
                    }
               }


          }
     }
}
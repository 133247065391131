@keyframes cloud {
	0% {
		right: -100px;
	}

	100% {
		right: 200%;
	}
}

.intro_mainPage{
	float: left;
	width: 100%;
	min-height: 100vh;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top left;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	align-items: center;
	overflow: hidden;
	padding: 100px 0;
	.container{
		position: relative;
		z-index: 10;
		.intro_mainPage_content{
			max-width: 500px;
			float: right;
			
			height: 100%;
			padding-top: 100px;
			.intro_mainPage_content_wrap_title{
				text-align: right;
				width: 100%;

				h1{
					p{
						font-size: 59px;
						font-style: italic;
						letter-spacing: -2.36px;
						line-height: 53px;
						text-transform: uppercase;
						font-weight: 900;

						strong{
							color: $red;
							width: 100%;
							display: block;
						}
					}
				}
				p{
					margin-top: 25px;
					font-size: 24px;
					letter-spacing: -0.96px;
					line-height: 36px;
				}
			}
			.intro_mainPage_content_wrap_popup{
				margin-top: 250px;
				width: 100%;

				a{
					display: inline-block;
					font-size: 24px;
					letter-spacing: -0.96px;
					line-height: 36px;
					font-weight: 900;
					font-style: italic;
					display: flex;
					align-items: center;
					color: $global;
					float: right;
					text-decoration: none;

					strong{
						color: $red;
						margin-left: 7px;
					}
					.popup_icon{
						margin-left: 20px;
						display: inline-block;
						width: 55px; height: 55px;
						background-color: #fff;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 100px;
						transition-duration: .2s;
						img{
							height: 10px;
						}
						&:hover{
							opacity: .6;
						}
					}
				}
			}
		}
	}

	.intro_mainPage_animation{
		position: absolute;
		top: 0;
		width: 100%;
		bottom: 0;
		pointer-events: none;
		opacity: .8;
		.animation_triangle{
			display: block;
			width: 0;
			height: 0;
			border: 50px solid transparent;
			border-right: 100px solid $red;
			filter: blur(2px);
			// right: -500px;
			position: absolute;
			top: 0%;
			right: -1000px;
			// animation-name: cloud;
			// animation-duration: 4s;
			// animation-timing-function: ease-out;
			// // animation-fill-mode: forwards;
			// animation-fill-mode: infinite;
			animation: cloud 30s infinite;

			animation-timing-function:cubic-bezier(0.1, 0.3, 0.8, 0.9);
			&.animation_triangle:nth-child(1) {
				animation-duration: 14s;
				transform: scale(1);
				top: 10%;
			 	animation-delay: 2s;

				
			}
			&.animation_triangle:nth-child(2) {
				animation-duration: 13s;
				transform: scale(1.2);
				top: 15%;
			 	animation-delay:1s;

			}
			&.animation_triangle:nth-child(3) {
				animation-duration: 19s;
				transform: scale(1.5);
				top: 20%;
			 	animation-delay: .6s;

			}
			&.animation_triangle:nth-child(4) {
				animation-duration: 9s;
				transform: scale(.4);
				top: 70%;
			 	animation-delay: 3s;

			}
			&.animation_triangle:nth-child(5) {
				animation-duration: 9s;
				transform: scale(2);
				top: 40%;
			 	animation-delay: 5s;

			}
			&.animation_triangle:nth-child(6) {
				animation-duration: 21s;
				transform: scale(1.7);
				border-right: 100px solid #fff;
				top: 30%;
			 	animation-delay: 4s;

			}
			&.animation_triangle:nth-child(7) {
				animation-duration: 13s;
				transform: scale(.9);
				top: 80%;
			 	animation-delay: 2s;

			}
			&.animation_triangle:nth-child(8) {
				animation-duration: 13s;
				transform: scale(.3);
				top: 50%;
			 	animation-delay: 1.5s;

			}
			&.animation_triangle:nth-child(9) {
				animation-duration: 12s;
				transform: scale(1.3);
				border-right: 100px solid #fff;

				top: 60%;
			 	animation-delay: 4.5s;

			}
			&.animation_triangle:nth-child(10) {
				animation-duration:9.5s;
				transform: scale(1.2);
				top: 90%;
			 	animation-delay: 1.3s;

			}
		}
	}
}

.intro_mainPage_popup{
	position: fixed;
	min-height: 100vh;
	overflow: auto;
	width: 100%;
	background-color: rgba(0,0,0,.8);
	align-items: center;
	justify-content: center;
	z-index: 999999999;
	display: none;
	.wrap{
		background-color: #fff;
		border-radius: 10px;
		padding: 10px;
		.closePopup{
			float: none;
			font-weight: 900;
			text-transform: uppercase;
			font-size: 12px;
			opacity: 1;
			margin-bottom: 25px;
			span{
				float: right;
				color: $red;
				cursor: pointer;

				&:hover{
					color: $redHover;
				}
			}
			width: 100%;
		}
	}
	&.intro_mainPage_popup--active{
		display: flex;
	}
}

@import 'rwd/tablet';
@import 'rwd/mobile';
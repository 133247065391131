@include media-breakpoint-down(md){
 .subPage_workOffer {
      float: none;
      width: 100%;
      max-width: 876px;
      margin: 0 auto;

      .workOffer_title {
           color: $global;
           font-size: 30px;
           font-weight: 600;
           letter-spacing: -1.2px;
           line-height: 36px;
           float: left;
           width: 100%;
           text-align: center;
           margin-bottom: 75px;

      }

      .workOffer_wrap {
           float: left;
           width: 100%;

           .workOffer_item {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 75px;
                align-items: center;

                .item_content {
                     width: 100%;
                     .item_content_title {
                          color: $red;
                          font-size: 20px;
                          font-weight: 700;
                     }

                     .item_content_date {
                          color: $global;
                          font-size: 16px;
                          font-weight: 400;
                          margin-top: 0px;
                          line-height: 40px;
                     }
                }

                .item_link {
                     margin-left: auto;
                     max-width: 100%;
                     width: 100%;
                     margin-top: 30px;

                     a {
                          @include buttonAnimation();
                          width: 100%;
                          background-color: #fff;
                          border: 1px solid $red;
                          color: $red;
                           max-width: 244px;

                          font-size: 12px;
                          font-weight: 900;
                     }
                }
           }
      }
 }

}
@include media-breakpoint-down(sm) {
     .intro_mainPage {
          justify-content: center;
          padding-top: 20px;
          padding-bottom: 0px;
          min-height: 420px;
          background-size: 100% auto;
          // background-position: right bottom -200px;
          background-position: top center;

          .container {
               .intro_mainPage_content {
                    padding-top: 20px;
                    float: none;
                    margin: 0 auto;


                    .intro_mainPage_content_wrap_title {
                         text-align: center;

                         h1 {
                              p{
                                   font-size: 28px;
                                   letter-spacing: -1.12px;
                                   line-height: 30px;
                                   span {}
                              }
                         }

                         p {
                             font-size: 16px;
                             letter-spacing: -0.64px;
                             line-height: 24px;
                         }
                    }

                    .intro_mainPage_content_wrap_popup {
                         margin-top: 80px;

                         a {
                              text-align: center;
                              font-size: 16px;
                              letter-spacing: -0.64px;
                              line-height: 36px;
                              flex-wrap: wrap;
                              width: 100%;

                              strong {}

                              .popup_text{
                                   display: block;
                                   position: relative;
                                   width: 100%;
                              }
                              .popup_icon {
                                   margin: 0 auto;
                                   img {}

                                   &:hover {}
                              }
                         }
                    }
               }
          }

          .intro_mainPage_animation {
               animation: none;
                .animation_triangle {

                    &.animation_triangle:nth-child(1) {
                         right: -60px;
                    }

                    &.animation_triangle:nth-child(2) {
                         display: none;
                    }

                    &.animation_triangle:nth-child(3) {
                         transform: scale(.6);
                         top: 50%;
                         left: -100px;
                    }

                    &.animation_triangle:nth-child(4) {
                         display: none;
                    }

                    &.animation_triangle:nth-child(5) {}

                    &.animation_triangle:nth-child(6) {
                         transform: scale(1);
                         top: 60%;
                         right: -30px;
                         opacity: .6;
                    }

                    &.animation_triangle:nth-child(7) {}

                    &.animation_triangle:nth-child(8) {}

                    &.animation_triangle:nth-child(9) {}

                    &.animation_triangle:nth-child(10) {}
               }
          }
     }
     
     // .intro_mainPage .container .intro_mainPage_content{
     //      margin: 0;
     //      margin-left: auto;
     // }
     .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title h1{
          margin-top: 18vw;
          margin-bottom: 15vw;
          text-align: right;
     }
     // .intro_mainPage .container .intro_mainPage_content{
     //      max-width: 100%;
     //      width: 100%;
     // }
     .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup{
          margin-top: 20px;
     }
     // .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup{
     //      display: flex;
     //      justify-content: center;
     // }
     .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title h1 p{
          font-size: 20px;
          line-height: 22px;
     }
     
}

@media (max-width: 767px){
     .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title h1{
          margin-top: 34vw;
     }
}
@media (max-width:400px){
     .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title h1{
          margin-top: 23vw;
     }
}
@media (max-width:360px){
     .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title h1{
          margin-top: 0vw;
     }
}
@include media-breakpoint-down(md){
 .subPage_textBox_imageRight_inGrid-6-6 {
      float: left;
      width: 100%;
      padding-top: 0px;
      padding-bottom: 70px;

      .container {
           position: relative;

           .subPage_textBox_imageRight_inGrid-6-6_content {
                .subPage_textBox_imageRight_inGrid-6-6_content_wrap {


                     .subPage_textBox_imageRight_inGrid-6-6_content_image {
                          float: left;

                          .wrap {
                              img{
                                   max-width: 100%;
                              }
                          }

                           &::after {
                                display: none;

                           }

                           &::before {
                                display: none;

                           }

                     }

                     .subPage_textBox_imageRight_inGrid-6-6_content_text {
                          float: left;
                          width: 100%;

                         padding: 0;
                          color: $global;
                          font-size: 16px;
                          font-weight: 400;

                          min-height: 100%;
                          display: flex;
                          flex-wrap: wrap;
                          align-content: flex-start;

                          strong {

                             
                          }

                          p {
                             
                          }

                          ul,
                          ol {
                               list-style: none;
                               padding: 0;
                               margin: 0;
                               float: left;
                               width: 100%;

                               li {
                                    line-height: 50px;
                                    padding-left: 20px;
                                    position: relative;
                                    float: left;
                                    width: 100%;

                                    &::before {
                                         width: 7px;
                                         display: inline-block;
                                         height: 10px;
                                         background-image: url(../img/icons/arrowRight-red.svg);
                                         content: '';
                                         position: absolute;
                                         left: 0;
                                         top: 20px;
                                    }
                               }
                          }

                          .item_link {
                               @include buttonAnimation();
                               width: 244px;
                               margin-top: 36px;

                          }
                     }
                }
           }
      }
 }

}
@mixin buttonAnimation() {
	position: relative;
	background-color: $red;
	display: block;
	border-radius: 0px;
	width: 100%;
	overflow: hidden;

      color: #fff;
	transition-duration: .5s;
	text-decoration: none;

	font-size: 12px;
	letter-spacing: -0.48px;
	line-height: 1em;
	text-transform: uppercase;
	font-weight: 800;

	padding: 20px 30px;
	padding-right: 20px;
	


	&::after{
		transition-duration: .3s;
		position: absolute;
		display: block;
		height: 1000%;
		left: -100px; right: -100px;
		top: 140%; bottom: 0;
		border-radius: 1000px;
		background-color: $redHover;
		content: '';
	}

	&::before{
		transition-duration: .3s;
		position: absolute;
		display: block;
		height: 1000%;
		left: -100px; right: -100px;
		top: 100%; bottom: 0;
		border-radius: 1000px;
		background-color: $redHover;
		opacity: .4;
		content: '';
	}
	&:hover{
		color: #fff;;

		&::after{
			top: -100%;
		}
		&::before{
			top: -120%;
		}
		span{
			img{
				right: -2px;
				opacity: .8;
			}
		}
	}
      span{
            position: relative;
		  z-index: 1;
		  display: flex;
		  align-items: center;
		  img {
		  	height: 10px;
		  	opacity: .5;
			  margin-left: auto;
			  position: relative;
			  right: 0;
			  transition-duration: .2s;
		  }
      }
}
// @include buttonAnimation(red);

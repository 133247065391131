@include media-breakpoint-down(sm) {
     .subPage_blankPage {
          float: left;
          width: 100%;
          padding-bottom: 100px;
          padding-top: 45px;

     .container {
          .blankPage_content {
               margin: 0 auto;
               text-align: center;

               *:last-child {
                    margin-bottom: 0px;
               }

               p {
                    margin-bottom: 40px;
               }

               ul,
               ol {
                    text-align: left;

                    list-style: none;
                    padding: 0;
                    margin: 0;
                    float: left;
                    width: 100%;
                    margin-bottom: 40px;

                    li {
                         padding-left: 20px;
                         position: relative;
                         float: left;
                         width: 100%;

                         &::before {
                              width: 7px;
                              display: inline-block;
                              height: 10px;
                              background-image: url(../img/icons/arrowRight-red.svg);
                              content: '';
                              position: absolute;
                              left: 0;
                         }

                         &:last-child {
                              margin-left: 0px;
                         }

                         ul,
                         ol {
                              li {
                                   &::before {
                                        display: none;
                                   }
                              }
                         }
                    }
               }

               img {
                    max-width: 100%;
                    margin-bottom: 40px;
                    height: auto;
                    margin-top: 10px;
               }
          }
     }
}

}
/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary.nice-select.open:not(:disabled):not(.disabled), .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary.nice-select.open:not(:disabled):not(.disabled):focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary.nice-select.open:not(:disabled):not(.disabled), .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary.nice-select.open:not(:disabled):not(.disabled):focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success.nice-select.open:not(:disabled):not(.disabled), .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success.nice-select.open:not(:disabled):not(.disabled):focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info.nice-select.open:not(:disabled):not(.disabled), .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info.nice-select.open:not(:disabled):not(.disabled):focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning.nice-select.open:not(:disabled):not(.disabled), .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning.nice-select.open:not(:disabled):not(.disabled):focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger.nice-select.open:not(:disabled):not(.disabled), .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger.nice-select.open:not(:disabled):not(.disabled):focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light.nice-select.open:not(:disabled):not(.disabled), .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light.nice-select.open:not(:disabled):not(.disabled):focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark.nice-select.open:not(:disabled):not(.disabled), .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark.nice-select.open:not(:disabled):not(.disabled):focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary.nice-select.open:not(:disabled):not(.disabled), .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary.nice-select.open:not(:disabled):not(.disabled):focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary.nice-select.open:not(:disabled):not(.disabled), .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary.nice-select.open:not(:disabled):not(.disabled):focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success.nice-select.open:not(:disabled):not(.disabled), .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success.nice-select.open:not(:disabled):not(.disabled):focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info.nice-select.open:not(:disabled):not(.disabled), .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info.nice-select.open:not(:disabled):not(.disabled):focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning.nice-select.open:not(:disabled):not(.disabled), .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning.nice-select.open:not(:disabled):not(.disabled):focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger.nice-select.open:not(:disabled):not(.disabled), .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger.nice-select.open:not(:disabled):not(.disabled):focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light.nice-select.open:not(:disabled):not(.disabled), .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light.nice-select.open:not(:disabled):not(.disabled):focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark.nice-select.open:not(:disabled):not(.disabled), .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark.nice-select.open:not(:disabled):not(.disabled):focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active, .dropdown-item.nice-select.open {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.nice-select.open, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.nice-select.open,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before, .custom-control-input.nice-select.open ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #dee2e6;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #80bdff;
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active, .custom-range.nice-select.open::-webkit-slider-thumb {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active, .custom-range.nice-select.open::-moz-range-thumb {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active, .custom-range.nice-select.open::-ms-thumb {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active, .list-group-item-action.nice-select.open {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: transform 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable.nice-select.open, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 0.25rem;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: calc(2.25rem + 2px);
  line-height: calc(2.25rem + 2px)-2;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto;
}

.nice-select:hover {
  border-color: #dbdbdb;
}

.nice-select:active, .nice-select.open, .nice-select:focus {
  border-color: #999;
}

.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: '';
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  width: 5px;
}

.nice-select.open:after {
  transform: rotate(-135deg);
}

.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1) translateY(0);
}

.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}

.nice-select.disabled:after {
  border-color: #cccccc;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .list {
  left: auto;
  right: 0;
}

.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}

.nice-select.small:after {
  height: 4px;
  width: 4px;
}

.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}

.nice-select .list {
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform-origin: 50% 0;
  transform: scale(0.75) translateY(-calc(2.25rem + 2px)/2);
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: calc(2.25rem + 2px)-2;
  list-style: none;
  min-height: calc(2.25rem + 2px)-2;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  transition: all 0.2s;
}

.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
  background-color: #f6f6f6;
}

.nice-select .option.selected {
  font-weight: bold;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}

body, html {
  font-family: 'Nunito Sans',sans-serif;
  width: 100%;
  max-width: 100%;
  min-width: 320px;
  overflow-x: hidden;
  color: #333333;
}

.siteWrapper {
  background-color: #fff;
  float: left;
  width: 100%;
}

.no-padding {
  padding: 0;
}

.mainPage {
  background-color: #f1f1f1;
  float: left;
  width: 100%;
  position: relative;
}

.mainPage::before {
  content: '';
  width: 100%;
  height: 100px;
  position: absolute;
  z-index: 0;
  top: 42%;
  left: 0;
  margin-top: -50px;
  border: 750px solid transparent;
  border-left: 2000px solid #fff;
}

.mainPage .mainPage_wrap {
  z-index: 1;
  position: relative;
}

@media (min-width: 1200px) {
  .container {
    width: 100%;
    max-width: 1420px;
    padding: 0 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 100%;
    max-width: 991px;
    padding: 0 40px;
  }
}

@media (max-width: 767px) {
  .container {
    padding: 0 20px;
  }
  .mainPage {
    background-color: #fff;
  }
  .mainPage::before {
    display: none;
  }
}

.site_header {
  float: left;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10000;
}

.site_header.site_header--scroll .site_header_container {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
}

.site_header .site_header_container {
  padding: 40px 50px;
  float: left;
  width: 100%;
  display: flex;
  transition-duration: .3s;
  align-items: center;
}

.site_header .site_header_container .site_header_left img {
  width: 194px;
}

.site_header .site_header_container .site_header_right {
  margin-left: auto;
}

.site_header .site_header_container .site_header_right .site_header_right_menu {
  z-index: 1;
  position: relative;
  font-size: 18px;
}

.site_header .site_header_container .site_header_right .site_header_right_menu .menu_button {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  transition-duration: .4s;
  cursor: pointer;
}

.site_header .site_header_container .site_header_right .site_header_right_menu .menu_button .menu_button_icon {
  display: flex;
  margin-left: 10px;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background-color: #000;
  padding: 0 13px;
  border-radius: 100px;
  transition-duration: .4s;
}

.site_header .site_header_container .site_header_right .site_header_right_menu .menu_button .menu_button_icon .menu_button_icon_line {
  display: block;
  margin: 2px 0;
  transition-duration: .2s;
  width: 100%;
  position: relative;
  height: 2px;
  background-color: #fff;
  left: 0;
}

.site_header .site_header_container .site_header_right .site_header_right_menu .menu_button:hover .menu_button_icon .menu_button_icon_line:nth-child(1) {
  left: -3px;
}

.site_header .site_header_container .site_header_right .site_header_right_menu .menu_button:hover .menu_button_icon .menu_button_icon_line:nth-child(3) {
  left: 3px;
}

.site_header .site_header_container .site_header_right .site_header_right_menu .menu_button.menu_button--open {
  color: #fff;
}

.site_header .site_header_container .site_header_right .site_header_right_menu .menu_button.menu_button--open .menu_button_icon {
  background-color: #fff;
  padding-top: 1px;
  padding-left: 12px;
}

.site_header .site_header_container .site_header_right .site_header_right_menu .menu_button.menu_button--open .menu_button_icon .menu_button_icon_line:nth-child(1) {
  left: 0px;
  transform: rotate(-45deg);
  margin: -2px 0;
  background-color: #000000;
}

.site_header .site_header_container .site_header_right .site_header_right_menu .menu_button.menu_button--open .menu_button_icon .menu_button_icon_line:nth-child(2) {
  left: 0px;
  margin: -2px 0;
  width: 0px;
  background-color: #000000;
}

.site_header .site_header_container .site_header_right .site_header_right_menu .menu_button.menu_button--open .menu_button_icon .menu_button_icon_line:nth-child(3) {
  left: 0px;
  margin: -2px 0;
  transform: rotate(45deg);
  background-color: #000000;
}

.site_header .site_header_container .site_header_right .site_header_right_nav {
  position: absolute;
  opacity: 0;
  right: -100%;
  background-color: #333333;
  min-height: 100vh;
  overflow: auto;
  width: 100%;
  max-width: 478px;
  height: 100vh;
  top: 0;
  transition-duration: .5s;
}

.site_header .site_header_container .site_header_right .site_header_right_nav.site_header_right_nav--open {
  opacity: 1;
  right: 0;
}

.site_header .site_header_container .site_header_right .site_header_right_nav .siteMenu {
  padding: 140px 50px 0 50px;
  list-style: none;
}

.site_header .site_header_container .site_header_right .site_header_right_nav .siteMenu li a {
  display: block;
  text-align: right;
  width: 100%;
  color: #dee2e8;
  text-decoration: none;
  padding: 16px 0;
  transition-duration: .2s;
}

.site_header .site_header_container .site_header_right .site_header_right_nav .siteMenu li a:hover {
  opacity: .6;
}

.site_header .site_header_container .site_header_right .site_header_right_nav .languages {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
}

.site_header .site_header_container .site_header_right .site_header_right_nav .languages li {
  margin-left: 10px;
}

.site_header .site_header_container .site_header_right .site_header_right_nav .languages li img {
  height: 15px;
}

@media (max-width: 991.98px) {
  .site_header .site_header_container {
    padding: 20px 30px;
  }
  .site_header .site_header_container .site_header_left img {
    width: 156px;
  }
  .site_header .site_header_container .site_header_right {
    margin-left: auto;
  }
  .site_header .site_header_container .site_header_right .site_header_right_menu {
    z-index: 1;
    position: relative;
  }
}

@media (max-width: 767.98px) {
  .site_header .site_header_container {
    padding: 10px 20px;
    align-items: flex-start;
  }
  .site_header .site_header_container .site_header_left img {
    width: 75px;
  }
  .site_header .site_header_container .site_header_right .site_header_right_menu .menu_button .menu_button_text {
    display: none;
  }
  .site_header .site_header_container .site_header_right .site_header_right_nav.site_header_right_nav--open {
    opacity: 1;
    right: 0;
  }
  .site_header .site_header_container .site_header_right .site_header_right_nav .siteMenu {
    padding: 80px 20px;
    list-style: none;
  }
  .site_header .site_header_container .site_header_right .site_header_right_nav .siteMenu li a {
    display: block;
    text-align: right;
    width: 100%;
    color: #dee2e8;
    text-decoration: none;
    padding: 16px 0;
    transition-duration: .2s;
  }
  .site_header .site_header_container .site_header_right .site_header_right_nav .siteMenu li a:hover {
    opacity: .6;
  }
}

.site_footer {
  float: left;
  width: 100%;
  border-top: 30px solid #f3f3f3;
  position: relative;
}

.site_footer .container .footer_wrap {
  padding-top: 60px;
  padding-bottom: 70px;
  float: left;
  width: 100%;
}

.site_footer .container .footer_wrap .footer_item .footer_item_header {
  color: #ab0000;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 18px;
}

.site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list {
  list-style: none;
  padding: 0;
  float: left;
  width: 100%;
}

.site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list li a {
  color: #9ca0a6;
  font-size: 16px;
  font-weight: 400;
  line-height: 38px;
  transition-duration: .2s;
  text-decoration: none;
}

.site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list li a:hover {
  color: #C6CDD8;
}

.site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list li.facebook {
  margin-top: 68px;
}

.site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list li.facebook a {
  color: #9ca0a6;
  font-size: 16px;
  font-weight: 900;
  line-height: 38px;
}

.site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list li.facebook a:hover {
  color: #C6CDD8;
}

.site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list.footer_item_content_list--red {
  margin-top: 10px;
}

.site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list.footer_item_content_list--red li {
  margin-bottom: 17px;
}

.site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list.footer_item_content_list--red li a {
  color: #ab0000;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
}

.site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list.footer_item_content_list--red li a:hover {
  color: #820000;
}

.site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list.footer_item_content_list--red li a img {
  width: 26px;
  margin-right: 15px;
}

.site_footer .container .footer_wrap .footer_item .footer_item_content p {
  color: #9ca0a6;
  font-size: 16px;
  font-weight: 400;
  line-height: 38px;
}

.site_footer .container .footer_bottom {
  display: flex;
  padding: 30px 0;
  border-top: 1px solid #e9eef5;
  float: left;
  width: 100%;
}

.site_footer .container .footer_bottom .left {
  color: #9ca0a6;
  font-size: 14px;
  font-weight: 400;
  line-height: 38px;
}

.site_footer .container .footer_bottom .right {
  margin-left: auto;
  color: #9ca0a6;
  font-size: 14px;
  font-weight: 400;
  line-height: 38px;
}

.site_footer .container .footer_bottom .right img {
  width: 124px;
}

.site_footer .goUp {
  position: absolute;
  right: 30px;
  bottom: 70px;
}

.site_footer .goUp .goUp_wrap {
  display: block;
  width: 54px;
  height: 54px;
  background-color: #9ca0a6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  cursor: pointer;
}

.site_footer .goUp .goUp_wrap img {
  width: 23px;
}

@media (max-width: 991.98px) {
  .site_footer {
    float: left;
    width: 100%;
    border-top: 30px solid #f3f3f3;
  }
  .site_footer .container .footer_wrap {
    padding-top: 60px;
    padding-bottom: 70px;
    float: left;
    width: 100%;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_header {
    color: #ab0000;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 18px;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list {
    list-style: none;
    padding: 0;
    float: left;
    width: 100%;
    margin-bottom: 40px;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list li a {
    color: #9ca0a6;
    font-size: 16px;
    font-weight: 400;
    line-height: 38px;
    transition-duration: .2s;
    text-decoration: none;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list li a:hover {
    color: #C6CDD8;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list li.facebook {
    margin-top: 50px;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list li.facebook a {
    color: #9ca0a6;
    font-size: 16px;
    font-weight: 900;
    line-height: 38px;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list li.facebook a:hover {
    color: #C6CDD8;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list.footer_item_content_list--red {
    margin-top: -10px;
    width: 40%;
    float: left;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list.footer_item_content_list--red li {
    margin-bottom: 17px;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list.footer_item_content_list--red li a {
    color: #ab0000;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list.footer_item_content_list--red li a:hover {
    color: #820000;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list.footer_item_content_list--red li a img {
    width: 26px;
    margin-right: 15px;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content p {
    width: 60%;
    padding-right: 30px;
    float: left;
    color: #9ca0a6;
    font-size: 16px;
    font-weight: 400;
    line-height: 38px;
  }
  .site_footer .container .footer_bottom {
    display: flex;
    padding: 30px 0;
    border-top: 1px solid #e9eef5;
    float: left;
    width: 100%;
  }
  .site_footer .container .footer_bottom .left {
    color: #9ca0a6;
    font-size: 14px;
    font-weight: 400;
    line-height: 38px;
  }
  .site_footer .container .footer_bottom .right {
    margin-left: auto;
    color: #9ca0a6;
    font-size: 14px;
    font-weight: 400;
    line-height: 38px;
  }
  .site_footer .container .footer_bottom .right img {
    width: 124px;
  }
  .goUp {
    position: absolute;
    right: 30px;
    bottom: 130px;
  }
  .goUp .goUp_wrap {
    display: block;
    width: 54px;
    height: 54px;
    background-color: #9ca0a6;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
  }
  .goUp .goUp_wrap img {
    width: 23px;
  }
}

@media (max-width: 767.98px) {
  .site_footer {
    float: left;
    width: 100%;
    border-top: 30px solid #f3f3f3;
  }
  .site_footer .container .footer_wrap {
    padding-top: 40px;
    padding-bottom: 130px;
    float: left;
    width: 100%;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_header {
    color: #ab0000;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 18px;
    text-align: center;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list {
    list-style: none;
    padding: 0;
    float: left;
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list li a {
    color: #9ca0a6;
    font-size: 16px;
    font-weight: 400;
    line-height: 38px;
    transition-duration: .2s;
    text-decoration: none;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list li a:hover {
    color: #C6CDD8;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list li.facebook {
    margin-top: 50px;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list li.facebook a {
    color: #9ca0a6;
    font-size: 16px;
    font-weight: 900;
    line-height: 38px;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list li.facebook a:hover {
    color: #C6CDD8;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list.footer_item_content_list--red {
    margin-top: 0px;
    width: 100%;
    float: left;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list.footer_item_content_list--red li {
    margin-bottom: 17px;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list.footer_item_content_list--red li a {
    color: #ab0000;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list.footer_item_content_list--red li a:hover {
    color: #820000;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content .footer_item_content_list.footer_item_content_list--red li a img {
    width: 26px;
    margin-right: 15px;
  }
  .site_footer .container .footer_wrap .footer_item .footer_item_content p {
    width: 100%;
    float: left;
    color: #9ca0a6;
    font-size: 16px;
    font-weight: 400;
    line-height: 38px;
    text-align: center;
  }
  .site_footer .container .footer_bottom {
    display: flex;
    padding: 30px 0;
    border-top: 1px solid #e9eef5;
    float: left;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .site_footer .container .footer_bottom .left {
    color: #9ca0a6;
    font-size: 14px;
    font-weight: 400;
    line-height: 38px;
    float: left;
    width: 100%;
    text-align: center;
  }
  .site_footer .container .footer_bottom .right {
    margin-left: auto;
    color: #9ca0a6;
    font-size: 14px;
    font-weight: 400;
    line-height: 38px;
    float: left;
    width: 100%;
    text-align: center;
  }
  .site_footer .container .footer_bottom .right img {
    width: 124px;
  }
  .site_footer .goUp {
    position: absolute;
    right: 0px;
    bottom: 180px;
    left: 0px;
    width: 100%;
  }
  .site_footer .goUp .goUp_wrap {
    display: block;
    margin: 0 auto;
    width: 54px;
    height: 54px;
    background-color: #9ca0a6;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
  }
  .site_footer .goUp .goUp_wrap img {
    width: 23px;
  }
}

@keyframes cloud {
  0% {
    right: -100px;
  }
  100% {
    right: 200%;
  }
}

.intro_mainPage {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top left;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  overflow: hidden;
  padding: 100px 0;
}

.intro_mainPage .container {
  position: relative;
  z-index: 10;
}

.intro_mainPage .container .intro_mainPage_content {
  max-width: 500px;
  float: right;
  height: 100%;
  padding-top: 100px;
}

.intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title {
  text-align: right;
  width: 100%;
}

.intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title h1 p {
  font-size: 59px;
  font-style: italic;
  letter-spacing: -2.36px;
  line-height: 53px;
  text-transform: uppercase;
  font-weight: 900;
}

.intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title h1 p strong {
  color: #ab0000;
  width: 100%;
  display: block;
}

.intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title p {
  margin-top: 25px;
  font-size: 24px;
  letter-spacing: -0.96px;
  line-height: 36px;
}

.intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup {
  margin-top: 250px;
  width: 100%;
}

.intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup a {
  display: inline-block;
  font-size: 24px;
  letter-spacing: -0.96px;
  line-height: 36px;
  font-weight: 900;
  font-style: italic;
  display: flex;
  align-items: center;
  color: #333333;
  float: right;
  text-decoration: none;
}

.intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup a strong {
  color: #ab0000;
  margin-left: 7px;
}

.intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup a .popup_icon {
  margin-left: 20px;
  display: inline-block;
  width: 55px;
  height: 55px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  transition-duration: .2s;
}

.intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup a .popup_icon img {
  height: 10px;
}

.intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup a .popup_icon:hover {
  opacity: .6;
}

.intro_mainPage .intro_mainPage_animation {
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  pointer-events: none;
  opacity: .8;
}

.intro_mainPage .intro_mainPage_animation .animation_triangle {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #ab0000;
  filter: blur(2px);
  position: absolute;
  top: 0%;
  right: -1000px;
  animation: cloud 30s infinite;
  animation-timing-function: cubic-bezier(0.1, 0.3, 0.8, 0.9);
}

.intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(1) {
  animation-duration: 14s;
  transform: scale(1);
  top: 10%;
  animation-delay: 2s;
}

.intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(2) {
  animation-duration: 13s;
  transform: scale(1.2);
  top: 15%;
  animation-delay: 1s;
}

.intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(3) {
  animation-duration: 19s;
  transform: scale(1.5);
  top: 20%;
  animation-delay: .6s;
}

.intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(4) {
  animation-duration: 9s;
  transform: scale(0.4);
  top: 70%;
  animation-delay: 3s;
}

.intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(5) {
  animation-duration: 9s;
  transform: scale(2);
  top: 40%;
  animation-delay: 5s;
}

.intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(6) {
  animation-duration: 21s;
  transform: scale(1.7);
  border-right: 100px solid #fff;
  top: 30%;
  animation-delay: 4s;
}

.intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(7) {
  animation-duration: 13s;
  transform: scale(0.9);
  top: 80%;
  animation-delay: 2s;
}

.intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(8) {
  animation-duration: 13s;
  transform: scale(0.3);
  top: 50%;
  animation-delay: 1.5s;
}

.intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(9) {
  animation-duration: 12s;
  transform: scale(1.3);
  border-right: 100px solid #fff;
  top: 60%;
  animation-delay: 4.5s;
}

.intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(10) {
  animation-duration: 9.5s;
  transform: scale(1.2);
  top: 90%;
  animation-delay: 1.3s;
}

.intro_mainPage_popup {
  position: fixed;
  min-height: 100vh;
  overflow: auto;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
  z-index: 999999999;
  display: none;
}

.intro_mainPage_popup .wrap {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}

.intro_mainPage_popup .wrap .closePopup {
  float: none;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 12px;
  opacity: 1;
  margin-bottom: 25px;
  width: 100%;
}

.intro_mainPage_popup .wrap .closePopup span {
  float: right;
  color: #ab0000;
  cursor: pointer;
}

.intro_mainPage_popup .wrap .closePopup span:hover {
  color: #820000;
}

.intro_mainPage_popup.intro_mainPage_popup--active {
  display: flex;
}

@media (max-width: 991.98px) {
  .intro_mainPage {
    justify-content: center;
    background-size: auto;
    min-height: 680px;
    justify-content: center;
    padding-top: 20px;
    min-height: 420px;
    background-size: 100% auto;
    background-position: top center;
  }
  .intro_mainPage .container .intro_mainPage_content {
    padding-top: 20px;
    float: none;
    margin: 0 auto;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title {
    text-align: center;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title h1 p {
    font-size: 50px;
    letter-spacing: -2px;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title p {
    font-size: 22px;
    font-weight: 300;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup {
    margin-top: 140px;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup a {
    text-align: center;
  }
  .intro_mainPage .intro_mainPage_animation .animation_triangle {
    animation: none;
  }
  .intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(1) {
    right: -60px;
  }
  .intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(2) {
    left: 0;
    transform: scale(0.4);
  }
  .intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(3) {
    transform: scale(0.6);
    top: 50%;
    left: -50px;
  }
  .intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(4) {
    top: auto;
    bottom: 0;
    left: -5%;
  }
  .intro_mainPage .container .intro_mainPage_content {
    margin: 0;
    margin-left: auto;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title h1 {
    margin-top: 30vw;
    margin-bottom: 10vw;
    text-align: right;
  }
  .intro_mainPage .container .intro_mainPage_content {
    max-width: 100%;
    width: 100%;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup {
    margin-top: 50px;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 767.98px) {
  .intro_mainPage {
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 0px;
    min-height: 420px;
    background-size: 100% auto;
    background-position: top center;
  }
  .intro_mainPage .container .intro_mainPage_content {
    padding-top: 20px;
    float: none;
    margin: 0 auto;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title {
    text-align: center;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title h1 p {
    font-size: 28px;
    letter-spacing: -1.12px;
    line-height: 30px;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title p {
    font-size: 16px;
    letter-spacing: -0.64px;
    line-height: 24px;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup {
    margin-top: 80px;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup a {
    text-align: center;
    font-size: 16px;
    letter-spacing: -0.64px;
    line-height: 36px;
    flex-wrap: wrap;
    width: 100%;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup a .popup_text {
    display: block;
    position: relative;
    width: 100%;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup a .popup_icon {
    margin: 0 auto;
  }
  .intro_mainPage .intro_mainPage_animation {
    animation: none;
  }
  .intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(1) {
    right: -60px;
  }
  .intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(2) {
    display: none;
  }
  .intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(3) {
    transform: scale(0.6);
    top: 50%;
    left: -100px;
  }
  .intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(4) {
    display: none;
  }
  .intro_mainPage .intro_mainPage_animation .animation_triangle.animation_triangle:nth-child(6) {
    transform: scale(1);
    top: 60%;
    right: -30px;
    opacity: .6;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title h1 {
    margin-top: 18vw;
    margin-bottom: 15vw;
    text-align: right;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_popup {
    margin-top: 20px;
  }
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title h1 p {
    font-size: 20px;
    line-height: 22px;
  }
}

@media (max-width: 767px) {
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title h1 {
    margin-top: 34vw;
  }
}

@media (max-width: 400px) {
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title h1 {
    margin-top: 23vw;
  }
}

@media (max-width: 360px) {
  .intro_mainPage .container .intro_mainPage_content .intro_mainPage_content_wrap_title h1 {
    margin-top: 0vw;
  }
}

.offer_mainPage {
  float: left;
  width: 100%;
}

.offer_mainPage .container {
  position: relative;
}

.offer_mainPage .container .offer_mainPage_header {
  font-size: 30px;
  letter-spacing: -1.2px;
  line-height: 36px;
  float: left;
  width: 100%;
  font-weight: 600;
  margin-bottom: 60px;
  margin-top: 90px;
}

.offer_mainPage .container .offer_mainPage_points {
  float: left;
  width: 100%;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper {
  display: flex;
  width: calc(100% + 44px);
  margin-left: -22px;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item {
  width: 33.333%;
  padding-bottom: calc(33.333% - 44px);
  position: relative;
  background-color: #ab0000;
  margin: 0 22px;
  margin-bottom: 44px;
  background-size: cover;
  display: block;
  box-shadow: 0 0 0px transparent;
  transition-duration: .3s;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item:hover {
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item .item_wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 30px;
  display: block;
  text-decoration: none;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item .item_icon {
  margin-bottom: 30px;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item .item_icon img {
  width: 51px;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item .item_title {
  color: #ffffff;
  font-size: 16px;
  letter-spacing: -0.64px;
  line-height: 24px;
  text-transform: uppercase;
  display: block;
  width: 100%;
  font-weight: 800;
  margin-bottom: 20px;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item .item_description {
  color: #f09696;
  font-size: 16px;
  letter-spacing: -0.64px;
  line-height: 24px;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--darkBackground {
  background-color: #820000;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--blankItem {
  background-color: #fff;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--blankItem:hover {
  box-shadow: none;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--info .item_wrap {
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--info .item_wrap .item_title {
  font-size: 21px;
  letter-spacing: -0.84px;
  line-height: 33px;
  color: #ffffff;
  text-transform: none;
  font-weight: 400;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--info .item_wrap .item_title strong {
  color: #ab0000;
  font-weight: 600;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--info .item_wrap .item_link {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--info .item_wrap .item_link::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--info .item_wrap .item_link::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--info .item_wrap .item_link:hover {
  color: #fff;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--info .item_wrap .item_link:hover::after {
  top: -100%;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--info .item_wrap .item_link:hover::before {
  top: -120%;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--info .item_wrap .item_link:hover span img {
  right: -2px;
  opacity: .8;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--info .item_wrap .item_link span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--info .item_wrap .item_link span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

.offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--z1 {
  position: relative;
  z-index: 1;
}

.offer_mainPage .container .offer_mainPage_rightImage {
  float: left;
  width: auto;
}

.offer_mainPage .container .offer_mainPage_rightImage img {
  position: absolute;
  top: -50px;
  right: 40px;
  width: 40%;
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
}

@media (max-width: 1320px) {
  .offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item .item_wrap {
    position: relative;
  }
  .offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item {
    padding-bottom: 0px;
  }
}

@media (max-width: 991.98px) {
  .offer_mainPage .container .offer_mainPage_header {
    margin-top: 80px;
    font-size: 28px;
  }
  .offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item {
    width: 100%;
  }
  .offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--blankItem {
    width: 30%;
  }
  .offer_mainPage .container .offer_mainPage_rightImage {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .offer_mainPage {
    padding-top: 20px;
    padding-bottom: 30px;
    float: left;
    width: 100%;
  }
  .offer_mainPage .container .offer_mainPage_header {
    display: none;
  }
  .offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper {
    flex-wrap: wrap;
  }
  .offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item {
    margin-bottom: 20px;
    width: 100%;
    min-height: 280px;
  }
  .offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item .item_wrap {
    padding: 20px;
  }
  .offer_mainPage .container .offer_mainPage_points .offer_mainPage_points_wraper .offer_mainPage_points_wraper_item.offer_mainPage_points_wraper_item--blankItem {
    display: none;
  }
  .offer_mainPage .container .offer_mainPage_rightImage {
    display: none;
  }
}

.carrierZone_mainPage {
  float: left;
  width: 100%;
  padding-top: 260px;
  padding-bottom: 90px;
}

.carrierZone_mainPage .container .carrierZone_wrap {
  position: relative;
  float: left;
  width: 100%;
}

.carrierZone_mainPage .container .carrierZone_mainPage_bacgkround {
  display: block;
  width: 646px;
  position: absolute;
  top: -40px;
  bottom: -40px;
  left: 0;
  background-color: #000;
  background-size: cover;
}

.carrierZone_mainPage .container .carrierZone_mainPage_box {
  display: flex;
  background-color: #202020;
  padding: 70px 70px;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  float: right;
  width: 761px;
}

.carrierZone_mainPage .container .carrierZone_mainPage_box .box_title {
  color: #ffffff;
  font-size: 30px;
  line-height: 32px;
  margin: 0;
  padding: 0;
}

.carrierZone_mainPage .container .carrierZone_mainPage_box a {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
  margin-left: auto;
  max-width: 244px;
}

.carrierZone_mainPage .container .carrierZone_mainPage_box a::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.carrierZone_mainPage .container .carrierZone_mainPage_box a::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.carrierZone_mainPage .container .carrierZone_mainPage_box a:hover {
  color: #fff;
}

.carrierZone_mainPage .container .carrierZone_mainPage_box a:hover::after {
  top: -100%;
}

.carrierZone_mainPage .container .carrierZone_mainPage_box a:hover::before {
  top: -120%;
}

.carrierZone_mainPage .container .carrierZone_mainPage_box a:hover span img {
  right: -2px;
  opacity: .8;
}

.carrierZone_mainPage .container .carrierZone_mainPage_box a span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.carrierZone_mainPage .container .carrierZone_mainPage_box a span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

@media (max-width: 1300px) {
  .carrierZone_mainPage {
    padding-top: 170px;
  }
}

@media (max-width: 991.98px) {
  .carrierZone_mainPage {
    float: left;
    width: 100%;
    padding-top: 200px;
    padding-bottom: 40px;
  }
  .carrierZone_mainPage .container .carrierZone_wrap {
    position: relative;
    float: left;
    width: 100%;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_bacgkround {
    position: absolute;
    top: -100%;
    bottom: 50%;
    left: 0;
    width: 100%;
    right: 0;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box {
    display: flex;
    background-color: #202020;
    padding: 60px 45px;
    justify-content: center;
    position: relative;
    z-index: 1;
    align-items: center;
    width: 612px;
    float: none;
    margin: 0 auto;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box .box_title {
    color: #ffffff;
    font-size: 26px;
    line-height: 32px;
    margin: 0;
    padding: 0;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    margin-left: auto;
    max-width: 244px;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a:hover {
    color: #fff;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a:hover::after {
    top: -100%;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a:hover::before {
    top: -120%;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a:hover span img {
    right: -2px;
    opacity: .8;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

@media (max-width: 767.98px) {
  .carrierZone_mainPage {
    float: left;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 40px;
  }
  .carrierZone_mainPage .container .carrierZone_wrap {
    position: relative;
    float: left;
    width: 100%;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_bacgkround {
    position: absolute;
    top: -80%;
    bottom: 50%;
    left: 0;
    width: 100%;
    height: 120px;
    right: 0;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box {
    display: flex;
    background-color: #202020;
    margin: 0 10px;
    padding: 15px;
    justify-content: center;
    position: relative;
    z-index: 1;
    align-items: center;
    width: calc(100% - 20px);
    flex-wrap: wrap;
    float: none;
    margin-left: 10px;
    text-align: center;
    justify-content: center;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box .box_title {
    color: #ffffff;
    font-size: 26px;
    line-height: 32px;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    font-size: 20px;
    width: 100%;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    margin-left: 0px;
    max-width: 244px;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a:hover {
    color: #fff;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a:hover::after {
    top: -100%;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a:hover::before {
    top: -120%;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a:hover span img {
    right: -2px;
    opacity: .8;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .carrierZone_mainPage .container .carrierZone_mainPage_box a span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

.consulting_mainPage {
  float: left;
  width: 100%;
  padding-top: 90px;
}

.consulting_mainPage .container .consulting_mainPage_title {
  font-size: 30px;
  letter-spacing: -1.2px;
  line-height: 36px;
  max-width: 546px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 110px;
  font-weight: 600;
}

.consulting_mainPage .container .consulting_mainPage_items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item {
  width: 33.333%;
  text-align: center;
}

.consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_image_mobile {
  display: none;
}

.consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_wrap {
  padding: 0 30px;
  margin-bottom: 60px;
}

.consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_title {
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 10px;
}

.consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_description {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 20px;
}

.consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_link a {
  color: #ab0000;
  font-size: 16px;
  line-height: 30px;
  font-weight: 900;
  text-decoration: none;
}

.consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_link a:hover {
  color: #820000;
}

.consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item.consulting_mainPage_item--image {
  text-align: center;
  position: relative;
}

.consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item.consulting_mainPage_item--image img {
  max-width: 100%;
}

.consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item.consulting_mainPage_item--image::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 30px;
  content: '';
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;
  z-index: 1;
}

.consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item.consulting_mainPage_item--image:nth-child(6n + 2)::after {
  background-image: url(../img/bg/1.png);
}

.consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item.consulting_mainPage_item--image:nth-child(6n + 4)::after {
  background-image: url(../img/bg/2.png);
  right: auto;
  left: 0;
  background-position: left bottom;
}

.consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item.consulting_mainPage_item--image:nth-child(6n + 6)::after {
  background-image: url(../img/bg/3.png);
}

@media (min-width: 992px) {
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item:nth-child(4) {
    order: 3;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item:nth-child(5) {
    order: 2;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item:nth-child(6) {
    order: 1;
  }
}

@media (max-width: 991.98px) {
  .consulting_mainPage {
    float: left;
    width: 100%;
    padding-top: 40px;
  }
  .consulting_mainPage .container .consulting_mainPage_title {
    margin: 0 auto;
    margin-bottom: 60px;
    max-width: 510px;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -1.12px;
    line-height: 36px;
  }
  .consulting_mainPage .container .consulting_mainPage_items {
    display: flex;
    flex-wrap: wrap;
    align-items: normal;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item {
    width: 33.333%;
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    float: left;
    padding-bottom: 30px;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_image_mobile {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_image_mobile img {
    width: 100%;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_wrap {
    padding: 0 15px;
    margin-bottom: 0px;
    height: 100%;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_title {
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 10px;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_description {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_link {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0;
    text-align: center;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_link a {
    color: #ab0000;
    font-size: 16px;
    line-height: 30px;
    font-weight: 900;
    text-decoration: none;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_link a:hover {
    color: #820000;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item.consulting_mainPage_item--image {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .consulting_mainPage {
    float: left;
    width: 100%;
    padding-top: 20px;
  }
  .consulting_mainPage .container .consulting_mainPage_title {
    margin: 0 auto;
    margin-bottom: 30px;
    max-width: 510px;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.8px;
    line-height: 28px;
  }
  .consulting_mainPage .container .consulting_mainPage_items {
    display: flex;
    flex-wrap: wrap;
    align-items: normal;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    position: relative;
    float: left;
    padding-bottom: 30px;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_image_mobile {
    display: block;
    width: 100%;
    margin-bottom: 30px;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_image_mobile img {
    width: 100%;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_wrap {
    padding: 0 15px;
    margin-bottom: 0px;
    height: 100%;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_title {
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 10px;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_description {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_link {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0;
    text-align: center;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_link a {
    color: #ab0000;
    font-size: 16px;
    line-height: 30px;
    font-weight: 900;
    text-decoration: none;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item .item_link a:hover {
    color: #820000;
  }
  .consulting_mainPage .container .consulting_mainPage_items .consulting_mainPage_item.consulting_mainPage_item--image {
    display: none;
  }
}

.mainPage_news {
  float: left;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 190px;
}

.mainPage_news .container .news_title {
  font-size: 30px;
  letter-spacing: -1.2px;
  line-height: 36px;
  margin-bottom: 60px;
  text-align: center;
  width: 100%;
  float: left;
  font-weight: 600;
}

.mainPage_news .container .news_articles {
  float: left;
  width: 100%;
}

.mainPage_news .container .news_articles .news_article {
  text-align: center;
}

.mainPage_news .container .news_articles .news_article .news_article_image img {
  max-width: 100%;
}

.mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_date {
  color: #202020;
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 25px;
  margin-top: 30px;
  margin-bottom: 16px;
  font-weight: 700;
  width: 100%;
}

.mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_title {
  color: #ab0000;
  font-size: 20px;
  letter-spacing: -0.8px;
  line-height: 25px;
  font-weight: 700;
  padding: 0 30px;
  width: 100%;
}

.mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_title a {
  color: #ab0000;
  text-decoration: none;
}

.mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_title a:hover {
  color: #820000;
}

.mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_subtitle {
  color: #9ca0a6;
  font-size: 16px;
  letter-spacing: -0.64px;
  line-height: 25px;
  font-weight: 400;
  padding: 0 30px;
  margin-top: 15px;
  width: 100%;
}

.mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link {
  width: 100%;
}

.mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
  width: 186px;
  display: inline-block;
  margin-top: 50px;
}

.mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover {
  color: #fff;
}

.mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover::after {
  top: -100%;
}

.mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover::before {
  top: -120%;
}

.mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover span img {
  right: -2px;
  opacity: .8;
}

.mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

@media (max-width: 991.98px) {
  .mainPage_news {
    float: left;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .mainPage_news .container .news_title {
    font-size: 30px;
    letter-spacing: -1.2px;
    line-height: 36px;
    margin-bottom: 60px;
    text-align: center;
    width: 100%;
    float: left;
    font-weight: 600;
  }
  .mainPage_news .container .news_articles {
    float: left;
    width: 100%;
  }
  .mainPage_news .container .news_articles .news_article {
    text-align: left;
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
  }
  .mainPage_news .container .news_articles .news_article .news_article_image {
    width: 50%;
    float: left;
    text-align: right;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap {
    width: 50%;
    float: left;
    padding-left: 20px;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_date {
    color: #202020;
    font-size: 10px;
    letter-spacing: -0.4px;
    line-height: 25px;
    margin-top: 0px;
    margin-bottom: 16px;
    font-weight: 700;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_title {
    color: #ab0000;
    font-size: 20px;
    letter-spacing: -0.8px;
    line-height: 25px;
    font-weight: 700;
    padding: 0 0px;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_subtitle {
    color: #9ca0a6;
    font-size: 16px;
    letter-spacing: -0.64px;
    line-height: 25px;
    font-weight: 400;
    padding: 0 0px;
    margin-top: 15px;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    width: 186px;
    display: inline-block;
    margin-top: 50px;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover {
    color: #fff;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover::after {
    top: -100%;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover::before {
    top: -120%;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover span img {
    right: -2px;
    opacity: .8;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

@media (max-width: 767.98px) {
  .mainPage_news {
    float: left;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .mainPage_news .container .news_title {
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.8px;
    line-height: 28px;
    margin-bottom: 40px;
  }
  .mainPage_news .container .news_articles {
    float: left;
    width: 100%;
  }
  .mainPage_news .container .news_articles .news_article {
    text-align: center;
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
  }
  .mainPage_news .container .news_articles .news_article .news_article_image {
    width: 100%;
    float: left;
    text-align: center;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap {
    width: 100%;
    float: left;
    padding-left: 20px;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    justify-content: center;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_date {
    color: #202020;
    font-size: 10px;
    letter-spacing: -0.4px;
    line-height: 25px;
    margin-top: 20px;
    margin-bottom: 16px;
    font-weight: 700;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_title {
    color: #ab0000;
    font-size: 20px;
    letter-spacing: -0.8px;
    line-height: 25px;
    font-weight: 700;
    padding: 0 0px;
    font-size: 16px;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_subtitle {
    color: #9ca0a6;
    font-size: 16px;
    letter-spacing: -0.64px;
    line-height: 25px;
    font-weight: 400;
    padding: 0 0px;
    margin-top: 15px;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    width: 186px;
    display: inline-block;
    margin-top: 30px;
    font-size: 12px;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover {
    color: #fff;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover::after {
    top: -100%;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover::before {
    top: -120%;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover span img {
    right: -2px;
    opacity: .8;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .mainPage_news .container .news_articles .news_article .news_article_wrap .news_article_link a span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

.mainPage_ourPassion {
  float: left;
  width: 100%;
  padding-bottom: 200px;
}

.mainPage_ourPassion .container {
  position: relative;
}

.mainPage_ourPassion .container .ourPassion_wrap {
  float: left;
  width: 100%;
  position: relative;
}

.mainPage_ourPassion .container .ourPassion {
  max-width: 530px;
  height: 513px;
  float: left;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_title {
  color: #333333;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 40px;
  letter-spacing: -1.2px;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_description {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 65px;
  line-height: 32px;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
  max-width: 244px;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover {
  color: #fff;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover::after {
  top: -100%;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover::before {
  top: -120%;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover span img {
  right: -2px;
  opacity: .8;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

.mainPage_ourPassion .container .ourPassion_images {
  float: right;
  height: 100%;
}

.mainPage_ourPassion .container .ourPassion_images::after {
  display: block;
  position: absolute;
  right: 2vw;
  bottom: 10vh;
  content: '';
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;
  z-index: 1;
  background-image: url(../img/bg/4.png);
}

.mainPage_ourPassion .container .ourPassion_images img {
  position: absolute;
  top: 0;
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
  right: 0;
}

.mainPage_ourPassion .container .ourPassion_images img:nth-child(2) {
  right: 15vw;
  top: 15vh;
}

.mainPage_ourPassion .container .ourPassion_images img:nth-child(3) {
  right: 30vw;
  bottom: 0;
  top: auto;
}

@media (max-width: 1300px) {
  .mainPage_ourPassion .container .ourPassion_images img:nth-child(2) {
    right: 10vw;
  }
}

@media (max-width: 1200px) {
  .mainPage_ourPassion .container .ourPassion_images img {
    width: 300px;
  }
  .mainPage_ourPassion .container .ourPassion_images img:nth-child(2) {
    right: 14vw;
    top: 22vh;
  }
  .mainPage_ourPassion .container .ourPassion_images img:nth-child(3) {
    right: 30vw;
    bottom: 6vw;
  }
}

@media (max-width: 991.98px) {
  .mainPage_ourPassion {
    float: left;
    width: 100%;
    padding-bottom: 200px;
  }
  .mainPage_ourPassion .container {
    position: relative;
  }
  .mainPage_ourPassion .container .ourPassion_wrap {
    float: left;
    width: 100%;
    position: relative;
  }
  .mainPage_ourPassion .container .ourPassion {
    max-width: 530px;
    height: auto;
    float: none;
    margin: 0 auto;
    text-align: center;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_title {
    color: #333333;
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 40px;
    text-align: center;
    letter-spacing: -1.2px;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_description {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 65px;
    text-align: center;
    line-height: 32px;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    max-width: 244px;
    display: inline-block;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover {
    color: #fff;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover::after {
    top: -100%;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover::before {
    top: -120%;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover span img {
    right: -2px;
    opacity: .8;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
  .mainPage_ourPassion .container .ourPassion_images {
    float: left;
    height: 100%;
    width: 100%;
    position: relative;
    height: 60vw;
    margin-top: 80px;
  }
  .mainPage_ourPassion .container .ourPassion_images::after {
    display: block;
    position: absolute;
    right: 2vw;
    bottom: 0vh;
    content: '';
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom right;
    pointer-events: none;
    z-index: 1;
    background-image: url(../img/bg/4.png);
  }
  .mainPage_ourPassion .container .ourPassion_images img {
    position: absolute;
    top: 0;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    right: 0;
    width: 60vw;
  }
  .mainPage_ourPassion .container .ourPassion_images img:nth-child(2) {
    right: 19vw;
    top: 15vh;
  }
  .mainPage_ourPassion .container .ourPassion_images img:nth-child(3) {
    right: auto;
    bottom: 0;
    left: 0px;
    top: auto;
  }
}

@media (max-width: 767.98px) {
  .mainPage_ourPassion {
    float: left;
    width: 100%;
    padding-bottom: 80px;
  }
  .mainPage_ourPassion .container {
    position: relative;
  }
  .mainPage_ourPassion .container .ourPassion_wrap {
    float: left;
    width: 100%;
    position: relative;
  }
  .mainPage_ourPassion .container .ourPassion {
    max-width: 530px;
    height: auto;
    float: none;
    margin: 0 auto;
    text-align: center;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_title {
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.8px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_description {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 30px;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    max-width: 244px;
    display: inline-block;
    font-size: 12px;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover {
    color: #fff;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover::after {
    top: -100%;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover::before {
    top: -120%;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover span img {
    right: -2px;
    opacity: .8;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
  .mainPage_ourPassion .container .ourPassion_images {
    float: left;
    height: 100%;
    width: 100%;
    position: relative;
    height: auto;
    margin-top: 50px;
  }
  .mainPage_ourPassion .container .ourPassion_images::after {
    display: none;
  }
  .mainPage_ourPassion .container .ourPassion_images img {
    position: relative;
    top: auto;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    right: auto;
    width: 100%;
    margin-bottom: 20px;
  }
  .mainPage_ourPassion .container .ourPassion_images img:nth-child(2) {
    right: auto;
    bottom: auto;
    left: auto;
    top: auto;
  }
  .mainPage_ourPassion .container .ourPassion_images img:nth-child(3) {
    right: auto;
    bottom: auto;
    left: auto;
    top: auto;
  }
}

.subPage_intro {
  float: left;
  width: 100%;
  min-height: 300px;
  padding-top: 200px;
  background-size: cover;
}

.subPage_intro .container .wrap {
  float: left;
  position: relative;
  width: 100%;
  z-index: 11;
}

.subPage_intro .container .subPage_intro_title {
  text-align: center;
  float: left;
  width: 100%;
  color: #333333;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -1.44px;
  line-height: 53px;
  font-style: italic;
}

.subPage_intro .container .subPage_intro_arrows {
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  pointer-events: none;
  left: 0;
  right: 0;
  opacity: .8;
}

.subPage_intro .container .subPage_intro_arrows .subPage_intro_arrows_triangle {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #ab0000;
  filter: blur(2px);
  position: absolute;
}

.subPage_intro .container .subPage_intro_arrows .subPage_intro_arrows_triangle.subPage_intro_arrows_triangle:nth-child(1) {
  left: 100px;
  bottom: -86px;
}

.subPage_intro .container .subPage_intro_arrows .subPage_intro_arrows_triangle.subPage_intro_arrows_triangle:nth-child(2) {
  transform: scale(0.7);
  right: 50px;
  bottom: 0;
}

.subPage_intro .container .subPage_intro_arrows .subPage_intro_arrows_triangle.subPage_intro_arrows_triangle:nth-child(3) {
  transform: scale(0.4);
  top: -250%;
  right: 50%;
}

@media (max-width: 991.98px) {
  .subPage_intro {
    float: left;
    width: 100%;
    min-height: 300px;
    padding-top: 200px;
    background-size: cover;
  }
  .subPage_intro .container .wrap {
    float: left;
    position: relative;
    width: 100%;
    z-index: 11;
  }
  .subPage_intro .container .subPage_intro_title {
    color: #333333;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -1.44px;
    line-height: 53px;
  }
  .subPage_intro .container .subPage_intro_arrows {
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;
    pointer-events: none;
    left: 0;
    right: 0;
    opacity: .8;
  }
  .subPage_intro .container .subPage_intro_arrows .subPage_intro_arrows_triangle {
    display: block;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-right: 100px solid #ab0000;
    filter: blur(2px);
    position: absolute;
  }
  .subPage_intro .container .subPage_intro_arrows .subPage_intro_arrows_triangle.subPage_intro_arrows_triangle:nth-child(1) {
    left: 0px;
    bottom: -86px;
  }
  .subPage_intro .container .subPage_intro_arrows .subPage_intro_arrows_triangle.subPage_intro_arrows_triangle:nth-child(2) {
    transform: scale(0.7);
    right: 0px;
    bottom: 0;
  }
  .subPage_intro .container .subPage_intro_arrows .subPage_intro_arrows_triangle.subPage_intro_arrows_triangle:nth-child(3) {
    transform: scale(0.4);
    top: -250%;
    right: 40%;
  }
}

@media (max-width: 767.98px) {
  .subPage_intro {
    float: left;
    width: 100%;
    min-height: 125px;
    padding-top: 80px;
    background-size: cover;
  }
  .subPage_intro .container .wrap {
    float: left;
    position: relative;
    width: 100%;
    z-index: 11;
  }
  .subPage_intro .container .subPage_intro_title {
    color: #333333;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.72px;
    line-height: 53px;
    padding-bottom: 30px;
  }
  .subPage_intro .container .subPage_intro_arrows {
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;
    pointer-events: none;
    left: 0;
    right: 0;
    opacity: .8;
  }
  .subPage_intro .container .subPage_intro_arrows .subPage_intro_arrows_triangle {
    display: block;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-right: 100px solid #ab0000;
    filter: blur(2px);
    position: absolute;
  }
  .subPage_intro .container .subPage_intro_arrows .subPage_intro_arrows_triangle.subPage_intro_arrows_triangle:nth-child(1) {
    left: auto;
    right: -51px;
    bottom: -33px;
    transform: scale(0.3);
  }
  .subPage_intro .container .subPage_intro_arrows .subPage_intro_arrows_triangle.subPage_intro_arrows_triangle:nth-child(2) {
    transform: scale(0.1);
    right: auto;
    left: -94px;
    bottom: 0;
  }
  .subPage_intro .container .subPage_intro_arrows .subPage_intro_arrows_triangle.subPage_intro_arrows_triangle:nth-child(3) {
    display: none;
  }
}

.offer_subPage {
  float: left;
  width: 100%;
  padding-top: 140px;
  padding-bottom: 80px;
}

.offer_subPage .container {
  position: relative;
}

.offer_subPage .container .offer_subPage_points {
  float: left;
  width: 100%;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper {
  display: flex;
  width: calc(100% + 44px);
  margin-left: -22px;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item {
  width: 25%;
  padding-bottom: calc(25% - 44px);
  position: relative;
  background-color: #ab0000;
  margin: 0 22px;
  margin-bottom: 44px;
  background-size: cover;
  display: block;
  box-shadow: 0 0 0px transparent;
  transition-duration: .3s;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item:hover {
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item .item_wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 30px;
  display: block;
  text-decoration: none;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item .item_icon {
  margin-bottom: 30px;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item .item_icon img {
  width: 51px;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item .item_title {
  color: #ffffff;
  font-size: 16px;
  letter-spacing: -0.64px;
  line-height: 24px;
  text-transform: uppercase;
  display: block;
  width: 100%;
  font-weight: 800;
  margin-bottom: 20px;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item .item_description {
  color: #f09696;
  font-size: 16px;
  letter-spacing: -0.64px;
  line-height: 24px;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item:nth-child(2n + 2) {
  background-color: #820000;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item.offer_subPage_points_wraper_item--info .item_wrap {
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item.offer_subPage_points_wraper_item--info .item_wrap .item_title {
  font-size: 21px;
  letter-spacing: -0.84px;
  line-height: 33px;
  color: #ffffff;
  text-transform: none;
  font-weight: 400;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item.offer_subPage_points_wraper_item--info .item_wrap .item_title strong {
  color: #ab0000;
  font-weight: 600;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item.offer_subPage_points_wraper_item--info .item_wrap .item_link {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item.offer_subPage_points_wraper_item--info .item_wrap .item_link::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item.offer_subPage_points_wraper_item--info .item_wrap .item_link::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item.offer_subPage_points_wraper_item--info .item_wrap .item_link:hover {
  color: #fff;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item.offer_subPage_points_wraper_item--info .item_wrap .item_link:hover::after {
  top: -100%;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item.offer_subPage_points_wraper_item--info .item_wrap .item_link:hover::before {
  top: -120%;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item.offer_subPage_points_wraper_item--info .item_wrap .item_link:hover span img {
  right: -2px;
  opacity: .8;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item.offer_subPage_points_wraper_item--info .item_wrap .item_link span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item.offer_subPage_points_wraper_item--info .item_wrap .item_link span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

.offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item.offer_subPage_points_wraper_item--z1 {
  position: relative;
  z-index: 1;
}

@media (max-width: 1320px) {
  .offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item .item_wrap {
    position: relative;
  }
  .offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item {
    padding-bottom: 0px;
  }
}

@media (max-width: 991.98px) {
  .offer_subPage {
    padding-top: 110px;
    padding-bottom: 60px;
  }
  .offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper {
    flex-wrap: wrap;
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item {
    width: calc(50% - 20px);
    margin: 0 10px;
    margin-bottom: 20px;
  }
  .offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item.offer_subPage_points_wraper_item--blankItem {
    width: 30%;
  }
  .offer_subPage .container .offer_subPage_rightImage {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .offer_subPage {
    padding-top: 60px;
    padding-bottom: 30px;
    float: left;
    width: 100%;
  }
  .offer_subPage .container .offer_subPage_header {
    display: none;
  }
  .offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper {
    flex-wrap: wrap;
  }
  .offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item {
    margin-bottom: 20px;
    width: 100%;
    min-height: 280px;
  }
  .offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item .item_wrap {
    padding: 20px;
  }
  .offer_subPage .container .offer_subPage_points .offer_subPage_points_wraper .offer_subPage_points_wraper_item.offer_subPage_points_wraper_item--blankItem {
    display: none;
  }
  .offer_subPage .container .offer_subPage_rightImage {
    display: none;
  }
}

.offer2_subPage {
  float: left;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 80px;
}

.offer2_subPage .container {
  position: relative;
}

.offer2_subPage .container .offer2_subPage_points {
  float: left;
  width: 100%;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper {
  display: flex;
  width: calc(100% + 44px);
  margin-left: -22px;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item {
  width: 25%;
  padding-bottom: calc(25% - 44px);
  position: relative;
  background-color: #ab0000;
  margin: 0 22px;
  margin-bottom: 44px;
  background-size: cover;
  display: block;
  box-shadow: 0 0 0px transparent;
  transition-duration: .3s;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item:hover {
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item .item_wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 30px;
  display: block;
  text-decoration: none;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item .item_icon {
  margin-bottom: 30px;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item .item_icon img {
  width: 51px;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item .item_title {
  color: #ffffff;
  font-size: 16px;
  letter-spacing: -0.64px;
  line-height: 24px;
  text-transform: uppercase;
  display: block;
  width: 100%;
  font-weight: 800;
  margin-bottom: 20px;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item .item_description {
  color: #f09696;
  font-size: 16px;
  letter-spacing: -0.64px;
  line-height: 24px;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item:nth-child(2n + 2) {
  background-color: #820000;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item.offer2_subPage_points_wraper_item--info .item_wrap {
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item.offer2_subPage_points_wraper_item--info .item_wrap .item_title {
  font-size: 21px;
  letter-spacing: -0.84px;
  line-height: 33px;
  color: #ffffff;
  text-transform: none;
  font-weight: 400;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item.offer2_subPage_points_wraper_item--info .item_wrap .item_title strong {
  color: #ab0000;
  font-weight: 600;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item.offer2_subPage_points_wraper_item--info .item_wrap .item_link {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item.offer2_subPage_points_wraper_item--info .item_wrap .item_link::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item.offer2_subPage_points_wraper_item--info .item_wrap .item_link::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item.offer2_subPage_points_wraper_item--info .item_wrap .item_link:hover {
  color: #fff;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item.offer2_subPage_points_wraper_item--info .item_wrap .item_link:hover::after {
  top: -100%;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item.offer2_subPage_points_wraper_item--info .item_wrap .item_link:hover::before {
  top: -120%;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item.offer2_subPage_points_wraper_item--info .item_wrap .item_link:hover span img {
  right: -2px;
  opacity: .8;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item.offer2_subPage_points_wraper_item--info .item_wrap .item_link span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item.offer2_subPage_points_wraper_item--info .item_wrap .item_link span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item.offer2_subPage_points_wraper_item--z1 {
  position: relative;
  z-index: 1;
}

@media (max-width: 1320px) {
  .offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item .item_wrap {
    position: relative;
  }
  .offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item {
    padding-bottom: 0px;
  }
}

@media (max-width: 991.98px) {
  .offer2_subPage {
    padding-top: 110px;
    padding-bottom: 60px;
  }
  .offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper {
    flex-wrap: wrap;
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item {
    width: calc(50% - 20px);
    margin: 0 10px;
    margin-bottom: 20px;
  }
  .offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item.offer2_subPage_points_wraper_item--blankItem {
    width: 30%;
  }
  .offer2_subPage .container .offer2_subPage_rightImage {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .offer2_subPage {
    padding-top: 60px;
    padding-bottom: 30px;
    float: left;
    width: 100%;
  }
  .offer2_subPage .container .offer2_subPage_header {
    display: none;
  }
  .offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper {
    flex-wrap: wrap;
  }
  .offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item {
    margin-bottom: 20px;
    width: 100%;
    min-height: 280px;
  }
  .offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item .item_wrap {
    padding: 20px;
  }
  .offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item.offer2_subPage_points_wraper_item--blankItem {
    display: none;
  }
  .offer2_subPage .container .offer2_subPage_rightImage {
    display: none;
  }
}

.carrierZone_subPage {
  float: left;
  width: 100%;
  padding-top: 140px;
  padding-bottom: 180px;
}

.carrierZone_subPage.carrierZone_subPage--marginChange {
  padding-top: 0;
}

.carrierZone_subPage .container .carrierZone_wrap {
  position: relative;
  float: left;
  width: 100%;
}

.carrierZone_subPage .container .carrierZone_subPage_bacgkround {
  display: block;
  width: 646px;
  position: absolute;
  top: -40px;
  bottom: -40px;
  left: 0;
  background-color: #000;
  background-size: cover;
}

.carrierZone_subPage .container .carrierZone_subPage_box {
  display: flex;
  background-color: #202020;
  padding: 70px 70px;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  float: right;
  width: 761px;
}

.carrierZone_subPage .container .carrierZone_subPage_box .box_title {
  color: #ffffff;
  font-size: 30px;
  line-height: 32px;
  margin: 0;
  padding: 0;
}

.carrierZone_subPage .container .carrierZone_subPage_box .box_title.carrierZone_subPage--fontChange {
  font-size: 22px;
}

.carrierZone_subPage .container .carrierZone_subPage_box a {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
  margin-left: auto;
  max-width: 244px;
}

.carrierZone_subPage .container .carrierZone_subPage_box a::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.carrierZone_subPage .container .carrierZone_subPage_box a::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.carrierZone_subPage .container .carrierZone_subPage_box a:hover {
  color: #fff;
}

.carrierZone_subPage .container .carrierZone_subPage_box a:hover::after {
  top: -100%;
}

.carrierZone_subPage .container .carrierZone_subPage_box a:hover::before {
  top: -120%;
}

.carrierZone_subPage .container .carrierZone_subPage_box a:hover span img {
  right: -2px;
  opacity: .8;
}

.carrierZone_subPage .container .carrierZone_subPage_box a span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.carrierZone_subPage .container .carrierZone_subPage_box a span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

@media (max-width: 1300px) {
  .carrierZone_subPage {
    padding-top: 170px;
  }
}

@media (max-width: 991.98px) {
  .carrierZone_subPage {
    float: left;
    width: 100%;
    padding-top: 200px;
    padding-bottom: 40px;
  }
  .carrierZone_subPage.carrierZone_subPage--marginChange {
    padding-top: 100px;
  }
  .carrierZone_subPage .container .carrierZone_wrap {
    position: relative;
    float: left;
    width: 100%;
  }
  .carrierZone_subPage .container .carrierZone_subPage_bacgkround {
    position: absolute;
    top: -100%;
    bottom: 50%;
    left: 0;
    width: 100%;
    right: 0;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box {
    display: flex;
    background-color: #202020;
    padding: 60px 45px;
    justify-content: center;
    position: relative;
    z-index: 1;
    align-items: center;
    width: 612px;
    float: none;
    margin: 0 auto;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box .box_title {
    color: #ffffff;
    font-size: 26px;
    line-height: 32px;
    margin: 0;
    padding: 0;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    margin-left: auto;
    max-width: 244px;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a:hover {
    color: #fff;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a:hover::after {
    top: -100%;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a:hover::before {
    top: -120%;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a:hover span img {
    right: -2px;
    opacity: .8;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

@media (max-width: 767.98px) {
  .carrierZone_subPage {
    float: left;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 40px;
  }
  .carrierZone_subPage.carrierZone_subPage--marginChange {
    padding-top: 60px;
  }
  .carrierZone_subPage .container .carrierZone_wrap {
    position: relative;
    float: left;
    width: 100%;
  }
  .carrierZone_subPage .container .carrierZone_subPage_bacgkround {
    position: absolute;
    top: -80%;
    bottom: 50%;
    left: 0;
    width: 100%;
    height: 120px;
    right: 0;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box {
    display: flex;
    background-color: #202020;
    margin: 0 10px;
    padding: 15px;
    justify-content: center;
    position: relative;
    z-index: 1;
    align-items: center;
    width: calc(100% - 20px);
    flex-wrap: wrap;
    float: none;
    margin-left: 10px;
    text-align: center;
    justify-content: center;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box .box_title {
    color: #ffffff;
    font-size: 26px;
    line-height: 32px;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    font-size: 20px;
    width: 100%;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    margin-left: 0px;
    max-width: 244px;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a:hover {
    color: #fff;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a:hover::after {
    top: -100%;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a:hover::before {
    top: -120%;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a:hover span img {
    right: -2px;
    opacity: .8;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .carrierZone_subPage .container .carrierZone_subPage_box a span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

.imageBox {
  float: left;
  width: 100%;
}

.imageBox .imageBox_content {
  width: 70%;
  height: 330px;
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
  float: right;
  background-size: cover;
  background-position: center;
}

@media (max-width: 991.98px) {
  .imageBox {
    float: left;
    width: 100%;
    margin-bottom: 40px;
  }
  .imageBox .imageBox_content {
    width: calc(100% - 40px);
    height: 214px;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    float: right;
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 767.98px) {
  .imageBox {
    float: left;
    width: 100%;
    margin-bottom: 30px;
  }
  .imageBox .imageBox_content {
    width: calc(100% - 20px);
    height: 95px;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    float: right;
    background-size: cover;
    background-position: center;
  }
}

.subPage_textBox_imageRight {
  float: left;
  width: 100%;
  padding-top: 110px;
  padding-bottom: 75px;
}

.subPage_textBox_imageRight .container {
  max-width: calc(876px + 80px);
  position: relative;
}

.subPage_textBox_imageRight .container::before {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #ab0000;
  filter: blur(1px);
  content: '';
  position: absolute;
  transform: scale(2);
  left: -35%;
  top: 100px;
}

.subPage_textBox_imageRight .container .textBox_imageRight_title {
  color: #333333;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 32px;
  margin-bottom: 30px;
}

.subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_text {
  float: left;
  padding-top: 30px;
  width: 60%;
  padding-right: 40px;
}

.subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_text p {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 30px;
}

.subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_image {
  float: left;
  width: 70%;
  margin-right: -40%;
  background-color: red;
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
  position: relative;
}

.subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_image .wrap {
  overflow: hidden;
}

.subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_image .wrap img {
  width: 100%;
}

.subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_image::after {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #fff;
  filter: blur(2px);
  content: '';
  position: absolute;
  transform: scale(0.8);
  left: -20px;
  bottom: 20px;
}

.subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_image::before {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #ab0000;
  filter: blur(2px);
  content: '';
  position: absolute;
  transform: scale(1);
  right: 30px;
  top: -50px;
}

@media (max-width: 991.98px) {
  .subPage_textBox_imageRight {
    float: left;
    width: 100%;
    padding-top: 110px;
    padding-bottom: 75px;
  }
  .subPage_textBox_imageRight .container {
    max-width: calc(876px + 80px);
  }
  .subPage_textBox_imageRight .container::before {
    display: none;
  }
  .subPage_textBox_imageRight .container .textBox_imageRight_title {
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.03px;
    line-height: 32px;
  }
  .subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_text {
    float: left;
    padding-top: 10px;
    width: 60%;
    padding-right: 40px;
  }
  .subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_text p {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
  }
  .subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_image {
    float: left;
    width: 70%;
    margin-right: -40%;
    background-color: red;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
  }
  .subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_image .wrap img {
    width: 100%;
  }
  .subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_image::after {
    display: none;
  }
  .subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_image::before {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .subPage_textBox_imageRight {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .subPage_textBox_imageRight .container .textBox_imageRight_title {
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.03px;
    line-height: 26px;
    text-align: center;
  }
  .subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_text {
    float: left;
    padding-top: 10px;
    width: 100%;
    padding-right: 0px;
    text-align: center;
  }
  .subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_text p {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  .subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_image {
    float: left;
    width: 100%;
    background-color: transparent;
    margin-right: 0%;
    height: 400px;
    text-align: center;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
  }
  .subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_image .wrap {
    height: 100%;
  }
  .subPage_textBox_imageRight .container .textBox_imageRight_wrap .textBox_imageRight_image .wrap img {
    width: auto;
    height: 100%;
  }
}

.subPage_quote {
  float: left;
  width: 100%;
  position: relative;
  z-index: 11;
  margin-bottom: 130px;
}

.subPage_quote.quote--marginChange {
  margin-bottom: 0;
  margin-top: 110px;
}

.subPage_quote .container {
  max-width: calc(876px + 80px);
  position: relative;
}

.subPage_quote .container .quote_content {
  background-color: #333333;
  text-align: center;
  padding: 32px 50px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
}

.subPage_quote .container .quote_content p {
  margin: 0;
  padding: 0;
}

.subPage_quote .container .quote_content::before {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #ab0000;
  filter: blur(2px);
  content: '';
  position: absolute;
  transform: scale(0.6);
  left: 30px;
  bottom: -50px;
}

@media (max-width: 991.98px) {
  .subPage_quote {
    margin-bottom: 100px;
  }
  .subPage_quote .container .quote_content {
    font-size: 18px;
    line-height: 32px;
  }
  .subPage_quote .container .quote_content p {
    font-size: 18px;
    line-height: 32px;
  }
  .subPage_quote .container .quote_content::before {
    display: block;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-right: 100px solid #ab0000;
    filter: blur(2px);
    content: '';
    position: absolute;
    left: auto;
    transform: scale(0.6);
    right: 30%;
    bottom: -50px;
  }
}

@media (max-width: 767.98px) {
  .subPage_quote {
    margin-bottom: 70px;
  }
  .subPage_quote .container .quote_content {
    font-size: 16px;
    line-height: 24px;
    padding: 30px 20px;
    padding-bottom: 45px;
  }
  .subPage_quote .container .quote_content p {
    font-size: 16px;
    line-height: 24px;
  }
  .subPage_quote .container .quote_content::before {
    display: block;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-right: 100px solid #ab0000;
    filter: blur(2px);
    content: '';
    position: absolute;
    left: auto;
    transform: scale(0.6);
    right: 30%;
    bottom: -50px;
  }
}

.subPage_quoteWithTitle {
  float: left;
  width: 100%;
  position: relative;
  z-index: 11;
  margin-bottom: 130px;
  padding-top: 110px;
}

.subPage_quoteWithTitle.quote--marginChange {
  margin-bottom: 0;
  margin-top: 110px;
}

.subPage_quoteWithTitle .container {
  max-width: calc(876px + 80px);
  position: relative;
}

.subPage_quoteWithTitle .container .quote_title {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 32px;
}

.subPage_quoteWithTitle .container .quote_title p {
  margin-bottom: 40px;
}

.subPage_quoteWithTitle .container .quote_title strong {
  font-weight: 700;
}

.subPage_quoteWithTitle .container .quote_content {
  background-color: #333333;
  text-align: center;
  padding: 50px 50px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  color: #ffffff;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
}

.subPage_quoteWithTitle .container .quote_content p {
  margin: 0;
  padding: 0;
}

.subPage_quoteWithTitle .container .quote_content::before {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #ab0000;
  filter: blur(2px);
  content: '';
  position: absolute;
  transform: scale(0.6);
  left: 30px;
  bottom: -50px;
}

@media (max-width: 991.98px) {
  .subPage_quoteWithTitle {
    margin-bottom: 100px;
  }
  .subPage_quoteWithTitle .container .quote_content {
    font-size: 28px;
    line-height: 32px;
  }
  .subPage_quoteWithTitle .container .quote_content p {
    font-size: 28px;
    line-height: 32px;
  }
  .subPage_quoteWithTitle .container .quote_content::before {
    display: block;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-right: 100px solid #ab0000;
    filter: blur(2px);
    content: '';
    position: absolute;
    left: auto;
    transform: scale(0.6);
    right: 30%;
    bottom: -50px;
  }
}

@media (max-width: 767.98px) {
  .subPage_quoteWithTitle {
    margin-bottom: 45px;
    padding-top: 45px;
  }
  .subPage_quoteWithTitle .container .quote_title {
    text-align: center;
  }
  .subPage_quoteWithTitle .container .quote_title p {
    margin-bottom: 20px;
  }
  .subPage_quoteWithTitle .container .quote_content {
    font-size: 16px;
    line-height: 24px;
    padding: 30px 20px;
    padding-bottom: 45px;
    color: #ffffff;
    margin-top: 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .subPage_quoteWithTitle .container .quote_content p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .subPage_quoteWithTitle .container .quote_content::before {
    display: block;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-right: 100px solid #ab0000;
    filter: blur(2px);
    content: '';
    position: absolute;
    left: auto;
    transform: scale(0.6);
    right: 30%;
    bottom: -50px;
  }
}

.subPage_textBox_imageLeft {
  float: left;
  width: 100%;
  margin-bottom: 150px;
}

.subPage_textBox_imageLeft .container {
  max-width: calc(876px + 80px);
  position: relative;
}

.subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text {
  float: left;
  padding-top: 10px;
  width: 60%;
  padding-left: 40px;
}

.subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text p {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 30px;
}

.subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
  width: 244px;
  margin-top: 36px;
}

.subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link:hover {
  color: #fff;
}

.subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link:hover::after {
  top: -100%;
}

.subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link:hover::before {
  top: -120%;
}

.subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link:hover span img {
  right: -2px;
  opacity: .8;
}

.subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

.subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_image {
  float: left;
  width: 70%;
  margin-left: -30%;
  background-color: red;
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
  position: relative;
}

.subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_image .wrap {
  overflow: hidden;
}

.subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_image .wrap img {
  width: 100%;
}

.subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_image::after {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #fff;
  filter: blur(2px);
  content: '';
  position: absolute;
  transform: scale(0.8);
  right: 20px;
  bottom: 20px;
}

.subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_image::before {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #ab0000;
  filter: blur(2px);
  content: '';
  position: absolute;
  transform: scale(0.6);
  left: -100px;
  top: 50px;
}

@media (max-width: 991.98px) {
  .subPage_textBox_imageLeft {
    float: left;
    width: 100%;
    margin-bottom: 115px;
  }
  .subPage_textBox_imageLeft .container {
    max-width: calc(876px + 80px);
    position: relative;
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content {
    padding: 0 40px;
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text {
    float: left;
    padding-top: 10px;
    width: 100%;
    padding-left: 0px;
    text-align: center;
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text p {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 30px;
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    width: 244px;
    display: inline-block;
    margin-top: 36px;
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link:hover {
    color: #fff;
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link:hover::after {
    top: -100%;
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link:hover::before {
    top: -120%;
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link:hover span img {
    right: -2px;
    opacity: .8;
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_text .item_link span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_image {
    float: left;
    width: 100%;
    margin-left: 0%;
    background-color: red;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    position: relative;
    margin-bottom: 40px;
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_image .wrap {
    overflow: hidden;
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_image .wrap img {
    width: 100%;
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_image::after {
    display: none;
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content .textBox_imageLeft_content_wrap .textBox_imageLeft_content_image::before {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .subPage_textBox_imageLeft {
    margin-bottom: 80px;
  }
  .subPage_textBox_imageLeft .container .textBox_imageLeft_content {
    padding: 0px;
  }
}

.subPage_textBox_imageLeft_inGrid {
  float: left;
  width: 100%;
  padding-top: 110px;
  padding-bottom: 130px;
}

.subPage_textBox_imageLeft_inGrid .container {
  position: relative;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_title {
  max-width: calc(876px + 80px);
  margin: 0 auto;
  color: #333333;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 32px;
  margin-bottom: 80px;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_image {
  float: left;
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
  position: relative;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_image .wrap img {
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_image::after {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #fff;
  filter: blur(2px);
  content: '';
  position: absolute;
  transform: scale(0.8);
  right: 20px;
  bottom: 20px;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_image::before {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #ab0000;
  filter: blur(2px);
  content: '';
  position: absolute;
  transform: scale(0.6);
  left: -100px;
  top: 50px;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text {
  float: left;
  width: 100%;
  padding-top: 10px;
  padding-left: 30px;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text strong {
  font-size: 20px;
  font-weight: 700;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text p {
  margin-bottom: 30px;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ul, .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ol {
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ul li, .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ol li {
  line-height: 50px;
  padding-left: 20px;
  position: relative;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ul li::before, .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ol li::before {
  width: 7px;
  display: inline-block;
  height: 10px;
  background-image: url(../img/icons/arrowRight-red.svg);
  content: '';
  position: absolute;
  left: 0;
  top: 20px;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
  width: 244px;
  margin-top: 36px;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link:hover {
  color: #fff;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link:hover::after {
  top: -100%;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link:hover::before {
  top: -120%;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link:hover span img {
  right: -2px;
  opacity: .8;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

@media (max-width: 991.98px) {
  .subPage_textBox_imageLeft_inGrid {
    float: left;
    width: 100%;
    padding-top: 110px;
    padding-bottom: 130px;
  }
  .subPage_textBox_imageLeft_inGrid .container {
    position: relative;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_title {
    max-width: calc(876px + 80px);
    margin: 0 auto;
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.03px;
    line-height: 32px;
    margin-bottom: 80px;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_image {
    float: left;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    position: relative;
    height: 100%;
    width: calc(100% + 40px);
    margin-left: -40px;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_image .wrap {
    height: 100%;
    overflow: hidden;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_image .wrap img {
    height: 100%;
    width: auto;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_image::after {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_image::before {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text {
    float: left;
    width: 100%;
    padding-top: 10px;
    padding-left: 15px;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text strong {
    font-size: 20px;
    font-weight: 700;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text p {
    margin-bottom: 30px;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ul,
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ol {
    list-style: none;
    padding: 0;
    margin: 0;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ul li,
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ol li {
    line-height: 50px;
    padding-left: 20px;
    position: relative;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ul li::before,
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ol li::before {
    width: 7px;
    display: inline-block;
    height: 10px;
    background-image: url(../img/icons/arrowRight-red.svg);
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    width: 244px;
    margin-top: 36px;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link:hover {
    color: #fff;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link:hover::after {
    top: -100%;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link:hover::before {
    top: -120%;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link:hover span img {
    right: -2px;
    opacity: .8;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

@media (max-width: 767.98px) {
  .subPage_textBox_imageLeft_inGrid {
    float: left;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .subPage_textBox_imageLeft_inGrid .container {
    position: relative;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_title {
    max-width: calc(876px + 80px);
    margin: 0 auto;
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.03px;
    line-height: 32px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 26px;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_image {
    float: left;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    position: relative;
    height: 100%;
    width: 100%;
    margin-left: 0px;
    height: 350px;
    text-align: center;
    margin-bottom: 30px;
    background-color: transparent;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_image .wrap {
    height: 100%;
    overflow: hidden;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_image .wrap img {
    height: 100%;
    width: auto;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_image::after {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_image::before {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text {
    float: left;
    width: 100%;
    padding-top: 10px;
    padding-left: 0px;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text strong {
    font-size: 16px;
    font-weight: 700;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text p {
    margin-bottom: 30px;
    float: left;
    text-align: center;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ul,
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ol {
    list-style: none;
    padding: 0;
    margin: 0;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ul li,
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ol li {
    line-height: 50px;
    padding-left: 20px;
    position: relative;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ul li::before,
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text ol li::before {
    width: 7px;
    display: inline-block;
    height: 10px;
    background-image: url(../img/icons/arrowRight-red.svg);
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    margin: 0 auto;
    width: 244px;
    margin-top: 16px;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link:hover {
    color: #fff;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link:hover::after {
    top: -100%;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link:hover::before {
    top: -120%;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link:hover span img {
    right: -2px;
    opacity: .8;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .subPage_textBox_imageLeft_inGrid .container .subPage_textBox_imageLeft_inGrid_content .subPage_textBox_imageLeft_inGrid_content_wrap .subPage_textBox_imageLeft_inGrid_content_text .item_link span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

.subPage_textBox_imageLeft_inGrid-5-5 {
  float: left;
  width: 100%;
  padding-top: 110px;
  padding-bottom: 130px;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container {
  position: relative;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_title {
  max-width: calc(876px + 80px);
  margin: 0 auto;
  color: #333333;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 32px;
  margin-bottom: 80px;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_image {
  float: left;
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
  position: relative;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_image .wrap img {
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_image::after {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #fff;
  filter: blur(2px);
  content: '';
  position: absolute;
  transform: scale(0.8);
  right: 20px;
  bottom: 20px;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_image::before {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #ab0000;
  filter: blur(2px);
  content: '';
  position: absolute;
  transform: scale(0.6);
  left: -100px;
  top: 50px;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text {
  float: left;
  width: 100%;
  padding-top: 10px;
  padding-left: 30px;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text strong {
  font-size: 20px;
  font-weight: 700;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text p {
  margin-bottom: 30px;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ul, .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ol {
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ul li, .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ol li {
  line-height: 50px;
  padding-left: 20px;
  position: relative;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ul li::before, .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ol li::before {
  width: 7px;
  display: inline-block;
  height: 10px;
  background-image: url(../img/icons/arrowRight-red.svg);
  content: '';
  position: absolute;
  left: 0;
  top: 20px;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
  width: 244px;
  margin-top: 36px;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link:hover {
  color: #fff;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link:hover::after {
  top: -100%;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link:hover::before {
  top: -120%;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link:hover span img {
  right: -2px;
  opacity: .8;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

@media (max-width: 991.98px) {
  .subPage_textBox_imageLeft_inGrid-5-5 {
    float: left;
    width: 100%;
    padding-top: 110px;
    padding-bottom: 130px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container {
    position: relative;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_title {
    max-width: calc(876px + 80px);
    margin: 0 auto;
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.03px;
    line-height: 32px;
    margin-bottom: 80px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_image {
    float: left;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    position: relative;
    height: 100%;
    width: calc(100% + 40px);
    margin-left: -40px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_image .wrap {
    height: 100%;
    overflow: hidden;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_image .wrap img {
    height: 100%;
    width: auto;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_image::after {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_image::before {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text {
    float: left;
    width: 100%;
    padding-top: 10px;
    padding-left: 30px;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text strong {
    font-size: 20px;
    font-weight: 700;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text p {
    margin-bottom: 30px;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ul,
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ol {
    list-style: none;
    padding: 0;
    margin: 0;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ul li,
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ol li {
    line-height: 50px;
    padding-left: 20px;
    position: relative;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ul li::before,
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ol li::before {
    width: 7px;
    display: inline-block;
    height: 10px;
    background-image: url(../img/icons/arrowRight-red.svg);
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    width: 244px;
    margin-top: 36px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link:hover {
    color: #fff;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link:hover::after {
    top: -100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link:hover::before {
    top: -120%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link:hover span img {
    right: -2px;
    opacity: .8;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

@media (max-width: 767.98px) {
  .subPage_textBox_imageLeft_inGrid-5-5 {
    float: left;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container {
    position: relative;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_title {
    max-width: calc(876px + 80px);
    margin: 0 auto;
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.03px;
    line-height: 32px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 26px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_image {
    float: left;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    position: relative;
    height: 100%;
    width: 100%;
    margin-left: 0px;
    height: 350px;
    text-align: center;
    margin-bottom: 30px;
    background-color: transparent;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_image .wrap {
    height: 100%;
    overflow: hidden;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_image .wrap img {
    height: 100%;
    width: auto;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_image::after {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_image::before {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text {
    float: left;
    width: 100%;
    padding-top: 10px;
    padding-left: 0px;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text strong {
    font-size: 16px;
    font-weight: 700;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text p {
    margin-bottom: 30px;
    float: left;
    text-align: center;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ul,
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ol {
    list-style: none;
    padding: 0;
    margin: 0;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ul li,
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ol li {
    line-height: 50px;
    padding-left: 20px;
    position: relative;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ul li::before,
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text ol li::before {
    width: 7px;
    display: inline-block;
    height: 10px;
    background-image: url(../img/icons/arrowRight-red.svg);
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    margin: 0 auto;
    width: 244px;
    margin-top: 16px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link:hover {
    color: #fff;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link:hover::after {
    top: -100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link:hover::before {
    top: -120%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link:hover span img {
    right: -2px;
    opacity: .8;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .subPage_textBox_imageLeft_inGrid-5-5 .container .subPage_textBox_imageLeft_inGrid-5-5_content .subPage_textBox_imageLeft_inGrid-5-5_content_wrap .subPage_textBox_imageLeft_inGrid-5-5_content_text .item_link span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

.subPage_textBox_imageLeft_inGrid-5-5-center {
  float: left;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 130px;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container {
  position: relative;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_title {
  max-width: calc(876px + 80px);
  margin: 0 auto;
  color: #333333;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 32px;
  margin-bottom: 80px;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_image {
  float: left;
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
  position: relative;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_image .wrap img {
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_image::after {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #fff;
  filter: blur(2px);
  content: '';
  position: absolute;
  transform: scale(0.8);
  right: 20px;
  bottom: 20px;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_image::before {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #ab0000;
  filter: blur(2px);
  content: '';
  position: absolute;
  transform: scale(0.6);
  left: -100px;
  top: 50px;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text {
  float: left;
  width: 100%;
  padding-top: 10px;
  padding-left: 30px;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text strong {
  font-size: 20px;
  font-weight: 700;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text p {
  margin-bottom: 30px;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ul, .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ol {
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ul li, .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ol li {
  line-height: 50px;
  padding-left: 20px;
  position: relative;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ul li::before, .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ol li::before {
  width: 7px;
  display: inline-block;
  height: 10px;
  background-image: url(../img/icons/arrowRight-red.svg);
  content: '';
  position: absolute;
  left: 0;
  top: 20px;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
  width: 244px;
  margin-top: 36px;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link:hover {
  color: #fff;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link:hover::after {
  top: -100%;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link:hover::before {
  top: -120%;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link:hover span img {
  right: -2px;
  opacity: .8;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

@media (max-width: 991.98px) {
  .subPage_textBox_imageLeft_inGrid-5-5-center {
    float: left;
    width: 100%;
    padding-top: 110px;
    padding-bottom: 130px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container {
    position: relative;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_title {
    max-width: calc(876px + 80px);
    margin: 0 auto;
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.03px;
    line-height: 32px;
    margin-bottom: 80px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_image {
    float: left;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    position: relative;
    height: 100%;
    width: calc(100% + 40px);
    margin-left: -40px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_image .wrap {
    height: 100%;
    overflow: hidden;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_image .wrap img {
    height: 100%;
    width: auto;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_image::after {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_image::before {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text {
    float: left;
    width: 100%;
    padding-top: 10px;
    padding-left: 30px;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text strong {
    font-size: 20px;
    font-weight: 700;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text p {
    margin-bottom: 30px;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ul,
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ol {
    list-style: none;
    padding: 0;
    margin: 0;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ul li,
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ol li {
    line-height: 50px;
    padding-left: 20px;
    position: relative;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ul li::before,
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ol li::before {
    width: 7px;
    display: inline-block;
    height: 10px;
    background-image: url(../img/icons/arrowRight-red.svg);
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    width: 244px;
    margin-top: 36px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link:hover {
    color: #fff;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link:hover::after {
    top: -100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link:hover::before {
    top: -120%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link:hover span img {
    right: -2px;
    opacity: .8;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

@media (max-width: 767.98px) {
  .subPage_textBox_imageLeft_inGrid-5-5-center {
    float: left;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container {
    position: relative;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_title {
    max-width: calc(876px + 80px);
    margin: 0 auto;
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.03px;
    line-height: 32px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 26px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_image {
    float: left;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    position: relative;
    height: 100%;
    width: 100%;
    margin-left: 0px;
    height: 350px;
    text-align: center;
    margin-bottom: 30px;
    background-color: transparent;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_image .wrap {
    height: 100%;
    overflow: hidden;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_image .wrap img {
    height: 100%;
    width: auto;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_image::after {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_image::before {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text {
    float: left;
    width: 100%;
    padding-top: 10px;
    padding-left: 0px;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text strong {
    font-size: 16px;
    font-weight: 700;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text p {
    margin-bottom: 30px;
    float: left;
    text-align: center;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ul,
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ol {
    list-style: none;
    padding: 0;
    margin: 0;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ul li,
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ol li {
    padding-left: 20px;
    position: relative;
    float: left;
    width: 100%;
    font-size: 16px;
    margin: 10px 0;
    line-height: 24px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ul li::before,
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text ol li::before {
    width: 7px;
    display: inline-block;
    height: 10px;
    background-image: url(../img/icons/arrowRight-red.svg);
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    margin: 0 auto;
    width: 244px;
    margin-top: 16px;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link:hover {
    color: #fff;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link:hover::after {
    top: -100%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link:hover::before {
    top: -120%;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link:hover span img {
    right: -2px;
    opacity: .8;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .subPage_textBox_imageLeft_inGrid-5-5-center .container .subPage_textBox_imageLeft_inGrid-5-5-center_content .subPage_textBox_imageLeft_inGrid-5-5-center_content_wrap .subPage_textBox_imageLeft_inGrid-5-5-center_content_text .item_link span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

.subPage_textBox_imageLeft_inGrid-6-5 {
  float: left;
  width: 100%;
  padding-top: 110px;
  padding-bottom: 130px;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container {
  position: relative;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_title {
  max-width: calc(876px + 80px);
  margin: 0 auto;
  color: #333333;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 32px;
  margin-bottom: 80px;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image {
  float: left;
  position: relative;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image .wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22%;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image .wrap.reset_margin {
  margin-bottom: 2%;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image .wrap img {
  width: 48%;
  z-index: 2;
  -webkit-box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image .wrap .image2 {
  position: absolute;
  top: 79%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  -webkit-box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image .wrap .image3 {
  position: relative;
  margin-top: 6%;
  z-index: -1;
  -webkit-box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image::before {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #ab0000;
  filter: blur(2px);
  content: '';
  position: absolute;
  transform: scale(0.6);
  right: 0px;
  z-index: 1;
  bottom: 27%;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text {
  float: left;
  width: 100%;
  padding-top: 10px;
  padding-left: 30px;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  line-height: 32px;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text strong {
  font-size: 16px;
  font-weight: 700;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text p {
  margin-bottom: 30px;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ul, .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ol {
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ul li, .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ol li {
  line-height: 50px;
  padding-left: 20px;
  position: relative;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ul li::before, .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ol li::before {
  width: 7px;
  display: inline-block;
  height: 10px;
  background-image: url(../img/icons/arrowRight-red.svg);
  content: '';
  position: absolute;
  left: 0;
  top: 20px;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
  width: 244px;
  margin-top: 36px;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link:hover {
  color: #fff;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link:hover::after {
  top: -100%;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link:hover::before {
  top: -120%;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link:hover span img {
  right: -2px;
  opacity: .8;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

@media (max-width: 991.98px) {
  .subPage_textBox_imageLeft_inGrid-6-5 {
    float: left;
    width: 100%;
    padding-top: 110px;
    padding-bottom: 130px;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container {
    position: relative;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_title {
    max-width: calc(876px + 80px);
    margin: 0 auto;
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.03px;
    line-height: 32px;
    margin-bottom: 80px;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image {
    float: left;
    position: relative;
    height: 100%;
    width: 100%;
    margin-left: 0px;
    box-shadow: none;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image .wrap {
    height: 100%;
    overflow: hidden;
    width: 100%;
    margin-bottom: 40px;
    flex-direction: column;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image .wrap img {
    height: auto;
    width: 70%;
    float: right;
    box-shadow: none;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image .wrap .image2 {
    position: relative;
    margin-top: -8%;
    margin-left: 0;
    float: left;
    width: 70%;
    box-shadow: none;
    transform: none;
    top: 0;
    left: 0;
    margin-top: 1%;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image .wrap .image3 {
    position: relative;
    box-shadow: none;
    margin-top: 1%;
    margin-left: 0;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image::after {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image::before {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text {
    float: left;
    width: 100%;
    padding-top: 10px;
    padding-left: 0px;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text strong {
    font-size: 20px;
    font-weight: 700;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text p {
    margin-bottom: 30px;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ul,
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ol {
    list-style: none;
    padding: 0;
    margin: 0;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ul li,
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ol li {
    line-height: 50px;
    padding-left: 20px;
    position: relative;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ul li::before,
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ol li::before {
    width: 7px;
    display: inline-block;
    height: 10px;
    background-image: url(../img/icons/arrowRight-red.svg);
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    width: 244px;
    margin-top: 36px;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link:hover {
    color: #fff;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link:hover::after {
    top: -100%;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link:hover::before {
    top: -120%;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link:hover span img {
    right: -2px;
    opacity: .8;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

@media (max-width: 767.98px) {
  .subPage_textBox_imageLeft_inGrid-6-5 {
    float: left;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container {
    position: relative;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_title {
    max-width: calc(876px + 80px);
    margin: 0 auto;
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.03px;
    line-height: 32px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 26px;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image {
    float: left;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    position: relative;
    height: 100%;
    width: 100%;
    margin-left: 0px;
    height: 350px;
    text-align: center;
    margin-bottom: 0px;
    background-color: transparent;
    float: left;
    position: relative;
    height: 100%;
    width: 100%;
    margin-left: 0px;
    box-shadow: none;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image .wrap {
    height: 100%;
    overflow: hidden;
    width: 100%;
    margin-bottom: 40px;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image .wrap img {
    height: auto;
    width: 100%;
    float: right;
    box-shadow: none;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image .wrap .image2 {
    position: relative;
    margin-top: 30px;
    margin-left: 0;
    float: left;
    width: 100%;
    box-shadow: none;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image .wrap .image3 {
    position: relative;
    margin-top: 30px;
    margin-left: 0%;
    box-shadow: none;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image::after {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_image::before {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text {
    float: left;
    width: 100%;
    padding-top: 10px;
    padding-left: 0px;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text strong {
    font-size: 16px;
    font-weight: 700;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text p {
    margin-bottom: 30px;
    float: left;
    text-align: center;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ul,
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ol {
    list-style: none;
    padding: 0;
    margin: 0;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ul li,
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ol li {
    line-height: 50px;
    padding-left: 20px;
    position: relative;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ul li::before,
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text ol li::before {
    width: 7px;
    display: inline-block;
    height: 10px;
    background-image: url(../img/icons/arrowRight-red.svg);
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    margin: 0 auto;
    width: 244px;
    margin-top: 16px;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link:hover {
    color: #fff;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link:hover::after {
    top: -100%;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link:hover::before {
    top: -120%;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link:hover span img {
    right: -2px;
    opacity: .8;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .subPage_textBox_imageLeft_inGrid-6-5 .container .subPage_textBox_imageLeft_inGrid-6-5_content .subPage_textBox_imageLeft_inGrid-6-5_content_wrap .subPage_textBox_imageLeft_inGrid-6-5_content_text .item_link span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

.subPage_textBox_imageLeft_inGrid-7-5 {
  float: left;
  width: 100%;
  padding-top: 110px;
  padding-bottom: 130px;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container {
  position: relative;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_title {
  max-width: calc(876px + 80px);
  margin: 0 auto;
  color: #333333;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 32px;
  margin-bottom: 80px;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_image {
  float: left;
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
  position: relative;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_image .wrap img {
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_image::after {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #fff;
  filter: blur(2px);
  content: '';
  position: absolute;
  transform: scale(0.8);
  right: 20px;
  bottom: 20px;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_image::before {
  display: block;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right: 100px solid #ab0000;
  filter: blur(2px);
  content: '';
  position: absolute;
  transform: scale(0.6);
  left: -100px;
  top: 50px;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text {
  float: left;
  width: 100%;
  padding-top: 10px;
  padding-left: 30px;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text strong {
  font-size: 20px;
  font-weight: 700;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text p {
  margin-bottom: 30px;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ul, .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ol {
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ul li, .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ol li {
  line-height: 50px;
  padding-left: 20px;
  position: relative;
  float: left;
  width: 100%;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ul li::before, .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ol li::before {
  width: 7px;
  display: inline-block;
  height: 10px;
  background-image: url(../img/icons/arrowRight-red.svg);
  content: '';
  position: absolute;
  left: 0;
  top: 20px;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
  width: 244px;
  margin-top: 36px;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link:hover {
  color: #fff;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link:hover::after {
  top: -100%;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link:hover::before {
  top: -120%;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link:hover span img {
  right: -2px;
  opacity: .8;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

@media (max-width: 991.98px) {
  .subPage_textBox_imageLeft_inGrid-7-5 {
    float: left;
    width: 100%;
    padding-top: 110px;
    padding-bottom: 130px;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container {
    position: relative;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_title {
    max-width: calc(876px + 80px);
    margin: 0 auto;
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.03px;
    line-height: 32px;
    margin-bottom: 80px;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_image {
    float: left;
    background-color: transparent;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    position: relative;
    height: auto;
    width: 100%;
    margin-bottom: 30px;
    margin-left: 0px;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_image .wrap {
    height: 100%;
    overflow: hidden;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_image .wrap img {
    height: auto;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_image::after {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_image::before {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text {
    float: left;
    width: 100%;
    padding-top: 10px;
    padding-left: 30px;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text strong {
    font-size: 20px;
    font-weight: 700;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text p {
    margin-bottom: 30px;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ul,
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ol {
    list-style: none;
    padding: 0;
    margin: 0;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ul li,
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ol li {
    line-height: 50px;
    padding-left: 20px;
    position: relative;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ul li::before,
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ol li::before {
    width: 7px;
    display: inline-block;
    height: 10px;
    background-image: url(../img/icons/arrowRight-red.svg);
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    width: 244px;
    margin-top: 0px;
    margin: 0 auto;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link:hover {
    color: #fff;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link:hover::after {
    top: -100%;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link:hover::before {
    top: -120%;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link:hover span img {
    right: -2px;
    opacity: .8;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

@media (max-width: 767.98px) {
  .subPage_textBox_imageLeft_inGrid-7-5 {
    float: left;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container {
    position: relative;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_title {
    max-width: calc(876px + 80px);
    margin: 0 auto;
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.03px;
    line-height: 32px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 26px;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_image {
    float: left;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    position: relative;
    height: 100%;
    width: 100%;
    margin-left: 0px;
    height: auto;
    text-align: center;
    margin-bottom: 30px;
    background-color: transparent;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_image .wrap {
    height: 100%;
    overflow: hidden;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_image .wrap img {
    height: auto;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_image::after {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_image::before {
    display: none;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text {
    float: left;
    width: 100%;
    padding-top: 10px;
    padding-left: 0px;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text strong {
    font-size: 16px;
    font-weight: 700;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text p {
    margin-bottom: 30px;
    float: left;
    text-align: center;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ul,
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ol {
    list-style: none;
    padding: 0;
    margin: 0;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ul li,
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ol li {
    line-height: 50px;
    padding-left: 20px;
    position: relative;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ul li::before,
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text ol li::before {
    width: 7px;
    display: inline-block;
    height: 10px;
    background-image: url(../img/icons/arrowRight-red.svg);
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    margin: 0 auto;
    width: 244px;
    margin-top: 16px;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link:hover {
    color: #fff;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link:hover::after {
    top: -100%;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link:hover::before {
    top: -120%;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link:hover span img {
    right: -2px;
    opacity: .8;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .subPage_textBox_imageLeft_inGrid-7-5 .container .subPage_textBox_imageLeft_inGrid-7-5_content .subPage_textBox_imageLeft_inGrid-7-5_content_wrap .subPage_textBox_imageLeft_inGrid-7-5_content_text .item_link span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

.subPage_textBox_imageRight_inGrid-6-6 {
  float: left;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 130px;
}

.subPage_textBox_imageRight_inGrid-6-6 .container {
  position: relative;
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_title {
  max-width: calc(876px + 80px);
  margin: 0 auto;
  color: #333333;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 32px;
  margin-bottom: 80px;
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_image {
  float: left;
  position: relative;
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_image .wrap {
  position: relative;
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_image .wrap img {
  width: 100%;
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text {
  float: left;
  width: 100%;
  padding-top: 10px;
  padding-left: 30px;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text strong {
  font-size: 16px;
  font-weight: 700;
  color: #ab0000;
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text p {
  margin-bottom: 30px;
  float: left;
  width: 100%;
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ul, .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ol {
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
  width: 100%;
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ul li, .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ol li {
  line-height: 50px;
  padding-left: 20px;
  position: relative;
  float: left;
  width: 100%;
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ul li::before, .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ol li::before {
  width: 7px;
  display: inline-block;
  height: 10px;
  background-image: url(../img/icons/arrowRight-red.svg);
  content: '';
  position: absolute;
  left: 0;
  top: 20px;
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
  width: 244px;
  margin-top: 36px;
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link:hover {
  color: #fff;
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link:hover::after {
  top: -100%;
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link:hover::before {
  top: -120%;
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link:hover span img {
  right: -2px;
  opacity: .8;
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

@media (max-width: 991.98px) {
  .subPage_textBox_imageRight_inGrid-6-6 {
    float: left;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 70px;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container {
    position: relative;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_image {
    float: left;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_image .wrap img {
    max-width: 100%;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_image::after {
    display: none;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_image::before {
    display: none;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text {
    float: left;
    width: 100%;
    padding: 0;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ul,
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ol {
    list-style: none;
    padding: 0;
    margin: 0;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ul li,
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ol li {
    line-height: 50px;
    padding-left: 20px;
    position: relative;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ul li::before,
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ol li::before {
    width: 7px;
    display: inline-block;
    height: 10px;
    background-image: url(../img/icons/arrowRight-red.svg);
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    width: 244px;
    margin-top: 36px;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link:hover {
    color: #fff;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link:hover::after {
    top: -100%;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link:hover::before {
    top: -120%;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link:hover span img {
    right: -2px;
    opacity: .8;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

@media (max-width: 767.98px) {
  .subPage_textBox_imageRight_inGrid-6-6 {
    float: left;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 60px;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container {
    position: relative;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_title {
    max-width: calc(876px + 80px);
    margin: 0 auto;
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.03px;
    line-height: 32px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 26px;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_image {
    float: left;
    position: relative;
    height: 100%;
    width: 100%;
    margin-left: 0px;
    text-align: center;
    margin-bottom: 30px;
    background-color: transparent;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text {
    float: left;
    width: 100%;
    padding-top: 10px;
    padding-left: 0px;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text strong {
    font-weight: 400;
    color: #333333;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text p {
    margin-bottom: 30px;
    float: left;
    text-align: center;
    width: 100%;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ul,
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ol {
    list-style: none;
    padding: 0;
    margin: 0;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ul li,
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ol li {
    line-height: 50px;
    padding-left: 20px;
    position: relative;
    float: left;
    width: 100%;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ul li::before,
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text ol li::before {
    width: 7px;
    display: inline-block;
    height: 10px;
    background-image: url(../img/icons/arrowRight-red.svg);
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    margin: 0 auto;
    width: 244px;
    margin-top: 16px;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link:hover {
    color: #fff;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link:hover::after {
    top: -100%;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link:hover::before {
    top: -120%;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link:hover span img {
    right: -2px;
    opacity: .8;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .subPage_textBox_imageRight_inGrid-6-6 .container .subPage_textBox_imageRight_inGrid-6-6_content .subPage_textBox_imageRight_inGrid-6-6_content_wrap .subPage_textBox_imageRight_inGrid-6-6_content_text .item_link span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

.subPage_containerBox {
  float: left;
  width: 100%;
  padding-bottom: 100px;
}

.subPage_containerBox .container .containerBox .column {
  padding-left: 22px;
  padding-right: 22px;
}

.subPage_containerBox .container .containerBox .containerBox_item {
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
  margin-bottom: 45px;
  cursor: pointer;
  position: relative;
  top: 0;
  transition-duration: .2s;
}

.subPage_containerBox .container .containerBox .containerBox_item .containerBox_item_image {
  width: 133px;
}

.subPage_containerBox .container .containerBox .containerBox_item .containerBox_item_image img {
  width: 100%;
}

.subPage_containerBox .container .containerBox .containerBox_item .containerBox_item_text {
  width: calc(100% - 133px);
  padding-left: 25px;
  color: #333333;
  font-size: 16px;
  font-weight: 900;
  line-height: 32px;
}

.subPage_containerBox .container .containerBox .containerBox_item:hover {
  box-shadow: 0 10px 70px rgba(0, 0, 0, 0.5);
  top: -5px;
}

.subPage_containerBox .container .containerBox .containerBox_item_popup {
  max-width: 460px;
  width: 100%;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  background-color: #820000;
  position: fixed;
  top: 30%;
  left: 50%;
  margin-left: -230px;
  padding: 20px 36px 49px 36px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  z-index: 99999999;
  line-height: 32px;
  cursor: default;
  opacity: 0;
  pointer-events: none;
  transform: scale(0);
  max-height: 80vh;
  overflow: auto;
  transition-duration: .3s;
}

.subPage_containerBox .container .containerBox .containerBox_item_popup .popup-close {
  float: left;
  width: 100%;
  margin-bottom: 0px;
}

.subPage_containerBox .container .containerBox .containerBox_item_popup .popup-close .wrap {
  float: right;
  position: relative;
  right: -36px;
  top: -20px;
  padding: 20px;
  cursor: pointer;
}

.subPage_containerBox .container .containerBox .containerBox_item_popup .popup-close .wrap img {
  width: 15px;
}

.subPage_containerBox .container .containerBox .containerBox_item_popup strong {
  font-weight: 700;
}

.subPage_containerBox .container .containerBox .containerBox_item_popup p {
  margin-bottom: 30px;
}

.subPage_containerBox .container .containerBox .containerBox_item_popup p:last-child {
  margin-bottom: 0;
}

.subPage_containerBox .container .containerBox .containerBox_item_popup.containerBox_item_popup--active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

@media (max-width: 991.98px) {
  .subPage_containerBox {
    float: left;
    width: 100%;
    padding-bottom: 60px;
  }
  .subPage_containerBox .container .containerBox .column {
    padding-left: 10px;
    padding-right: 10px;
  }
  .subPage_containerBox .container .containerBox .containerBox_item {
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    top: 0;
    transition-duration: .2s;
  }
  .subPage_containerBox .container .containerBox .containerBox_item .containerBox_item_image {
    width: 133px;
  }
  .subPage_containerBox .container .containerBox .containerBox_item .containerBox_item_image img {
    width: 100%;
  }
  .subPage_containerBox .container .containerBox .containerBox_item .containerBox_item_text {
    width: calc(100% - 133px);
    padding-left: 25px;
    color: #333333;
    font-size: 16px;
    font-weight: 900;
    line-height: 32px;
  }
  .subPage_containerBox .container .containerBox .containerBox_item:hover {
    box-shadow: 0 10px 70px rgba(0, 0, 0, 0.5);
    top: -5px;
  }
  .subPage_containerBox .container .containerBox .containerBox_item_popup {
    max-width: 460px;
    width: 100%;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    background-color: #820000;
    position: fixed;
    top: 0px;
    margin-top: 40px;
    left: 50%;
    margin-left: -230px;
    padding: 20px 36px 49px 36px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    z-index: 99999999;
    line-height: 32px;
    cursor: default;
    opacity: 0;
    pointer-events: none;
    transform: scale(0);
    transition-duration: .3s;
  }
  .subPage_containerBox .container .containerBox .containerBox_item_popup .popup-close {
    float: left;
    width: 100%;
    margin-bottom: 0px;
  }
  .subPage_containerBox .container .containerBox .containerBox_item_popup .popup-close .wrap {
    float: right;
    position: relative;
    right: -36px;
    top: -20px;
    padding: 20px;
    cursor: pointer;
  }
  .subPage_containerBox .container .containerBox .containerBox_item_popup .popup-close .wrap img {
    width: 15px;
  }
  .subPage_containerBox .container .containerBox .containerBox_item_popup strong {
    font-weight: 700;
  }
  .subPage_containerBox .container .containerBox .containerBox_item_popup p {
    margin-bottom: 30px;
  }
  .subPage_containerBox .container .containerBox .containerBox_item_popup p:last-child {
    margin-bottom: 0;
  }
  .subPage_containerBox .container .containerBox .containerBox_item_popup.containerBox_item_popup--active {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
  }
}

@media (max-width: 767.98px) {
  .subPage_containerBox {
    float: left;
    width: 100%;
    padding-bottom: 60px;
  }
  .subPage_containerBox .container .containerBox .column {
    padding-left: 10px;
    padding-right: 10px;
  }
  .subPage_containerBox .container .containerBox .containerBox_item {
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    top: 0;
    transition-duration: .2s;
  }
  .subPage_containerBox .container .containerBox .containerBox_item .containerBox_item_image {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .subPage_containerBox .container .containerBox .containerBox_item .containerBox_item_image img {
    width: 133px;
  }
  .subPage_containerBox .container .containerBox .containerBox_item .containerBox_item_text {
    width: 100%;
    padding-left: 0;
    color: #333333;
    font-size: 16px;
    font-weight: 900;
    line-height: 32px;
    text-align: center;
  }
  .subPage_containerBox .container .containerBox .containerBox_item:hover {
    box-shadow: 0 10px 70px rgba(0, 0, 0, 0.5);
    top: -5px;
  }
  .subPage_containerBox .container .containerBox .containerBox_item_popup {
    max-width: 460px;
    width: 100%;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    background-color: #820000;
    position: fixed;
    top: 0px;
    margin-top: 0px;
    left: 0%;
    margin-left: 0px;
    padding: 20px 36px 49px 36px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    z-index: 99999999;
    line-height: 32px;
    cursor: default;
    opacity: 0;
    pointer-events: none;
    transform: scale(0);
    transition-duration: .3s;
    max-height: 100vh;
    height: 100vh;
  }
  .subPage_containerBox .container .containerBox .containerBox_item_popup .popup-close {
    float: left;
    width: 100%;
    margin-bottom: 0px;
  }
  .subPage_containerBox .container .containerBox .containerBox_item_popup .popup-close .wrap {
    float: right;
    position: relative;
    right: -36px;
    top: -20px;
    padding: 20px;
    cursor: pointer;
  }
  .subPage_containerBox .container .containerBox .containerBox_item_popup .popup-close .wrap img {
    width: 15px;
  }
  .subPage_containerBox .container .containerBox .containerBox_item_popup strong {
    font-weight: 700;
  }
  .subPage_containerBox .container .containerBox .containerBox_item_popup p {
    margin-bottom: 30px;
  }
  .subPage_containerBox .container .containerBox .containerBox_item_popup p:last-child {
    margin-bottom: 0;
  }
  .subPage_containerBox .container .containerBox .containerBox_item_popup.containerBox_item_popup--active {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
  }
}

.mainPage_ourPassion {
  float: left;
  width: 100%;
  padding-bottom: 200px;
}

.mainPage_ourPassion .container {
  position: relative;
}

.mainPage_ourPassion .container .ourPassion_wrap {
  float: left;
  width: 100%;
  position: relative;
}

.mainPage_ourPassion .container .ourPassion {
  max-width: 530px;
  height: 513px;
  float: left;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_title {
  color: #333333;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 40px;
  letter-spacing: -1.2px;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_description {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 65px;
  line-height: 32px;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
  max-width: 244px;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover {
  color: #fff;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover::after {
  top: -100%;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover::before {
  top: -120%;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover span img {
  right: -2px;
  opacity: .8;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.mainPage_ourPassion .container .ourPassion .ourPassion_link a span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

.mainPage_ourPassion .container .ourPassion_images {
  float: right;
  height: 100%;
}

.mainPage_ourPassion .container .ourPassion_images::after {
  display: block;
  position: absolute;
  right: 2vw;
  bottom: 10vh;
  content: '';
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;
  z-index: 1;
  background-image: url(../img/bg/4.png);
}

.mainPage_ourPassion .container .ourPassion_images img {
  position: absolute;
  top: 0;
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
  right: 0;
}

.mainPage_ourPassion .container .ourPassion_images img:nth-child(2) {
  right: 15vw;
  top: 15vh;
}

.mainPage_ourPassion .container .ourPassion_images img:nth-child(3) {
  right: 30vw;
  bottom: 0;
  top: auto;
}

@media (max-width: 1300px) {
  .mainPage_ourPassion .container .ourPassion_images img:nth-child(2) {
    right: 10vw;
  }
}

@media (max-width: 1200px) {
  .mainPage_ourPassion .container .ourPassion_images img {
    width: 300px;
  }
  .mainPage_ourPassion .container .ourPassion_images img:nth-child(2) {
    right: 14vw;
    top: 22vh;
  }
  .mainPage_ourPassion .container .ourPassion_images img:nth-child(3) {
    right: 30vw;
    bottom: 6vw;
  }
}

@media (max-width: 991.98px) {
  .mainPage_ourPassion {
    float: left;
    width: 100%;
    padding-bottom: 200px;
  }
  .mainPage_ourPassion .container {
    position: relative;
  }
  .mainPage_ourPassion .container .ourPassion_wrap {
    float: left;
    width: 100%;
    position: relative;
  }
  .mainPage_ourPassion .container .ourPassion {
    max-width: 530px;
    height: auto;
    float: none;
    margin: 0 auto;
    text-align: center;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_title {
    color: #333333;
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 40px;
    text-align: center;
    letter-spacing: -1.2px;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_description {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 65px;
    text-align: center;
    line-height: 32px;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    max-width: 244px;
    display: inline-block;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover {
    color: #fff;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover::after {
    top: -100%;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover::before {
    top: -120%;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover span img {
    right: -2px;
    opacity: .8;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
  .mainPage_ourPassion .container .ourPassion_images {
    float: left;
    height: 100%;
    width: 100%;
    position: relative;
    height: 70vw;
    margin-top: 80px;
  }
  .mainPage_ourPassion .container .ourPassion_images::after {
    display: block;
    position: absolute;
    right: 2vw;
    bottom: 0vh;
    content: '';
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom right;
    pointer-events: none;
    z-index: 1;
    background-image: url(../img/bg/4.png);
  }
  .mainPage_ourPassion .container .ourPassion_images img {
    position: absolute;
    top: 0;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    right: 0;
    width: 60vw;
  }
  .mainPage_ourPassion .container .ourPassion_images img:nth-child(2) {
    right: 19vw;
    top: 15vh;
  }
  .mainPage_ourPassion .container .ourPassion_images img:nth-child(3) {
    right: auto;
    bottom: 0;
    left: 0px;
    top: auto;
  }
}

@media (max-width: 767.98px) {
  .mainPage_ourPassion {
    float: left;
    width: 100%;
    padding-bottom: 80px;
  }
  .mainPage_ourPassion .container {
    position: relative;
  }
  .mainPage_ourPassion .container .ourPassion_wrap {
    float: left;
    width: 100%;
    position: relative;
  }
  .mainPage_ourPassion .container .ourPassion {
    max-width: 530px;
    height: auto;
    float: none;
    margin: 0 auto;
    text-align: center;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_title {
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.8px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_description {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 30px;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    max-width: 244px;
    display: inline-block;
    font-size: 12px;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover {
    color: #fff;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover::after {
    top: -100%;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover::before {
    top: -120%;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a:hover span img {
    right: -2px;
    opacity: .8;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .mainPage_ourPassion .container .ourPassion .ourPassion_link a span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
  .mainPage_ourPassion .container .ourPassion_images {
    float: left;
    height: 100%;
    width: 100%;
    position: relative;
    height: auto;
    margin-top: 50px;
  }
  .mainPage_ourPassion .container .ourPassion_images::after {
    display: none;
  }
  .mainPage_ourPassion .container .ourPassion_images img {
    position: relative;
    top: auto;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
    right: auto;
    width: 100%;
    margin-bottom: 20px;
  }
  .mainPage_ourPassion .container .ourPassion_images img:nth-child(2) {
    right: auto;
    bottom: auto;
    left: auto;
    top: auto;
  }
  .mainPage_ourPassion .container .ourPassion_images img:nth-child(3) {
    right: auto;
    bottom: auto;
    left: auto;
    top: auto;
  }
}

.subPage_workOffer {
  float: none;
  width: 100%;
  max-width: 876px;
  margin: 0 auto;
}

.subPage_workOffer .workOffer_title {
  color: #333333;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -1.2px;
  line-height: 36px;
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 75px;
}

.subPage_workOffer .workOffer_wrap {
  float: left;
  width: 100%;
}

.subPage_workOffer .workOffer_wrap .workOffer_item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 75px;
  align-items: center;
}

.subPage_workOffer .workOffer_wrap .workOffer_item .item_content .item_content_title {
  color: #ab0000;
  font-size: 20px;
  font-weight: 700;
}

.subPage_workOffer .workOffer_wrap .workOffer_item .item_content .item_content_date {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  line-height: 40px;
}

.subPage_workOffer .workOffer_wrap .workOffer_item .item_link {
  margin-left: auto;
  max-width: 244px;
  width: 100%;
}

.subPage_workOffer .workOffer_wrap .workOffer_item .item_link a {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ab0000;
  color: #ab0000;
  font-size: 12px;
  font-weight: 900;
}

.subPage_workOffer .workOffer_wrap .workOffer_item .item_link a::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.subPage_workOffer .workOffer_wrap .workOffer_item .item_link a::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.subPage_workOffer .workOffer_wrap .workOffer_item .item_link a:hover {
  color: #fff;
}

.subPage_workOffer .workOffer_wrap .workOffer_item .item_link a:hover::after {
  top: -100%;
}

.subPage_workOffer .workOffer_wrap .workOffer_item .item_link a:hover::before {
  top: -120%;
}

.subPage_workOffer .workOffer_wrap .workOffer_item .item_link a:hover span img {
  right: -2px;
  opacity: .8;
}

.subPage_workOffer .workOffer_wrap .workOffer_item .item_link a span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.subPage_workOffer .workOffer_wrap .workOffer_item .item_link a span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

@media (max-width: 991.98px) {
  .subPage_workOffer {
    float: none;
    width: 100%;
    max-width: 876px;
    margin: 0 auto;
  }
  .subPage_workOffer .workOffer_title {
    color: #333333;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -1.2px;
    line-height: 36px;
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 75px;
  }
  .subPage_workOffer .workOffer_wrap {
    float: left;
    width: 100%;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 75px;
    align-items: center;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_content {
    width: 100%;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_content .item_content_title {
    color: #ab0000;
    font-size: 20px;
    font-weight: 700;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_content .item_content_date {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    margin-top: 0px;
    line-height: 40px;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link {
    margin-left: auto;
    max-width: 100%;
    width: 100%;
    margin-top: 30px;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ab0000;
    color: #ab0000;
    max-width: 244px;
    font-size: 12px;
    font-weight: 900;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a:hover {
    color: #fff;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a:hover::after {
    top: -100%;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a:hover::before {
    top: -120%;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a:hover span img {
    right: -2px;
    opacity: .8;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

@media (max-width: 767.98px) {
  .subPage_workOffer {
    float: none;
    width: 100%;
    max-width: 876px;
    margin: 0 auto;
  }
  .subPage_workOffer .workOffer_title {
    color: #333333;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -1.2px;
    line-height: 36px;
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 75px;
  }
  .subPage_workOffer .workOffer_wrap {
    float: left;
    width: 100%;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 75px;
    align-items: center;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_content {
    width: 100%;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_content .item_content_title {
    color: #ab0000;
    font-size: 20px;
    font-weight: 700;
    font-size: 16px;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_content .item_content_date {
    color: #333333;
    font-weight: 400;
    margin-top: 0px;
    font-size: 16px;
    line-height: 40px;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link {
    margin-left: auto;
    max-width: 100%;
    width: 100%;
    margin-top: 30px;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ab0000;
    color: #ab0000;
    max-width: 244px;
    font-size: 12px;
    font-weight: 900;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a:hover {
    color: #fff;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a:hover::after {
    top: -100%;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a:hover::before {
    top: -120%;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a:hover span img {
    right: -2px;
    opacity: .8;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .subPage_workOffer .workOffer_wrap .workOffer_item .item_link a span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

.subPage_workOffer_single {
  float: none;
  width: 100%;
  max-width: 876px;
  margin: 0 auto;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 32px;
}

.subPage_workOffer_single .subPage_workOffer_single_wrap {
  padding-top: 110px;
  padding-bottom: 90px;
  float: left;
  width: 100%;
}

.subPage_workOffer_single *:last-child {
  margin-bottom: 0px;
}

.subPage_workOffer_single p {
  margin-bottom: 40px;
}

.subPage_workOffer_single ul,
.subPage_workOffer_single ol {
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
  width: 100%;
  margin-bottom: 40px;
}

.subPage_workOffer_single ul li,
.subPage_workOffer_single ol li {
  line-height: 50px;
  padding-left: 20px;
  position: relative;
  float: left;
  width: 100%;
}

.subPage_workOffer_single ul li::before,
.subPage_workOffer_single ol li::before {
  width: 7px;
  display: inline-block;
  height: 10px;
  background-image: url(../img/icons/arrowRight-red.svg);
  content: '';
  position: absolute;
  left: 0;
  top: 20px;
}

.subPage_workOffer_single ul li:last-child,
.subPage_workOffer_single ol li:last-child {
  margin-left: 0px;
}

.subPage_workOffer_single ul li ul li::before, .subPage_workOffer_single ul li ol li::before,
.subPage_workOffer_single ol li ul li::before,
.subPage_workOffer_single ol li ol li::before {
  display: none;
}

.subPage_workOffer_single img {
  max-width: 100%;
  margin-bottom: 40px;
  height: auto;
  margin-top: 10px;
}

.subPage_workOffer_single .wrap_bottom .title {
  color: #333333;
  font-size: 16px;
  letter-spacing: -0.03px;
  line-height: 50px;
  font-weight: 700;
}

.subPage_workOffer_single .wrap_bottom .contact {
  color: #333333;
  font-weight: 400;
  letter-spacing: -0.03px;
}

.subPage_workOffer_single .wrap_bottom .contact .contact_item {
  margin-right: 30px;
  float: left;
  line-height: 50px;
}

.subPage_workOffer_single .wrap_bottom .contact .contact_item a {
  color: #333333;
}

.subPage_workOffer_single .wrap_bottom .contact .contact_item img {
  width: 26px;
  margin-bottom: 0;
  margin-top: 0;
  margin-right: 10px;
}

.single_post_news_back {
  float: left;
  width: 100%;
}

.single_post_news_back a {
  color: #ab0000;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.56px;
  line-height: 25px;
  display: block;
  margin-top: 50px;
  transition-duration: .2s;
  text-decoration: none;
}

.single_post_news_back a:hover {
  color: #820000;
}

@media (max-width: 767.98px) {
  .subPage_workOffer_single {
    float: none;
    width: 100%;
    max-width: 876px;
    margin: 0 auto;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.03px;
    line-height: 32px;
  }
  .subPage_workOffer_single .subPage_workOffer_single_wrap {
    padding-top: 45px;
    padding-bottom: 80px;
    float: left;
    width: 100%;
    text-align: center;
  }
  .subPage_workOffer_single *:last-child {
    margin-bottom: 0px;
  }
  .subPage_workOffer_single p {
    margin-bottom: 40px;
  }
  .subPage_workOffer_single ul,
  .subPage_workOffer_single ol {
    text-align: left;
    list-style: none;
    padding: 0;
    margin: 0;
    float: left;
    width: 100%;
    margin-bottom: 40px;
  }
  .subPage_workOffer_single ul li,
  .subPage_workOffer_single ol li {
    line-height: 50px;
    padding-left: 20px;
    position: relative;
    float: left;
    width: 100%;
  }
  .subPage_workOffer_single ul li::before,
  .subPage_workOffer_single ol li::before {
    width: 7px;
    display: inline-block;
    height: 10px;
    background-image: url(../img/icons/arrowRight-red.svg);
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
  }
  .subPage_workOffer_single ul li:last-child,
  .subPage_workOffer_single ol li:last-child {
    margin-left: 0px;
  }
  .subPage_workOffer_single ul li ul li::before,
  .subPage_workOffer_single ul li ol li::before,
  .subPage_workOffer_single ol li ul li::before,
  .subPage_workOffer_single ol li ol li::before {
    display: none;
  }
  .subPage_workOffer_single img {
    max-width: 100%;
    margin-bottom: 40px;
    height: auto;
    margin-top: 10px;
  }
  .subPage_workOffer_single .wrap_bottom .title {
    color: #333333;
    font-size: 16px;
    letter-spacing: -0.03px;
    line-height: 32px;
    font-weight: 700;
  }
  .subPage_workOffer_single .wrap_bottom .contact {
    color: #333333;
    font-weight: 400;
    letter-spacing: -0.03px;
  }
  .subPage_workOffer_single .wrap_bottom .contact .contact_item {
    margin-right: 30px;
    float: left;
    line-height: 50px;
    text-align: center;
    width: 100%;
  }
  .subPage_workOffer_single .wrap_bottom .contact .contact_item a {
    color: #333333;
  }
  .subPage_workOffer_single .wrap_bottom .contact .contact_item img {
    width: 26px;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 10px;
  }
  .single_post_news_back {
    float: left;
    width: 100%;
  }
  .single_post_news_back a {
    color: #ab0000;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.56px;
    line-height: 25px;
    display: block;
    margin-top: 50px;
    transition-duration: .2s;
    text-decoration: none;
  }
  .single_post_news_back a:hover {
    color: #820000;
  }
}

.subPage_blankPage {
  float: left;
  width: 100%;
  padding-bottom: 150px;
  padding-top: 110px;
}

.subPage_blankPage .container .blankPage_content {
  max-width: 876px;
  margin: 0 auto;
}

.subPage_blankPage .container .blankPage_content *:last-child {
  margin-bottom: 0px;
}

.subPage_blankPage .container .blankPage_content p {
  margin-bottom: 40px;
}

.subPage_blankPage .container .blankPage_content ul,
.subPage_blankPage .container .blankPage_content ol {
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
  width: 100%;
  margin-bottom: 40px;
}

.subPage_blankPage .container .blankPage_content ul li,
.subPage_blankPage .container .blankPage_content ol li {
  padding-left: 20px;
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.subPage_blankPage .container .blankPage_content ul li::before,
.subPage_blankPage .container .blankPage_content ol li::before {
  width: 7px;
  display: inline-block;
  height: 10px;
  background-image: url(../img/icons/arrowRight-red.svg);
  content: '';
  position: absolute;
  left: 0;
  top: 7px;
}

.subPage_blankPage .container .blankPage_content ul li:last-child,
.subPage_blankPage .container .blankPage_content ol li:last-child {
  margin-left: 0px;
}

.subPage_blankPage .container .blankPage_content ul li ul li::before,
.subPage_blankPage .container .blankPage_content ul li ol li::before,
.subPage_blankPage .container .blankPage_content ol li ul li::before,
.subPage_blankPage .container .blankPage_content ol li ol li::before {
  display: none;
}

.subPage_blankPage .container .blankPage_content img {
  max-width: 100%;
  margin-bottom: 40px;
  height: auto;
  margin-top: 10px;
}

@media (max-width: 991.98px) {
  .subPage_blankPage {
    float: left;
    width: 100%;
    padding-bottom: 150px;
    padding-top: 110px;
  }
}

@media (max-width: 767.98px) {
  .subPage_blankPage {
    float: left;
    width: 100%;
    padding-bottom: 100px;
    padding-top: 45px;
  }
  .subPage_blankPage .container .blankPage_content {
    margin: 0 auto;
    text-align: center;
  }
  .subPage_blankPage .container .blankPage_content *:last-child {
    margin-bottom: 0px;
  }
  .subPage_blankPage .container .blankPage_content p {
    margin-bottom: 40px;
  }
  .subPage_blankPage .container .blankPage_content ul,
  .subPage_blankPage .container .blankPage_content ol {
    text-align: left;
    list-style: none;
    padding: 0;
    margin: 0;
    float: left;
    width: 100%;
    margin-bottom: 40px;
  }
  .subPage_blankPage .container .blankPage_content ul li,
  .subPage_blankPage .container .blankPage_content ol li {
    padding-left: 20px;
    position: relative;
    float: left;
    width: 100%;
  }
  .subPage_blankPage .container .blankPage_content ul li::before,
  .subPage_blankPage .container .blankPage_content ol li::before {
    width: 7px;
    display: inline-block;
    height: 10px;
    background-image: url(../img/icons/arrowRight-red.svg);
    content: '';
    position: absolute;
    left: 0;
  }
  .subPage_blankPage .container .blankPage_content ul li:last-child,
  .subPage_blankPage .container .blankPage_content ol li:last-child {
    margin-left: 0px;
  }
  .subPage_blankPage .container .blankPage_content ul li ul li::before,
  .subPage_blankPage .container .blankPage_content ul li ol li::before,
  .subPage_blankPage .container .blankPage_content ol li ul li::before,
  .subPage_blankPage .container .blankPage_content ol li ol li::before {
    display: none;
  }
  .subPage_blankPage .container .blankPage_content img {
    max-width: 100%;
    margin-bottom: 40px;
    height: auto;
    margin-top: 10px;
  }
}

.subPage_imageSlider {
  float: left;
  width: 100%;
}

.subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_title {
  color: #333333;
  letter-spacing: -0.03px;
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
}

.subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_date {
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
}

.subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_slider {
  float: left;
  width: 100%;
}

.subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_slider .slick-list {
  width: 140%;
  margin-left: -62px;
  padding: 40px 0;
  padding-left: 40px;
  float: left;
}

.subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_slider .slick-list .slick-slide {
  opacity: 0;
  transition-duration: .2s;
}

.subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_slider .slick-list .slick-active {
  opacity: 1;
}

.subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_slider .item_slider_wrap {
  padding: 0 22px;
}

.subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_slider .item_slider_wrap img {
  max-width: 100%;
  width: 100%;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
}

.subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .imageSlider_item_pagination {
  float: left;
  width: 100%;
  text-align: right;
  margin-bottom: 80px;
}

.subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .imageSlider_item_pagination .arrowLeft,
.subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .imageSlider_item_pagination .arrowRight {
  display: inline-block;
  cursor: pointer;
}

.subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .imageSlider_item_pagination .arrowLeft img,
.subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .imageSlider_item_pagination .arrowRight img {
  width: 15px;
}

.subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .imageSlider_item_pagination .arrowRight {
  margin-left: 25px;
  margin-right: 4px;
}

@media (max-width: 991.98px) {
  .subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_slider .slick-list {
    width: 100%;
    margin-left: 0;
    padding: 0px 0;
    padding-left: 0px;
    padding-left: 0px;
  }
  .subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_slider .item_slider_wrap {
    padding: 0;
  }
  .subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_slider .item_slider_wrap img {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .imageSlider_item_pagination {
    margin-bottom: 50px;
  }
  .subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_title {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.03px;
    line-height: 30px;
    font-weight: 700;
  }
}

@media (max-width: 767.98px) {
  .subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_slider .slick-list {
    width: 100%;
    margin-left: 0;
    padding: 0px 0;
    padding-left: 0px;
    padding-left: 0px;
  }
  .subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_slider .item_slider_wrap {
    padding: 0;
  }
  .subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_slider .item_slider_wrap img {
    width: 100%;
    box-shadow: none;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .imageSlider_item_pagination {
    margin-bottom: 50px;
  }
  .subPage_imageSlider .container .imageSlider_wrap .imageSlider_item .item_title {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.03px;
    line-height: 30px;
    font-weight: 700;
  }
}

.subPage_contact {
  float: left;
  width: 100%;
}

.subPage_contact .container .contact {
  float: left;
  width: 100%;
  padding-top: 110px;
  padding-bottom: 120px;
}

.subPage_contact .container .contact_header {
  max-width: 876px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 32px;
}

.subPage_contact .container .contact_header .contact_header_wrap {
  float: left;
  width: 100%;
  margin-bottom: 60px;
}

.subPage_contact .container .contact_header strong {
  font-weight: 700;
  display: block;
  float: left;
  width: 100%;
}

.subPage_contact .container .contact_header p {
  margin-bottom: 40px;
  display: block;
  float: left;
  width: 100%;
}

.subPage_contact .container .contact_header p:last-child {
  margin-bottom: 0;
}

.subPage_contact .container .contact_header a {
  color: #ab0000;
}

.subPage_contact .container .contact_content {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.subPage_contact .container .contact_content .contact_content_map {
  width: 50%;
  float: left;
  margin-right: -50%;
  height: 630px;
}

.subPage_contact .container .contact_content .contact_content_right {
  max-width: 761px;
  float: right;
  margin-left: auto;
  width: 100%;
  z-index: 1;
  position: relative;
  pointer-events: none;
}

.subPage_contact .container .contact_content .contact_content_right .contact_content_box {
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 50px 50px 50px 70px;
  pointer-events: all;
}

.subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item {
  display: flex;
}

.subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item .box_item_name {
  color: #333333;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 40px;
  width: 220px;
}

.subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item .box_item_phone {
  width: 165px;
  padding-left: 20px;
}

.subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item .box_item_phone a {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 40px;
}

.subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item .box_item_mail {
  width: 172px;
  margin-left: auto;
}

.subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item .box_item_mail a {
  color: #ab0000;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 40px;
}

.subPage_contact .container .contact_content .contact_content_right .contact_content_boxBottom {
  pointer-events: all;
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.subPage_contact .container .contact_content .contact_content_right .contact_content_boxBottom p {
  float: left;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 32px;
  margin: 0 25px;
  margin-bottom: 0;
}

@media (max-width: 1200px) {
  .subPage_contact {
    float: left;
    width: 100%;
  }
  .subPage_contact .container .contact {
    float: left;
    width: 100%;
    padding-top: 110px;
    padding-bottom: 120px;
  }
  .subPage_contact .container .contact_header {
    max-width: 876px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.03px;
    line-height: 32px;
  }
  .subPage_contact .container .contact_header .contact_header_wrap {
    float: left;
    width: 100%;
    margin-bottom: 60px;
  }
  .subPage_contact .container .contact_header strong {
    font-weight: 700;
    display: block;
    float: left;
    width: 100%;
  }
  .subPage_contact .container .contact_header p {
    margin-bottom: 40px;
    display: block;
    float: left;
    width: 100%;
  }
  .subPage_contact .container .contact_header p:last-child {
    margin-bottom: 0;
  }
  .subPage_contact .container .contact_header a {
    color: #ab0000;
  }
  .subPage_contact .container .contact_content {
    float: left;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .subPage_contact .container .contact_content .contact_content_map {
    width: 100%;
    margin-bottom: -30px;
    float: left;
    margin-right: 0px;
    height: 630px;
  }
  .subPage_contact .container .contact_content .contact_content_right {
    max-width: 761px;
    float: none;
    margin-left: auto;
    margin: 0 auto;
    width: 100%;
    z-index: 1;
    position: relative;
    pointer-events: none;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_box {
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 50px 40px 50px 40px;
    pointer-events: all;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item {
    display: flex;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item .box_item_name {
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.03px;
    line-height: 40px;
    width: 220px;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item .box_item_phone {
    width: 165px;
    padding-left: 20px;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item .box_item_phone a {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.03px;
    line-height: 40px;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item .box_item_mail {
    width: 172px;
    margin-left: auto;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item .box_item_mail a {
    color: #ab0000;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.03px;
    line-height: 40px;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_boxBottom {
    pointer-events: all;
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_boxBottom p {
    float: left;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.03px;
    line-height: 32px;
    margin: 0 25px;
    margin-bottom: 0;
  }
}

@media (max-width: 767.98px) {
  .subPage_contact {
    float: left;
    width: 100%;
  }
  .subPage_contact .container .contact {
    float: left;
    width: 100%;
    padding-top: 110px;
    padding-bottom: 120px;
  }
  .subPage_contact .container .contact_header {
    max-width: 876px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.03px;
    line-height: 32px;
  }
  .subPage_contact .container .contact_header .contact_header_wrap {
    float: left;
    width: 100%;
    margin-bottom: 60px;
  }
  .subPage_contact .container .contact_header strong {
    font-weight: 700;
    display: block;
    float: left;
    width: 100%;
  }
  .subPage_contact .container .contact_header p {
    margin-bottom: 40px;
    display: block;
    float: left;
    width: 100%;
  }
  .subPage_contact .container .contact_header p:last-child {
    margin-bottom: 0;
  }
  .subPage_contact .container .contact_header a {
    color: #ab0000;
  }
  .subPage_contact .container .contact_content {
    float: left;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .subPage_contact .container .contact_content .contact_content_map {
    width: 100%;
    margin-bottom: -30px;
    float: left;
    margin-right: 0px;
    height: 280px;
  }
  .subPage_contact .container .contact_content .contact_content_right {
    max-width: auto;
    width: calc(100% - 40px);
    float: none;
    margin-left: 20px;
    z-index: 1;
    position: relative;
    pointer-events: none;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_box {
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 25px;
    pointer-events: all;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item:last-child {
    margin-bottom: 0;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item .box_item_name {
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.03px;
    line-height: 20px;
    width: 100%;
    font-size: 14px;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item .box_item_phone {
    padding-left: 0px;
    width: 100%;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item .box_item_phone a {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.03px;
    line-height: 20px;
    font-size: 14px;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item .box_item_mail {
    margin-left: 0px;
    width: 100%;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_box .box_item .box_item_mail a {
    color: #ab0000;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.03px;
    line-height: 20px;
    font-size: 14px;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_boxBottom {
    pointer-events: all;
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 50px;
  }
  .subPage_contact .container .contact_content .contact_content_right .contact_content_boxBottom p {
    float: left;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.03px;
    line-height: 32px;
    margin: 0 25px;
    width: 100%;
    margin-bottom: 0;
  }
}

.subPage_contactForm {
  float: left;
  width: 100%;
  margin-bottom: 100px;
}

.subPage_contactForm .container .contactForm_wrap {
  max-width: 876px;
  margin: 0 auto;
}

.subPage_contactForm .container .subPage_contactForm_title {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 32px;
  margin-bottom: 40px;
}

.subPage_contactForm .container form {
  float: left;
  width: 100%;
}

.subPage_contactForm .container form p {
  float: left;
  width: 100%;
}

.subPage_contactForm .container form .item {
  margin-bottom: 45px;
}

.subPage_contactForm .container form .item span {
  width: 100%;
  float: left;
}

.subPage_contactForm .container form .item input {
  height: 68px;
  padding: 0 25px;
  width: 100%;
}

.subPage_contactForm .container form .item textarea {
  height: 236px;
  padding: 25px 25px;
}

.subPage_contactForm .container form .item input,
.subPage_contactForm .container form .item textarea {
  width: 100%;
  border: 1px solid #dadcde;
}

.subPage_contactForm .container form .item .nice-select {
  height: 68px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 25px 25px;
  border-radius: 0px;
  border: 1px solid #dadcde;
}

.subPage_contactForm .container form .item .nice-select::after {
  border-bottom-color: #DC8181;
  border-right-color: #DC8181;
  right: 30px;
  margin-top: -3px;
}

.subPage_contactForm .container form .item .nice-select ul {
  width: 100%;
  border-radius: 0px;
  margin: 0;
}

.subPage_contactForm .container form .item .nice-select ul li {
  padding: 10px 25px;
}

.subPage_contactForm .container form .item .checkboxStyle {
  width: 100%;
  float: left;
  margin-bottom: 20px;
  color: #706f6f;
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.subPage_contactForm .container form .item .checkboxStyle .text {
  width: calc(100% - 45px);
  margin-left: auto;
  font-size: 12px;
}

.subPage_contactForm .container form .item .checkboxStyle span {
  float: left;
  width: auto;
}

.subPage_contactForm .container form .item .checkboxStyle input {
  position: absolute;
  width: 0px;
  height: 0px;
  left: -10000px;
  opacity: 0;
}

.subPage_contactForm .container form .item .checkboxStyle span.wpcf7-list-item {
  margin: 0 !important;
}

.subPage_contactForm .container form .item .checkboxStyle .wpcf7-list-item-label .checkmark {
  position: relative;
  display: block;
  top: 0;
  cursor: pointer;
  left: 0;
  height: 22px;
  cursor: pointer;
  width: 22px;
  border: 1px solid #dadcde;
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-content: center;
}

.subPage_contactForm .container form .item .checkboxStyle .wpcf7-list-item-label .checkmark::after {
  position: absolute;
  width: 11px;
  height: 11px;
  background-color: transparent;
  transition-duration: .1s;
  content: '';
}

.subPage_contactForm .container form .item .checkboxStyle:hover input ~ .wpcf7-list-item-label .checkmark::after {
  background-color: #ccc;
}

.subPage_contactForm .container form .item .checkboxStyle input:checked ~ .wpcf7-list-item-label .checkmark::after {
  background-color: #ab0000;
}

.subPage_contactForm .container form .item button {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
  width: 244px;
  float: right;
  margin-left: auto;
  border: none;
}

.subPage_contactForm .container form .item button::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.subPage_contactForm .container form .item button::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.subPage_contactForm .container form .item button:hover {
  color: #fff;
}

.subPage_contactForm .container form .item button:hover::after {
  top: -100%;
}

.subPage_contactForm .container form .item button:hover::before {
  top: -120%;
}

.subPage_contactForm .container form .item button:hover span img {
  right: -2px;
  opacity: .8;
}

.subPage_contactForm .container form .item button span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.subPage_contactForm .container form .item button span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

span.wpcf7-not-valid-tip {
  color: #ab0000 !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  margin-top: 10px !important;
}

div.wpcf7 .screen-reader-response {
  float: left !important;
  width: 100% !important;
  position: relative !important;
  height: auto !important;
  text-align: center !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #ab0000 !important;
  margin-bottom: 30px !important;
}

div.wpcf7 .screen-reader-response ul,
div.wpcf7 .screen-reader-response ol {
  display: none !important;
}

.subPage_contactForm_title {
  float: left;
  width: 100%;
  margin-bottom: 100px;
}

.subPage_contactForm_title .container .contactForm_wrap {
  max-width: 876px;
  margin: 0 auto;
}

.subPage_contactForm_title .container .subPage_contactForm_title {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.96px;
  line-height: 36px;
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 70px;
}

.subPage_contactForm_title .container .subPage_contactForm_title a {
  color: #ab0000;
}

.subPage_contactForm_title .container form {
  float: left;
  width: 100%;
}

.subPage_contactForm_title .container form .item {
  margin-bottom: 45px;
}

.subPage_contactForm_title .container form .item span {
  width: 100%;
}

.subPage_contactForm_title .container form .item input {
  height: 68px;
  padding: 0 25px;
  width: 100%;
  font-size: 14px;
}

.subPage_contactForm_title .container form .item textarea {
  height: 236px;
  padding: 25px 25px;
  font-size: 14px;
}

.subPage_contactForm_title .container form .item input[type='text'], .subPage_contactForm_title .container form .item textarea {
  width: 100%;
  border: 1px solid #dadcde;
}

.subPage_contactForm_title .container form .item .nice-select {
  height: 68px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 25px 25px;
  border-radius: 0px;
  border: 1px solid #dadcde;
}

.subPage_contactForm_title .container form .item .nice-select::after {
  border-bottom-color: #DC8181;
  border-right-color: #DC8181;
  right: 30px;
}

.subPage_contactForm_title .container form .item .nice-select ul {
  width: 100%;
  border-radius: 0px;
  margin: 0;
}

.subPage_contactForm_title .container form .item .nice-select ul li {
  padding: 10px 25px;
}

.subPage_contactForm_title .container form .item .checkboxStyle {
  width: 100%;
  float: left;
  margin-bottom: 20px;
  color: #706f6f;
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.subPage_contactForm_title .container form .item .checkboxStyle .text {
  width: calc(100% - 45px);
  margin-left: auto;
  font-size: 12px;
}

.subPage_contactForm_title .container form .item .checkboxStyle span {
  float: left;
  width: auto;
}

.subPage_contactForm_title .container form .item .checkboxStyle input {
  position: absolute;
  width: 0px;
  height: 0px;
  left: -10000px;
  opacity: 0;
  font-size: 14px;
}

.subPage_contactForm_title .container form .item .checkboxStyle span.wpcf7-list-item {
  margin: 0 !important;
}

.subPage_contactForm_title .container form .item .checkboxStyle .wpcf7-list-item-label .checkmark {
  position: relative;
  display: block;
  top: 0;
  cursor: pointer;
  left: 0;
  height: 22px;
  cursor: pointer;
  width: 22px;
  border: 1px solid #dadcde;
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-content: center;
}

.subPage_contactForm_title .container form .item .checkboxStyle .wpcf7-list-item-label .checkmark::after {
  position: absolute;
  width: 11px;
  height: 11px;
  background-color: transparent;
  transition-duration: .1s;
  content: '';
}

.subPage_contactForm_title .container form .item .checkboxStyle:hover input ~ .wpcf7-list-item-label .checkmark::after {
  background-color: #ccc;
}

.subPage_contactForm_title .container form .item .checkboxStyle input:checked ~ .wpcf7-list-item-label .checkmark::after {
  background-color: #ab0000;
}

.subPage_contactForm_title .container form .item button {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
  width: 244px;
  float: right;
  margin-left: auto;
  border: none;
}

.subPage_contactForm_title .container form .item button::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.subPage_contactForm_title .container form .item button::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.subPage_contactForm_title .container form .item button:hover {
  color: #fff;
}

.subPage_contactForm_title .container form .item button:hover::after {
  top: -100%;
}

.subPage_contactForm_title .container form .item button:hover::before {
  top: -120%;
}

.subPage_contactForm_title .container form .item button:hover span img {
  right: -2px;
  opacity: .8;
}

.subPage_contactForm_title .container form .item button span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.subPage_contactForm_title .container form .item button span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

span.wpcf7-not-valid-tip {
  color: #ab0000 !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  margin-top: 10px !important;
}

div.wpcf7 .screen-reader-response {
  float: left !important;
  width: 100% !important;
  position: relative !important;
  height: auto !important;
  text-align: center !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #ab0000 !important;
  margin-bottom: 30px !important;
  display: none !important;
}

div.wpcf7 .screen-reader-response ul, div.wpcf7 .screen-reader-response ol {
  display: none !important;
}

div.wpcf7-validation-errors,
div.wpcf7-acceptance-missing {
  border: 2px solid #ab0000 !important;
  padding: 30px !important;
  text-align: center;
  font-weight: 800;
  margin: 0 !important;
}

.subPage_iconsRow {
  float: left;
  width: 100%;
  padding-bottom: 40px;
}

.subPage_iconsRow .container .iconsRow_title {
  color: #333333;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.96px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 100px;
}

.subPage_iconsRow .container .iconsRow_content {
  float: left;
  width: 100%;
}

.subPage_iconsRow .container .iconsRow_content .iconsRow_content_wrap {
  max-width: 872px;
  margin: 0 auto;
}

.subPage_iconsRow .container .iconsRow_content .iconsRow_content_wrap .iconsRow_content_item {
  margin-bottom: 80px;
}

.subPage_iconsRow .container .iconsRow_content .iconsRow_content_wrap .iconsRow_content_item .item_icon {
  width: 100%;
  height: 93px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subPage_iconsRow .container .iconsRow_content .iconsRow_content_wrap .iconsRow_content_item .item_icon img {
  max-width: 92px;
  max-height: 93px;
}

.subPage_iconsRow .container .iconsRow_content .iconsRow_content_wrap .iconsRow_content_item .item_title {
  margin: 0 auto;
  max-width: 257px;
  width: 100%;
  text-align: center;
  color: #333333;
  margin-top: 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

@media (max-width: 991.98px) {
  .subPage_iconsRow {
    margin-bottom: -110px;
    padding-bottom: 0px;
  }
}

@media (max-width: 767.98px) {
  .subPage_iconsRow {
    float: left;
    width: 100%;
    padding-bottom: 40px;
  }
  .subPage_iconsRow .container .iconsRow_title {
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.8px;
    line-height: 28px;
    margin-bottom: 50px;
  }
  .subPage_iconsRow .container .iconsRow_content {
    float: left;
    width: 100%;
  }
  .subPage_iconsRow .container .iconsRow_content .iconsRow_content_wrap {
    max-width: 872px;
    margin: 0 auto;
  }
  .subPage_iconsRow .container .iconsRow_content .iconsRow_content_wrap .iconsRow_content_item {
    margin-bottom: 60px;
  }
  .subPage_iconsRow .container .iconsRow_content .iconsRow_content_wrap .iconsRow_content_item .item_icon {
    width: 100%;
    height: 93px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .subPage_iconsRow .container .iconsRow_content .iconsRow_content_wrap .iconsRow_content_item .item_icon img {
    max-width: 92px;
    max-height: 93px;
  }
  .subPage_iconsRow .container .iconsRow_content .iconsRow_content_wrap .iconsRow_content_item .item_title {
    margin: 0 auto;
    width: 257px;
    text-align: center;
    color: #333333;
    margin-top: 40px;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
  }
}

.blog_news {
  float: left;
  width: 100%;
  padding-top: 180px;
  padding-bottom: 70px;
}

.blog_news .container .news_title {
  font-size: 30px;
  letter-spacing: -1.2px;
  line-height: 36px;
  margin-bottom: 60px;
  text-align: center;
  width: 100%;
  float: left;
  font-weight: 600;
}

.blog_news .container .news_articles {
  float: left;
  width: 100%;
}

.blog_news .container .news_articles .news_article {
  text-align: center;
  margin-bottom: 110px;
}

.blog_news .container .news_articles .news_article .news_article_image img {
  max-width: 100%;
}

.blog_news .container .news_articles .news_article .news_article_wrap .news_article_date {
  color: #202020;
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 25px;
  margin-top: 30px;
  margin-bottom: 16px;
  font-weight: 700;
  width: 100%;
}

.blog_news .container .news_articles .news_article .news_article_wrap .news_article_title {
  color: #ab0000;
  font-size: 20px;
  letter-spacing: -0.8px;
  line-height: 25px;
  font-weight: 700;
  padding: 0 30px;
  width: 100%;
}

.blog_news .container .news_articles .news_article .news_article_wrap .news_article_title a {
  color: #ab0000;
  text-decoration: none;
}

.blog_news .container .news_articles .news_article .news_article_wrap .news_article_title a:hover {
  color: #820000;
}

.blog_news .container .news_articles .news_article .news_article_wrap .news_article_subtitle {
  color: #9ca0a6;
  font-size: 16px;
  letter-spacing: -0.64px;
  line-height: 25px;
  font-weight: 400;
  padding: 0 30px;
  margin-top: 15px;
  width: 100%;
}

.blog_news .container .news_articles .news_article .news_article_wrap .news_article_link {
  width: 100%;
}

.blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
  width: 186px;
  display: inline-block;
  margin-top: 50px;
}

.blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover {
  color: #fff;
}

.blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover::after {
  top: -100%;
}

.blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover::before {
  top: -120%;
}

.blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover span img {
  right: -2px;
  opacity: .8;
}

.blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

.blog_news .container .news_articles_pagination {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
}

.blog_news .container .news_articles_pagination .news_articles_pagination_items a,
.blog_news .container .news_articles_pagination .news_articles_pagination_items span {
  float: left;
  font-size: 12px;
  font-weight: 700;
  line-height: 25px;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 40px;
  height: 40px;
  text-decoration: none;
  border: 1px solid #d9d9d9;
  margin: 0 -1px;
  transition-duration: .2s;
}

.blog_news .container .news_articles_pagination .news_articles_pagination_items a:first-child,
.blog_news .container .news_articles_pagination .news_articles_pagination_items span:first-child {
  border-radius: 3px 0px 0px 3px;
}

.blog_news .container .news_articles_pagination .news_articles_pagination_items a:last-child,
.blog_news .container .news_articles_pagination .news_articles_pagination_items span:last-child {
  border-radius: 0px 3px 3px 0px;
}

.blog_news .container .news_articles_pagination .news_articles_pagination_items a:hover,
.blog_news .container .news_articles_pagination .news_articles_pagination_items span:hover {
  background-color: #C6CDD8;
}

.blog_news .container .news_articles_pagination .news_articles_pagination_items .dots:hover {
  background-color: #fff;
}

.blog_news .container .news_articles_pagination .news_articles_pagination_items .current {
  background-color: #ab0000;
  color: #ffffff;
  border: none;
}

.blog_news .container .news_articles_pagination .news_articles_pagination_items .current:hover {
  background-color: #ab0000;
}

@media (max-width: 991.98px) {
  .blog_news {
    float: left;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .blog_news .container .news_title {
    font-size: 30px;
    letter-spacing: -1.2px;
    line-height: 36px;
    margin-bottom: 60px;
    text-align: center;
    width: 100%;
    float: left;
    font-weight: 600;
  }
  .blog_news .container .news_articles {
    float: left;
    width: 100%;
  }
  .blog_news .container .news_articles .news_article {
    text-align: left;
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
  }
  .blog_news .container .news_articles .news_article .news_article_image {
    width: 50%;
    float: left;
    text-align: right;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap {
    width: 50%;
    float: left;
    padding-left: 20px;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_date {
    color: #202020;
    font-size: 10px;
    letter-spacing: -0.4px;
    line-height: 25px;
    margin-top: 0px;
    margin-bottom: 16px;
    font-weight: 700;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_title {
    color: #ab0000;
    font-size: 20px;
    letter-spacing: -0.8px;
    line-height: 25px;
    font-weight: 700;
    padding: 0 0px;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_subtitle {
    color: #9ca0a6;
    font-size: 16px;
    letter-spacing: -0.64px;
    line-height: 25px;
    font-weight: 400;
    padding: 0 0px;
    margin-top: 15px;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    width: 186px;
    display: inline-block;
    margin-top: 50px;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover {
    color: #fff;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover::after {
    top: -100%;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover::before {
    top: -120%;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover span img {
    right: -2px;
    opacity: .8;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

@media (max-width: 767.98px) {
  .blog_news {
    float: left;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .blog_news .container .news_title {
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.8px;
    line-height: 28px;
    margin-bottom: 40px;
  }
  .blog_news .container .news_articles {
    float: left;
    width: 100%;
  }
  .blog_news .container .news_articles .news_article {
    text-align: center;
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
  }
  .blog_news .container .news_articles .news_article .news_article_image {
    width: 100%;
    float: left;
    text-align: center;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap {
    width: 100%;
    float: left;
    padding-left: 20px;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    justify-content: center;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_date {
    color: #202020;
    font-size: 10px;
    letter-spacing: -0.4px;
    line-height: 25px;
    margin-top: 20px;
    margin-bottom: 16px;
    font-weight: 700;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_title {
    color: #ab0000;
    font-size: 20px;
    letter-spacing: -0.8px;
    line-height: 25px;
    font-weight: 700;
    padding: 0 0px;
    font-size: 16px;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_subtitle {
    color: #9ca0a6;
    font-size: 16px;
    letter-spacing: -0.64px;
    line-height: 25px;
    font-weight: 400;
    padding: 0 0px;
    margin-top: 15px;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a {
    position: relative;
    background-color: #ab0000;
    display: block;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    color: #fff;
    transition-duration: .5s;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px;
    padding-right: 20px;
    width: 186px;
    display: inline-block;
    margin-top: 30px;
    font-size: 12px;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a::after {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 140%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    content: '';
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a::before {
    transition-duration: .3s;
    position: absolute;
    display: block;
    height: 1000%;
    left: -100px;
    right: -100px;
    top: 100%;
    bottom: 0;
    border-radius: 1000px;
    background-color: #820000;
    opacity: .4;
    content: '';
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover {
    color: #fff;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover::after {
    top: -100%;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover::before {
    top: -120%;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a:hover span img {
    right: -2px;
    opacity: .8;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .blog_news .container .news_articles .news_article .news_article_wrap .news_article_link a span img {
    height: 10px;
    opacity: .5;
    margin-left: auto;
    position: relative;
    right: 0;
    transition-duration: .2s;
  }
}

.single_post {
  float: left;
  width: 100%;
  padding-top: 110px;
  padding-bottom: 140px;
}

.single_post_news {
  float: left;
  width: 100%;
  max-width: 920px;
}

.single_post_news .single_post_news_header {
  margin-bottom: 40px;
}

.single_post_news .single_post_news_header .single_post_news_title {
  color: #333333;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -0.06px;
  line-height: 32px;
}

.single_post_news .single_post_news_header .single_post_news_date {
  color: #9ca0a6;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.56px;
  line-height: 25px;
}

.single_post_news .single_post_news_image img {
  width: 100%;
}

.single_post_news .single_post_news_content {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 32px;
}

.single_post_news .single_post_news_content *:last-child {
  margin-bottom: 0px;
}

.single_post_news .single_post_news_content p {
  margin-bottom: 40px;
}

.single_post_news .single_post_news_content ul,
.single_post_news .single_post_news_content ol {
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
  width: 100%;
  margin-bottom: 40px;
}

.single_post_news .single_post_news_content ul li,
.single_post_news .single_post_news_content ol li {
  line-height: 50px;
  padding-left: 20px;
  position: relative;
  float: left;
  width: 100%;
}

.single_post_news .single_post_news_content ul li::before,
.single_post_news .single_post_news_content ol li::before {
  width: 7px;
  display: inline-block;
  height: 10px;
  background-image: url(../img/icons/arrowRight-red.svg);
  content: '';
  position: absolute;
  left: 0;
  top: 20px;
}

.single_post_news .single_post_news_content ul li:last-child,
.single_post_news .single_post_news_content ol li:last-child {
  margin-left: 0px;
}

.single_post_news .single_post_news_content img {
  max-width: 100%;
  margin-bottom: 40px;
  height: auto;
  margin-top: 10px;
}

.single_post_news .single_post_news_back {
  float: left;
  width: 100%;
}

.single_post_news .single_post_news_back a {
  color: #ab0000;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.56px;
  line-height: 25px;
  display: block;
  margin-top: 30px;
  transition-duration: .2s;
  text-decoration: none;
}

.single_post_news .single_post_news_back a:hover {
  color: #820000;
}

@media (max-width: 991.98px) {
  .single_post_news {
    float: left;
    width: 100%;
    max-width: 920px;
    margin: 0 auto;
  }
}

@media (max-width: 767.98px) {
  .single_post_news {
    float: left;
    width: 100%;
    max-width: 920px;
    margin: 0 auto;
  }
  .single_post_news .single_post_news_header {
    margin-bottom: 20px;
  }
  .single_post_news .single_post_news_header .single_post_news_title {
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.64px;
    line-height: 25px;
  }
  .single_post_news .single_post_news_header .single_post_news_date {
    color: #9ca0a6;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.48px;
    line-height: 25px;
  }
  .single_post_news .single_post_news_image img {
    width: 100%;
  }
  .single_post_news .single_post_news_content {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
  }
  .single_post_news .single_post_news_content t *:last-child {
    margin-bottom: 0px;
  }
  .single_post_news .single_post_news_content p {
    margin-bottom: 30px;
  }
  .single_post_news .single_post_news_content ul,
  .single_post_news .single_post_news_content ol {
    text-align: left;
    list-style: none;
    padding: 0;
    margin: 0;
    float: left;
    width: 100%;
    margin-bottom: 30px;
  }
  .single_post_news .single_post_news_content ul li,
  .single_post_news .single_post_news_content ol li {
    line-height: 50px;
    padding-left: 20px;
    position: relative;
    float: left;
    width: 100%;
  }
  .single_post_news .single_post_news_content ul li::before,
  .single_post_news .single_post_news_content ol li::before {
    width: 7px;
    display: inline-block;
    height: 10px;
    background-image: url(../img/icons/arrowRight-red.svg);
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
  }
  .single_post_news .single_post_news_content ul li:last-child,
  .single_post_news .single_post_news_content ol li:last-child {
    margin-left: 0px;
  }
  .single_post_news .single_post_news_content img {
    max-width: 100%;
    margin-bottom: 30px;
    margin-top: 10px;
  }
  .single_post_news .single_post_news_back {
    float: left;
    width: 100%;
  }
  .single_post_news .single_post_news_back a {
    color: #ab0000;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.56px;
    line-height: 25px;
    display: block;
    margin-top: 30px;
    transition-duration: .2s;
    text-decoration: none;
  }
  .single_post_news .single_post_news_back a:hover {
    color: #820000;
  }
}

.postSidebar {
  margin-top: 45px;
}

.postSidebar .postSidebar_header {
  color: #333333;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.04px;
  line-height: 32px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.postSidebar .postSidebar_content .postSidebar_content_news {
  margin-bottom: 50px;
}

.postSidebar .postSidebar_content .postSidebar_content_news .postSidebar_content_news_image img {
  width: 100%;
}

.postSidebar .postSidebar_content .postSidebar_content_news .postSidebar_content_news_header .postSidebar_content_news_date {
  color: #202020;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: -0.4px;
  line-height: 25px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.postSidebar .postSidebar_content .postSidebar_content_news .postSidebar_content_news_header .postSidebar_content_news_title {
  text-decoration: none;
  color: #ab0000;
  transition-duration: .2s;
}

.postSidebar .postSidebar_content .postSidebar_content_news .postSidebar_content_news_header .postSidebar_content_news_title:hover {
  color: #820000;
}

.postSidebar .postSidebar_content .postSidebar_content_news .postSidebar_content_news_header .postSidebar_content_news_title h2 {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.64px;
  line-height: 25px;
}

.postSidebar .postSidebar_content .postSidebar_content_points_wraper_item {
  width: 100%;
  position: relative;
  background-color: #ab0000;
  background-size: cover;
  display: block;
  box-shadow: 0 0 0px transparent;
  transition-duration: .3s;
  padding: 30px;
}

.postSidebar .postSidebar_content .postSidebar_content_points_wraper_item .item_wrap {
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
  min-height: 220px;
}

.postSidebar .postSidebar_content .postSidebar_content_points_wraper_item .item_wrap .item_title {
  font-size: 21px;
  letter-spacing: -0.84px;
  line-height: 33px;
  color: #ffffff;
  text-transform: none;
  font-weight: 400;
}

.postSidebar .postSidebar_content .postSidebar_content_points_wraper_item .item_wrap .item_title strong {
  color: #ab0000;
  font-weight: 600;
}

.postSidebar .postSidebar_content .postSidebar_content_points_wraper_item .item_wrap .item_link {
  position: relative;
  background-color: #ab0000;
  display: block;
  border-radius: 0px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  transition-duration: .5s;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.48px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 30px;
  padding-right: 20px;
}

.postSidebar .postSidebar_content .postSidebar_content_points_wraper_item .item_wrap .item_link::after {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 140%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  content: '';
}

.postSidebar .postSidebar_content .postSidebar_content_points_wraper_item .item_wrap .item_link::before {
  transition-duration: .3s;
  position: absolute;
  display: block;
  height: 1000%;
  left: -100px;
  right: -100px;
  top: 100%;
  bottom: 0;
  border-radius: 1000px;
  background-color: #820000;
  opacity: .4;
  content: '';
}

.postSidebar .postSidebar_content .postSidebar_content_points_wraper_item .item_wrap .item_link:hover {
  color: #fff;
}

.postSidebar .postSidebar_content .postSidebar_content_points_wraper_item .item_wrap .item_link:hover::after {
  top: -100%;
}

.postSidebar .postSidebar_content .postSidebar_content_points_wraper_item .item_wrap .item_link:hover::before {
  top: -120%;
}

.postSidebar .postSidebar_content .postSidebar_content_points_wraper_item .item_wrap .item_link:hover span img {
  right: -2px;
  opacity: .8;
}

.postSidebar .postSidebar_content .postSidebar_content_points_wraper_item .item_wrap .item_link span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.postSidebar .postSidebar_content .postSidebar_content_points_wraper_item .item_wrap .item_link span img {
  height: 10px;
  opacity: .5;
  margin-left: auto;
  position: relative;
  right: 0;
  transition-duration: .2s;
}

@media (max-width: 991.98px) {
  .postSidebar {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .single_post {
    float: left;
    width: 100%;
    padding-top: 45px;
    padding-bottom: 60px;
  }
}

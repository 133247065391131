@include media-breakpoint-down(md){
     .subPage_intro {
          float: left;
          width: 100%;
          min-height: 300px;
          padding-top: 200px;
          background-size: cover;

          .container {
               .wrap {
                    float: left;
                    position: relative;
                    width: 100%;
                    z-index: 11;
               }

               .subPage_intro_title {
                    color: $global;
                    font-size: 36px;
                    font-weight: 600;
                    letter-spacing: -1.44px;
                    line-height: 53px;
               }

               .subPage_intro_arrows {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    bottom: 0;
                    pointer-events: none;
                    left: 0;
                    right: 0;
                    opacity: .8;

                    .subPage_intro_arrows_triangle {
                         display: block;
                         width: 0;
                         height: 0;
                         border: 50px solid transparent;
                         border-right: 100px solid $red;
                         filter: blur(2px);
                         // right: -500px;
                         position: absolute;

                         &.subPage_intro_arrows_triangle:nth-child(1) {
                              // transform: scale(1);
                              // top: -50px;
                              left: 0px;
                              bottom: -86px;
                         }

                         &.subPage_intro_arrows_triangle:nth-child(2) {
                              // border-right: 70px solid $red;
                              transform: scale(.7);
                              right: 0px;
                              bottom: 0;
                         }

                         &.subPage_intro_arrows_triangle:nth-child(3) {
                              transform: scale(.4);
                              top: -250%;
                              right: 40%;
                         }
                    }
               }
          }
     }
}
.subPage_workOffer_single {
	float: none;
	width: 100%;
	max-width: 876px;
	margin: 0 auto;
	color: $global;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: -0.03px;
	line-height: 32px;

	.subPage_workOffer_single_wrap{
		padding-top: 110px;
		padding-bottom: 90px;
		float: left;
		width: 100%;
	}
	

	*:last-child {
		margin-bottom: 0px;
	}

	p {
		margin-bottom: 40px;
	}

	ul,
	ol {
		list-style: none;
		padding: 0;
		margin: 0;
		float: left;
		width: 100%;
		margin-bottom: 40px;

		li {
			line-height: 50px;
			padding-left: 20px;
			position: relative;
			float: left;
			width: 100%;

			&::before {
				width: 7px;
				display: inline-block;
				height: 10px;
				background-image: url(../img/icons/arrowRight-red.svg);
				content: '';
				position: absolute;
				left: 0;
				top: 20px;
			}

			&:last-child {
				margin-left: 0px;
			}

			ul, ol{
				li{
					&::before {
						display: none;
					}
				}
			}
		}
	}

	img {
		max-width: 100%;
		margin-bottom: 40px;
		height: auto;
		margin-top: 10px;
	}

	.wrap_bottom{
		.title{
			color: $global;
			font-size: 16px;
			letter-spacing: -0.03px;
			line-height: 50px;
			font-weight: 700;
		}
		.contact{
			color: $global;
			font-weight: 400;
			letter-spacing: -0.03px;
			.contact_item{
				margin-right: 30px;
				float: left;
			line-height: 50px;

				a {
					color: $global;
				}
				img{
					width: 26px;
					margin-bottom: 0; margin-top: 0;
					margin-right: 10px;
				}
			}
			
		}
	}
}

.single_post_news_back {
	float: left;
	width: 100%;

	a {
		color: $red;
		font-size: 14px;
		font-weight: 700;
		letter-spacing: -0.56px;
		line-height: 25px;
		display: block;
		margin-top: 50px;
		transition-duration: .2s;
		text-decoration: none;

		&:hover {
			color: $redHover;
		}
	}
}
@import 'rwd/tablet';
@import 'rwd/mobile';

.subPage_textBox_imageLeft{
	float: left;
	width: 100%;
	margin-bottom: 150px;
	.container{
		max-width: calc(876px + 80px);
		position: relative;
		.textBox_imageLeft_content{
			.textBox_imageLeft_content_wrap {
				.textBox_imageLeft_content_text {
					float: left;
					padding-top: 10px;
					width: 60%;
					padding-left: 40px;

					p {
						color: $global;
						font-size: 16px;
						font-weight: 400;
						line-height: 32px;
						margin-bottom: 30px;
					}

					.item_link {
						@include buttonAnimation();
						width: 244px;
						margin-top: 36px;

					}
				}

				.textBox_imageLeft_content_image {
					float: left;
					width: 70%;
					margin-left: -30%;
					background-color: red;
					box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
					position: relative;

					.wrap {
						overflow: hidden;

						img {
							width: 100%;
						}
					}

					&::after {
						display: block;
						width: 0;
						height: 0;
						border: 50px solid transparent;
						border-right: 100px solid #fff;
						filter: blur(2px);
						// right: -500px;
						content: '';
						position: absolute;

						transform: scale(.8);
						right: 20px;
						bottom: 20px;
					}

					&::before {
						display: block;
						width: 0;
						height: 0;
						border: 50px solid transparent;
						border-right: 100px solid $red;
						filter: blur(2px);
						// right: -500px;
						content: '';
						position: absolute;

						transform: scale(.6);
						left: -100px;
						top: 50px;
					}

				}
			}
		}
	}
}

@import 'rwd/tablet';
@import 'rwd/mobile';
@include media-breakpoint-down(sm) {
     .mainPage_ourPassion {
          float: left;
          width: 100%;

          padding-bottom: 80px;

          .container {
               position: relative;

               .ourPassion_wrap {
                    float: left;
                    width: 100%;
                    position: relative;
               }

               .ourPassion {
                    max-width: 530px;
                    height: auto;
                    float: none;
                    margin: 0 auto;
                    text-align: center;


                    .ourPassion_title {
                         color: $global;
                         font-size: 20px;
                         font-weight: 600;
                         letter-spacing: -0.8px;
                         line-height: 28px;
                         margin-bottom: 20px;
                    }

                    .ourPassion_description {
                         color: $global;
                         font-size: 16px;
                         font-weight: 400;
                         line-height: 26px;
                         margin-bottom: 30px;
                    }

                    .ourPassion_link {
                         a {
                              @include buttonAnimation();
                              max-width: 244px;
                              display: inline-block;
                              font-size: 12px;
                         }
                    }
               }

               .ourPassion_images {
                    float: left;
                    height: 100%;
                    width: 100%;
                    position: relative;
                    height: auto;
                    margin-top: 50px;

                    &::after {
                         display: none;
                    }

                    img {
                         position: relative;
                         top: auto;
                         box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
                         right: auto;
                         width: 100%;
                         margin-bottom: 20px;

                         &:nth-child(1) {

                         }

                         &:nth-child(2) {
                               right: auto;
                               bottom: auto;
                               left: auto;
                               top: auto;
                         }

                         &:nth-child(3) {
                              right: auto;
                              bottom: auto;
                              left: auto;
                              top: auto;
                         }
                    }
               }
          }
     }
}
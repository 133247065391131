.subPage_imageSlider {
	float: left;
	width: 100%;

	.container{
		.imageSlider_wrap{
			.imageSlider_item{
				.item_title{
					color: $global;
					letter-spacing: -0.03px;
					font-size: 20px;
					font-weight: 700;
					line-height: 40px;

				}
				.item_date{
					font-size: 16px;
					font-weight: 400;
					line-height: 40px;

				}
				.item_slider{
						float: left;
						width: 100%;
					.slick-list{
						width: 140%;
						margin-left: -62px;
						padding: 40px 0;
						padding-left: 40px;
						float: left;
						.slick-slide{
							opacity: 0;
							transition-duration: .2s;
						}
						.slick-active{
							opacity: 1;
						}
					}
					.item_slider_wrap{
						padding: 0 22px;
						img{
							max-width: 100%;
							width: 100%;
							box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
						}
					}
				}
				.imageSlider_item_pagination{
					float: left;
					width: 100%;
					text-align: right;
					margin-bottom: 80px;
					.arrowLeft,
					.arrowRight {
						display: inline-block;
						cursor: pointer;
						img {
							width: 15px;
						}
					}

					.arrowLeft{
						
						img{

						}
					}
					.arrowRight{
						margin-left: 25px;
						margin-right: 4px;
						img{

						}
					}
				}
			}
		}
	}
}

@import 'rwd/tablet';
@import 'rwd/mobile';
.offer2_subPage {
	float: left;
	width: 100%;
	padding-top: 0px;
	padding-bottom: 80px;
	.container{
		position: relative;
		.offer2_subPage_points{
			float: left;
			width: 100%;
			.offer2_subPage_points_wraper{
				display: flex;
				// flex-wrap: wrap;
				width: calc(100% + 44px);
				margin-left: -22px;
				.offer2_subPage_points_wraper_item{

					width: 25%;
					padding-bottom: calc(25% - 44px);
					position: relative;
					background-color: $red;
					margin: 0 22px;
					margin-bottom: 44px;
					background-size: cover;
					display: block;
					box-shadow: 0 0 0px rgba(0, 0, 0, 0);
					transition-duration: .3s;


					&:hover{
						box-shadow: 0 0 70px rgba(0, 0, 0, 0.25);
					}
					.item_wrap{
						position: absolute;
						height: 100%;
						width: 100%;
						padding: 30px;
						display: block;
						text-decoration: none;


					}
					.item_icon{
						margin-bottom: 30px;
						img{
							width: 51px;
						}
					}
					.item_title{
						color: #ffffff;
						font-size: 16px;
						letter-spacing: -0.64px;
						line-height: 24px;
						text-transform: uppercase;
						display: block;
						width: 100%;
						font-weight: 800;
						margin-bottom: 20px;
					}
					.item_description{
						color: #f09696;
						font-size: 16px;
						letter-spacing: -0.64px;
						line-height: 24px;
					}
					&:nth-child(2n + 2){
						background-color: $redHover;
					}
					
					&.offer2_subPage_points_wraper_item--info{
						.item_wrap{
							display: flex;
							align-content: space-between;
							flex-wrap: wrap;
							.item_title{
								font-size: 21px;
								letter-spacing: -0.84px;
								line-height: 33px;
								color: #ffffff;
								text-transform: none;
								font-weight: 400;
								strong{
									color: $red;
									font-weight: 600;
								}
							}
							.item_link{
								@include buttonAnimation();
							}
						}
					}
					
					
					&.offer2_subPage_points_wraper_item--z1{
						position: relative;
						z-index: 1;
					}
				}
			}
		}
	}
}
@media (max-width: 1320px){
	.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item .item_wrap{
		position: relative;
	}
	.offer2_subPage .container .offer2_subPage_points .offer2_subPage_points_wraper .offer2_subPage_points_wraper_item{
		padding-bottom: 0px;
	}
}
@import 'rwd/tablet';
@import 'rwd/mobile';
@include media-breakpoint-down(md){
     .site_header {
          .site_header_container {
               padding: 20px 30px;
               .site_header_left {
                    img {
                         width: 156px;
                    }
               }

               .site_header_right {
                    margin-left: auto;

                    .site_header_right_menu {
                         z-index: 1;
                         position: relative;

                         .menu_button {
                              .menu_button_icon {
                                   .menu_button_icon_line {
                                   }
                              }
                              &:hover {
                                   .menu_button_icon {
                                        .menu_button_icon_line:nth-child(1) {
                                        }
                                        .menu_button_icon_line:nth-child(3) {
                                        }
                                   }
                              }

                              &.menu_button--open {
                                   .menu_button_icon {
                                        .menu_button_icon_line:nth-child(1) {
                                        }
                                        .menu_button_icon_line:nth-child(2) {
                                        }
                                        .menu_button_icon_line:nth-child(3) {
                                        }
                                   }
                                   &:hover {}
                              }
                         }
                    }

                    .site_header_right_nav {
                         &.site_header_right_nav--open {
                         }

                         ul {
                              li {
                                   a {
                                        &:hover {
                                        }
                                   }
                              }
                         }
                    }
               }
          }
     }
}
.subPage_contactForm {
	float: left;
	width: 100%;
	margin-bottom: 100px;

	.container{
		.contactForm_wrap{
			max-width: 876px;
			margin: 0 auto;
		}
		.subPage_contactForm_title{
			color: $global;
			font-size: 16px;
			font-weight: 400;
			letter-spacing: -0.03px;
			line-height: 32px;
			margin-bottom: 40px;
		}
		form {
			float: left;
			width: 100%;
			p {
				float: left;
				width: 100%;
			}
			.item {
				margin-bottom: 45px;
				
				span {
					width: 100%;
					float: left;
				}

				input {
					height: 68px;
					padding: 0 25px;
					width: 100%;
				}

				textarea {
					height: 236px;
					padding: 25px 25px;
				}

				input,
				textarea {
					width: 100%;
					border: 1px solid #dadcde;
				}

				.nice-select {
					height: 68px;
					width: 100%;
					display: flex;
					align-items: center;
					padding: 25px 25px;
					border-radius: 0px;
					border: 1px solid #dadcde;
				
					&::after{
						border-bottom-color: #DC8181;
						border-right-color: #DC8181;
						right: 30px;
						margin-top: -3px;
					}
					ul {
						width: 100%;
						border-radius: 0px;
						margin: 0;

						li {
							padding: 10px 25px;
						}
					}
				}

				.checkboxStyle {
					width: 100%;
					float: left;
					margin-bottom: 20px;
					// label{
					color: #706f6f;
					font-size: 15px;
					display: flex;
					flex-wrap: wrap;
					align-items: flex-start;

					position: relative;
					cursor: pointer;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;

					.text {
						width: calc(100% - 45px);
						margin-left: auto;
						font-size: 12px;
					}

					span {
						float: left;
						width: auto;
					}

					input {
						position: absolute;
						width: 0px;
						height: 0px;
						left: -10000px;
						opacity: 0;

					}

					span.wpcf7-list-item {
						margin: 0 !important;
					}

					.wpcf7-list-item-label .checkmark {
						position: relative;
						display: block;
						top: 0;
						cursor: pointer;
						left: 0;
						height: 22px;
						cursor: pointer;
						width: 22px;
						border: 1px solid #dadcde;
						display: flex;
						// background-color: #fff;
						align-items: center;
						margin-right: 10px;
						justify-content: center;

						&::after {
							position: absolute;
							width: 11px;
							height: 11px;
							background-color: transparent;
							transition-duration: .1s;
							content: '';
						}
					}

					&:hover input~.wpcf7-list-item-label .checkmark {
						&::after {
							background-color: #ccc;
						}
					}

					input:checked~.wpcf7-list-item-label .checkmark {
						&::after {
							background-color: $red;
						}
					}

				}

				button {
					@include buttonAnimation();
					width: 244px;
					float: right;
					margin-left: auto;
					border: none;
				}
			}

		}
		}
		}

		span.wpcf7-not-valid-tip {
			color: $red !important;
			font-size: 12px !important;
			font-weight: 700 !important;
			margin-top: 10px !important;
		}

		div.wpcf7 .screen-reader-response {
			float: left !important;
			width: 100% !important;
			position: relative !important;
			height: auto !important;
			text-align: center !important;
			font-size: 24px !important;
			font-weight: 700 !important;
			color: $red !important;
			margin-bottom: 30px !important;

			ul,
			ol {
				display: none !important;
			}
		}

		div.wpcf7-validation-errors,
		div.wpcf7-acceptance-missing {
			// display: none !important;
		}

		@import 'rwd/tablet';
		@import 'rwd/mobile';